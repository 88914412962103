/* eslint-disable no-underscore-dangle */

// Libraries
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Layout
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";

// Material UI components
import Grid from "@mui/material/Grid2";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

// Redux Actions
import {
  deactivate,
  activate,
  setLoadingUsers,
  getUsers,
  clearOneUser,
} from "store/actions/userActions";
import DynamicDateRange from "components/DynamicDateRange";
import { isSameDay, subDays } from "date-fns";
import MDButton from "components/MDButton";
import { getPdf, getCsv } from "store/actions/exportAction";
import columnsMap from "components/MaterialTablePicker/TableColumns";

const AllUsers = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.users.loadingUsers);
  const users = useSelector((state) => state.users.users);
  const user = useSelector((state) => state.users.user);

  const [hideDaterange, setHideDaterange] = useState(true);
  const [allUsers, setAllUsers] = useState(true);
  const [filterButton, setFilterButton] = useState(false);
  const [click, setClick] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        7
      ),
      endDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        1
      ),
      key: "selection",
    },
  ]);

  useEffect(() => {
    dispatch(clearOneUser());
  }, []);

  useEffect(() => {
    if (click) {
      tableRef.current.onQueryChange();
      setClick(false);
    }
  }, [click]);

  useEffect(() => {
    if (filterButton) {
      dispatch(
        getUsers(dateRange[0]?.startDate?.toISOString(), dateRange[0]?.endDate?.toISOString())
      );
      setFilterButton(false);
    }
  }, [users?.length, filterButton]);

  const handleDeactivate = (id) => {
    dispatch(setLoadingUsers());
    dispatch(deactivate(id));
    setClick(true);
  };
  const handleActivate = (id) => {
    dispatch(setLoadingUsers());
    dispatch(activate(id));
    setClick(true);
  };

  const handleDateChange = (date) => {
    if (date?.selection?.startDate && isSameDay(date.selection.startDate, date.selection.endDate)) {
      const newStartDate = date.selection.startDate;
      const newEndDate = dateRange[0].endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    } else {
      const newStartDate = date?.range1?.startDate
        ? date?.range1.startDate
        : date?.selection?.startDate;
      const newEndDate = date?.range1?.endDate ? date?.range1.endDate : date?.selection?.endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    }
  };

  const refreshPage = () => {
    window.location.reload(true);
  };
  const actionsChildFn = (rowData) => {
    let actionsChildRole = {};
    actionsChildRole = {
      icon: rowData.active ? DeleteSweepIcon : VerifiedUserIcon,
      tooltip: rowData.active ? "Deactivate" : "Activate",

      iconProps: {
        style: {
          marginLeft: "10px",
          fontSize: "25px",
          color: rowData.active ? "#f50057" : "green",
        },
      },
      onClick: () => {
        // eslint-disable-next-line no-unused-expressions
        rowData.active ? handleDeactivate(rowData._id) : handleActivate(rowData._id);
        refreshPage();
      },
    };
    return actionsChildRole;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid style={{ marginTop: "15px" }} item size={{ xs: 6 }}>
          <DynamicDateRange
            defaultValue={dateRange}
            selectedOption={dateRange}
            handleDateChange={handleDateChange}
            hideDaterange={hideDaterange}
            hideDateRangeFn={setHideDaterange}
          />
          <MDBox display={!allUsers ? "block" : "none"} style={{ fontSize: "medium" }}>
            Selected Date Range: {dateRange[0]?.startDate.toDateString()} to{" "}
            {dateRange[0].endDate.toDateString()}
          </MDBox>
        </Grid>
        <Grid item size={{ xs: 6 }}>
          {" "}
          <MDBox sx={{ display: "flex", justifyContent: "flex-start" }}>
            <MDButton
              color="primary"
              style={{
                marginTop: "30px",
                marginLeft: "40px",
                width: "150px",
                height: "25px",
                color: "white",
              }}
              onClick={() => {
                setAllUsers(false);
                setHideDaterange(true);
                setFilterButton(true);
              }}
            >
              Filter Date
            </MDButton>{" "}
            <MDButton
              color="primary"
              style={{
                marginTop: "30px",
                marginLeft: "40px",
                width: "150px",
                height: "25px",
                color: "white",
              }}
              onClick={() => {
                setAllUsers(true);
              }}
            >
              Reset
            </MDButton>
          </MDBox>
        </Grid>
        {allUsers && (
          <Grid item size={{ xs: 12 }}>
            <MDBox>
              <MaterialTablePicker
                title="Users"
                columns={[
                  columnsMap.partnerName,
                  columnsMap.emailCol,
                  columnsMap.roleCol,
                  columnsMap.nbOfTickets,
                  columnsMap.partnerField,
                  columnsMap.sourceLocation,
                  columnsMap.registrationDateAsync,
                  columnsMap.loginType,
                  columnsMap.activeCol,
                  columnsMap.verified,
                ]}
                tableRef={tableRef}
                searchField="users"
                exportMenuField="users"
                asyncTable
                asyncURL={`${process.env.REACT_APP_API}/users?`}
                currentUrl="users"
                addUrl="users/new-user"
                onRowClickURL="/admin/users"
                idSynonymName="_id"
                sortField="createdAt"
                direction="asc"
                isLoading={loading}
                actionsName="User"
                actionsChild={actionsChildFn}
                actionsRole
                usersTable
                getPdf={(value, query) => {
                  if (value) {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf${query}&fields=name,email,role,partner,sourceLocation,createdAt,loginType,active,verified`,
                        "Users"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for PDF export.");
                  }
                }}
                getCsv={(query) => {
                  if (user?.role === "admin") {
                    dispatch(
                      getCsv(
                        `stats/csv${query}&fields=name,email,role,partner,sourceLocation,createdAt,loginType,active,verified`,
                        "Users"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for PDF export.");
                  }
                }}
              />
            </MDBox>
          </Grid>
        )}
        {!allUsers && (
          <Grid item size={{ xs: 12 }}>
            <MDBox>
              <MaterialTablePicker
                title="Users"
                columns={[
                  columnsMap.partnerName,
                  columnsMap.emailCol,
                  columnsMap.roleCol,
                  columnsMap.nbOfTickets,
                  columnsMap.partnerField,
                  columnsMap.sourceLocation,
                  columnsMap.registrationDateAsync,
                  columnsMap.loginType,
                  columnsMap.activeCol,
                  columnsMap.verified,
                ]}
                tableRef={tableRef}
                searchField="users"
                exportMenuField="users"
                data={users}
                currentUrl="users"
                addUrl="users/new-user"
                onRowClickURL="/admin/users"
                idSynonymName="_id"
                sortField="createdAt"
                direction="asc"
                isLoading={loading}
                actionsName="User"
                actionsChild={actionsChildFn}
                actionsRole
                usersTable
                getPdf={(value) => {
                  if (value) {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf?fields=name,email,role,partner,sourceLocation,createdAt,loginType,active,verified&startDate=${
                          dateRange[0]?.startDate?.toISOString().split("T")[0]
                        }&endDate=${dateRange[0]?.endDate?.toISOString().split("T")[0]}`,
                        "Users"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for PDF export.");
                  }
                }}
                getCsv={() => {
                  if (user?.role === "admin") {
                    dispatch(
                      // getCsv(
                      //   `${process.env.REACT_APP_API}/users/csv?csv=true&fields=name,email,role,partner.name,sourceLocation,createdAt,loginType,active,verified`,
                      //   "Users"
                      // )
                      getCsv(
                        `stats/csv?fields=name,email,role,partner,sourceLocation,createdAt,loginType,active,verified&startDate=${
                          dateRange[0]?.startDate?.toISOString().split("T")[0]
                        }&endDate=${dateRange[0]?.endDate?.toISOString().split("T")[0]}`,
                        "Users"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for CSV export.");
                  }
                }}
              />
            </MDBox>
          </Grid>
        )}
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default AllUsers;
