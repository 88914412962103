/* eslint-disable camelcase */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, icon },
} = checkout;

const validations = [
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z\u0600-\u06FF])/, title.invalidMsg)
      .required(title.errorMsg),
    [icon.name]: Yup.mixed().when("type.value", {
      is: "JOBS",
      then: () => Yup.mixed().required("Icon is required!"),
      otherwise: () => Yup.mixed().notRequired(),
    }),
  }),
];

export default validations;
