/* eslint-disable no-use-before-define */
import React from "react";
import PropTypes from "prop-types";
import { Html, Head, Body, Container, Section, Text } from "@react-email/components";

import DetailsCard from "../components/DetailsCard";
import FooterSection from "../components/FooterSection";

const B2POffersPromotionsCancellation = ({ data = {} }) => {
  /* ****** Constants ****** */
  const {
    guests = "2",
    time = "07:00",
    date = "13/11/2000",
    phone = "1234567890",
    name = "Lokalee User",
    email = "lokalee.app",
    ref: {
      location = { name: "Lokalee Location" },
      recommendation = { title: "Lokalee Recommendation" },
    } = {},
  } = data;

  return (
    <Html>
      <Head />
      <Body style={styles.body}>
        <Container style={styles.container}>
          <Section style={styles.header}>
            <img
              alt="Lokalee Logo"
              style={styles.logo}
              src="https://email-assets.lokalee.app/email/emailImages/logo.png"
            />
          </Section>

          <Section style={styles.whiteContainer}>
            <Text style={styles.title}>Guest</Text>
            <Text style={styles.highlight}>Canceled.</Text>
            <Text style={styles.greeting}>
              Dear {recommendation?.title ? recommendation.title : location.name},
            </Text>
            <Text style={styles.message}>
              The booking with the following details has just been canceled by the guest.
            </Text>
            <Text style={styles.detailsTitle}>Booking Details</Text>

            <DetailsCard
              numOfGuests={guests}
              bookingTime={time}
              bookingDate={date}
              visitorName={name}
              visitorPhone={phone}
              visitorEmail={email}
            />

            <Text style={styles.additionalMessage}>
              To follow up, kindly contact the guest directly or through the email address provided.
            </Text>

            <div style={styles.separator} />

            <Section style={styles.footerContainer}>
              <Text style={styles.footerText}>If you need assistance, get in touch</Text>

              <a href="mailto:cs@lokalee.app">
                <p style={styles.link}>Help Center</p>
              </a>
            </Section>
          </Section>

          <FooterSection />
        </Container>
      </Body>
    </Html>
  );
};

export default B2POffersPromotionsCancellation;

const styles = {
  body: {
    margin: 0,
    padding: 0,
    backgroundColor: "#f4f4f4",
    fontFamily: "Arial, sans-serif",
  },

  container: {
    width: "600px",
    padding: "24px",
    margin: "20px auto",
    borderRadius: "8px",
    backgroundColor: "#E6EBE9",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },

  header: {
    height: "75px",
  },

  whiteContainer: {
    padding: "24px",
    borderRadius: "8px",
    marginBottom: "24px",
    backgroundColor: "#ffffff",
  },

  logo: {
    height: "40px",
  },

  title: {
    fontSize: "54px",
    fontWeight: "700",
    marginBottom: "32px",
  },

  highlight: {
    color: "#28a745",
    fontSize: "54px",
    fontWeight: "700",
    marginBottom: "24px",
  },

  greeting: {
    fontSize: "16px",
    fontWeight: "700",
    marginBottom: "10px",
  },

  message: {
    margin: "0px",
    fontSize: "16px",
    marginBottom: "24px",
  },

  detailsTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "12px",
  },

  separator: {
    margin: "10px 0",
    borderTop: "1px solid #E6EBE9",
  },

  additionalMessage: {
    fontSize: "16px",
    marginBottom: "24px",
  },

  footerContainer: {
    textAlign: "center",
    fontSize: "12px",
    color: "#888",
  },

  footerText: {
    margin: "0",
    fontSize: "12px",
    alignText: "center",
    color: "#000000",
  },

  link: {
    fontSize: "14px",
    color: "#00850D",
    fontWeight: "bold",
    textDecoration: "underline",
  },
};

B2POffersPromotionsCancellation.propTypes = {
  data: PropTypes.shape({}),
};
