/* eslint-disable camelcase */

import { useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButtonLoading from "components/MDButtonLoading";

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover-2.png";
import { useDispatch, useSelector } from "react-redux";
import { resetPass, setLoading, clearErrors, checkToken } from "store/actions/userActions";
import setAlert from "store/actions/alertActions";
import { InputAdornment } from "@mui/material";
import MDButton from "components/MDButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Danger from "components/Danger";
import { useHandleError } from "components/helper";
// import setAlert from "store/actions/alertActions";

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const error = useSelector((state) => state.users.error);
  const [password, setPassword] = useState("");
  const [validation1, setValidation1] = useState(false);
  const [validation2, setValidation2] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const resetPassSuccess = useSelector((state) => state.users.resetPassSuccess);

  // Check Token on load
  useEffect(() => {
    setLoading();
    dispatch(checkToken(token));
  }, [token, setLoading, checkToken]);

  useEffect(() => {
    if (password.length < 8) {
      setValidation1(true);
    } else {
      setValidation1(false);
    }
  }, [password]);
  useEffect(() => {
    if (confirmPassword !== "" && password !== confirmPassword) {
      setValidation2(true);
    } else {
      setValidation2(false);
    }
  }, [password, confirmPassword]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  const showNewPasswordFn = () => {
    setShowNewPassword(!showNewPassword);
  };

  const showConfirmNewPasswordFn = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleResetPassword = () => {
    dispatch(setLoading());
    dispatch(resetPass(password, token));
  };

  if (resetPassSuccess) {
    return <Navigate to="/authentication/login" />;
  }

  if (error) {
    return <Navigate to="/authentication/reset-password" />;
  }
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1} p={1}>
            Thank you for confirming your email. Please set a new password and use it to login.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type={showNewPassword ? "text" : "password"}
                label="New password"
                variant="standard"
                fullWidth
                onChange={handleChangePassword}
                error={validation1}
                onFocus={() => setValidation1(false)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MDButton onClick={showNewPasswordFn}>
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </MDButton>
                    </InputAdornment>
                  ),
                }}
              />
              {validation1 && <Danger validation="Password should be at least 8 characters!" />}
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type={showConfirmNewPassword ? "text" : "password"}
                label="Confirm new password"
                variant="standard"
                fullWidth
                onChange={handleConfirmPassword}
                error={validation2}
                onFocus={() => setValidation2(false)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MDButton onClick={showConfirmNewPasswordFn}>
                        {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                      </MDButton>
                    </InputAdornment>
                  ),
                }}
              />
              {validation2 && <Danger validation="Passwords do not match!" />}
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButtonLoading
                // loading={loading}
                loadingPosition="start"
                // eslint-disable-next-line react/jsx-no-useless-fragment
                startIcon={<></>}
                variant="gradient"
                color="primary"
                fullWidth
                onClick={() => {
                  if (!validation1 && !validation2) handleResetPassword();
                }}
                size="large"
              >
                Confirm Password
              </MDButtonLoading>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                Nevermind...{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/login"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Go to login
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

export default ConfirmEmail;
