// User

export const AUTH_ERROR = "AUTH_ERROR";

export const EMAIL_VERIFIED = "EMAIL_VERIFIED";

export const LOGOUT = "LOGOUT";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGIN_VERIFICATION_REQUIRED = "LOGIN_VERIFICATION_REQUIRED";

export const SET_OPEN_VERIFICATION_MODAL = "SET_OPEN_VERIFICATION_MODAL";

export const SET_USER_LOADING = "SET_USER_LOADING";

export const SET_USERS_LOADING = "SET_USERS_LOADING";

export const SET_USER_LOAD = "SET_USERS_LOAD";

export const USER_LOADED = "USER_LOADED";

export const USER_ERROR = "USER_ERROR";

export const GET_USER_INFO = "GET_USER_INFO";

export const RESET_EMAIL_SENT = "RESET_EMAIL_SENT";

export const RESET_CODE_SUCCESS = "RESET_CODE_SUCCESS";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const RESET_CODE_DISABLED = "RESET_CODE_DISABLED";

export const UPDATE_USER = "UPDATE_USER";

export const UPDATED_PROFILE = "UPDATED_PROFILE";

export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

export const CLEAR_USER_LOADING = "CLEAR_USER_LOADING";

export const GET_HOTEL_LOCATION = "GET_HOTEL_LOCATION";

export const USER_INFO_FAIL = "USER_INFO_FAIL";

export const CONTACT_SUCCESS = "CONTACT_SUCCESS";

export const CONTACT_ERROR = "CONTACT_ERROR";

export const LINK_SENT = "LINK_SENT";

export const RESET_PASS_SUCCESSS = "RESET_PASS_SUCCESSS";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const REGISTER_FAIL = "REGISTER_FAIL";

export const GET_ONE_USER = "GET_ONE_USER";

export const USER_FAIL = "USER_FAIL";

export const EDIT_ERROR = "EDIT_ERROR";

export const UPDATE_SPECIFIC_USER = "UPDATE_SPECIFIC_USER";

export const GET_UPDATED_FILTER = "GET_UPDATED_FILTER";
export const GET_NO_FILTER = "GET_NO_FILTER";

export const CLEAR_FILTER = "CLEAR_FILTER";

export const DEACTIVATE = "DEACTIVATE";

export const ACTIVATE = "ACTIVATE";

export const GET_USERS = "GET_USERS";

export const CLEAR_ONE_USER = "CLEAR_ONE_USER";

export const SET_UPDATE_LOADING_USERS = "SET_UPDATE_LOADING_USERS";

export const TOKEN_CONFIRMED = "TOKEN_CONFIRMED";

export const CHECK_EMAIL_FAIL = "CHECK_EMAIL_FAIL";

export const CHECK_EMAIL = "CHECK_EMAIL";

// Clearings

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const CLEAR_DATA = "CLEAR_DATA";

// Alert

export const REMOVE_ALERT = "REMOVE_ALERT";

export const SET_ALERT = "SET_ALERT";

// Filters

export const CATEGORY_FILTER_ADD_SUCCESS = "CATEGORY_FILTER_ADD_SUCCESS";

export const SET_FILTER_LOADING = "SET_FILTER_LOADING";

export const FILTER_FAIL = "FILTER_FAIL";

export const UPDATE_FILTER = "UPDATE_FILTER";

// locations
export const CLEAR_SUBTAGS = "CLEAR_SUBTAGS";

export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";

export const SETTING_UPDATE_SUCCESS = "SETTING_UPDATE_SUCCESS";

export const PRODUCT_ADD_SUCCESS = "PRODUCT_ADD_SUCCESS";

export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";

export const GET_ONLINE_STORE = "GET_ONLINE_STORE";

export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const GET_LOCATION_TAGS = "GET_LOCATION_TAGS";

export const GET_SUB_LOCATION_TAGS = "GET_SUB_LOCATION_TAGS";

export const SET_LOCATION_LOADING = "SET_LOCATION_LOADING";

export const LOCATION_ADD_SUCCESS = "LOCATION_ADD_SUCCESS";

export const LOCATION_FAIL = "LOCATION_FAIL";

export const GET_LOCATIONS = "GET_LOCATIONS";

export const DELETE_LOCATION = "DELETE_LOCATION";

export const GET_ONE_LOCATION = "GET_ONE_LOCATION";

export const UPDATE_LOCATION = "UPDATE_LOCATION";

export const SET_LOCATION_APPEARANCE = "SET_LOCATION_APPEARANCE";

export const GET_USER_PARTNER_LOCATIONS = "GET_USER_PARTNER_LOCATIONS";

export const UPDATE_LOCATION_TRANSLATION = "UPDATE_LOCATION_TRANSLATION";

export const SLIDE_LOCATION_ADD_SUCCESS = "SLIDE_LOCATION_ADD_SUCCESS";

export const LOCATION_SLIDERS_FAIL = "LOCATION_SLIDERS_FAIL";

export const DELETE_LOCATION_SLIDER = "DELETE_LOCATION_SLIDER";

export const SLIDE_LOCATION_UPDATE = "SLIDE_LOCATION_UPDATE";

export const PUBLISH_UNPUBLISH = "PUBLISH_UNPUBLISH";

export const GET_LOCATION_TRANSLATION = "GET_LOCATION_TRANSLATION";

export const LOCATION_JOBS_ADD_SUCCESS = "LOCATION_JOBS_ADD_SUCCESS";
export const WIFI_LOCATION_ADD_SUCCESS = "WIFI_LOCATION_ADD_SUCCESS";
export const LOCATION_WIFI_FAIL = "LOCATION_WIFI_FAIL";

// ServiceJobs

export const SERVICES_JOBS_FAIL = "SERVICES_JOBS_FAIL";

export const GET_SERVICES_JOBS = "GET_SERVICES_JOBS";

// TravelDesks

export const SET_TRAVELDESK_LOADING = "SET_TRAVELDESK_LOADING";

export const TRAVELDESK_ADD_SUCCESS = "TRAVELDESK_ADD_SUCCESS";

export const GET_TRAVELDESKS = "GET_TRAVELDESKS";

export const TRAVELDESK_FAIL = "TRAVELDESK_FAIL";

export const UPDATE_TRAVELDESK = "UPDATE_TRAVELDESK";

export const GET_ONE_TRAVELDESK = "GET_ONE_TRAVELDESK";

export const DELETE_TRAVELDESK = "DELETE_TRAVELDESK";

// Menus
export const SORT_MENU = "SORT_MENU";

export const RESET_MENUS = "RESET_MENUS";

export const SET_MENU_LOADING = "SET_MENU_LOADING";

export const MENU_ADD_SUCCESS = "MENU_ADD_SUCCESS";

export const DESTINATIONS_MENU_ADD_SUCCESS = "MENU_ADD_SUCCESS";

export const LOCATIONS_MENU_ADD_SUCCESS = "MENU_ADD_SUCCESS";

export const MENU_FAIL = "MENU_FAIL";

export const DELETE_MENU = "DELETE_MENU";

export const GET_ONE_MENU = "GET_ONE_MENU";

export const UPDATE_MENU = "UPDATE_MENU";

export const SORT_GLOBAL_MENU = "SORT_GLOBAL_MENU";

export const SORT_LOCATION_MENU = "SORT_LOCATION_MENU";

export const SORT_DESTINATIONS_MENU = "SORT_DESTINATIONS_MENU";

export const UPDATE_MENU_TRANSLATION = "UPDATE_MENU_TRANSLATION";

export const GET_UPDATED_MENU_TYPE = "GET_UPDATED_MENU_TYPE";

export const SET_GLOBAL_MENU_LOADING = "SET_GLOBAL_MENU_LOADING";

export const GLOBAL_MENU_FAIL = "GLOBAL_MENU_FAIL";

export const GET_GLOBAL_MENUS = "GET_GLOBAL_MENUS";

export const GET_DESTINATIONS_MENUS = "GET_DESTINATIONS_MENUS";

export const GET_LOCATION_MENUS = "GET_LOCATION_MENUS";

export const DELETE_GLOBAL_MENU = "DELETE_GLOBAL_MENU";

export const SORT_GLOBAL_MENUS = "SORT_GLOBAL_MENUS";

// Partners

export const GET_PARTNERS = "GET_PARTNERS";

export const PARTNER_FAIL = "PARTNER_FAIL";

export const SET_PARTNER_LOADING = "SET_PARTNER_LOADING";

export const UPDATE_PARTNER = "UPDATE_PARTNER";

export const PARTNER_ADD_SUCCESS = "PARTNER_ADD_SUCCESS";

export const SET_PARTNERS_LOADING = "SET_PARTNERS_LOADING";

export const DELETE_PARTNER = "DELETE_PARTNER";

export const GET_ONE_PARTNER = "GET_ONE_PARTNER";

export const CHECK_PARTNERS = "CHECK_PARTNERS";

export const CHECK_PARTNERS_FAIL = "CHECK_PARTNERS_FAIL";

// Tags

export const TAG_ADD_SUCCESS = "TAG_ADD_SUCCESS";

export const GET_ONE_TAG = "GET_ONE_TAG";

export const TAG_FAIL = "TAG_FAIL";

export const UPDATE_TAG = "UPDATE_TAG";

export const DELETE_TAG = "DELETE_TAG";

export const GET_TAGS = "GET_TAGS";

export const SET_TAG_LOADING = "SET_TAG_LOADING";

export const CLEAR_ONE_TAG = "CLEAR_ONE_TAG";

// Cities

export const SET_CITY_LOADING = "SET_CITY_LOADING";

export const CITY_ADD_SUCCESS = "CITY_ADD_SUCCESS";

export const GET_CITIES = "GET_CITIES";

export const CITY_FAIL = "CITY_FAIL";

export const UPDATE_CITY = "UPDATE_CITY";

export const GET_ONE_CITY = "GET_ONE_CITY";

export const DELETE_CITY = "DELETE_CITY";

export const UPDATE_CITY_TRANSLATION = "UPDATE_CITY_TRANSLATION";

export const SLIDE_CITY_ADD_SUCCESS = "SLIDE_CITY_ADD_SUCCESS";

export const CITY_SLIDERS_FAIL = "CITY_SLIDERS_FAIL";

export const DELETE_CITY_SLIDER = "DELETE_CITY_SLIDER";

export const SLIDE_CITY_UPDATE = "SLIDE_CITY_UPDATE";

export const CHECK_CITIES = "CHECK_CITIES";

export const CHECK_CITIES_FAIL = "CHECK_CITIES_FAIL";

export const CHECK_COUNTRIES = "CHECK_COUNTRIES";

export const CHECK_COUNTRIES_FAIL = "CHECK_COUNTRIES_FAIL";

// Categories

export const SET_CATEGORY_LOADING = "SET_CATEGORY_LOADING";

export const CATEGORY_ADD_SUCCESS = "CATEGORY_ADD_SUCCESS";

export const CATEGORY_FAIL = "CATEGORY_FAIL";

export const GET_CATEGORIES = "GET_CATEGORIES";

export const DELETE_CATEGORY = "DELETE_CATEGORY";

export const GET_ONE_CATEGORY = "GET_ONE_CATEGORY";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

// Global Boxes

export const SET_GLOBAL_BOX_LOADING = "SET_GLOBAL_BOX_LOADING";

export const GLOBAL_BOX_ADD_SUCCESS = "GLOBAL_BOX_ADD_SUCCESS";

export const GLOBAL_BOX_FAIL = "GLOBAL_BOX_FAIL";

export const GET_GLOBAL_BOXES = "GET_GLOBAL_BOXES";

export const DELETE_GLOBAL_BOX = "DELETE_GLOBAL_BOX";

export const SORT_GLOBAL_BOXES = "SORT_GLOBAL_BOXES";

// City Boxes

export const BOXES_FAIL = "BOXES_FAIL";

export const GET_BOXES = "GET_BOXES";

export const SORT_BOXES = "SORT_BOXES";

export const REMOVE_BOX = "REMOVE_BOX";

export const ADD_BOX = "ADD_BOX";

// Location Boxes

export const GET_LOCATIONBOXES = "GET_LOCATIONBOXES";

export const ADD_LOCATIONBOX = "ADD_LOCATIONBOX";

export const LOCATIONBOXES_FAIL = "LOCATIONBOXES_FAIL";

export const REMOVE_LOCATIONBOX = "REMOVE_LOCATIONBOX";

export const SORT_LOCATIONBOXES = "SORT_LOCATIONBOXES";

// Banners

export const BANNER_FAIL = "BANNER_FAIL";

export const ADD_BANNER = "ADD_BANNER";

export const SET_BANNER_LOADING = "SET_BANNER_LOADING";

export const GET_BANNERS = "GET_BANNERS";

export const UPDATE_BANNER = "UPDATE_BANNER";

export const REMOVE_BANNER = "REMOVE_BANNER";

export const GET_ALL_BANNERS = "GET_ALL_BANNERS";

export const GET_ONE_BANNER = "GET_ONE_BANNER";

// Recomendations

export const SET_RECOMMENDATION_LOADING = "SET_RECOMMENDATION_LOADING";

export const RECOMMENDATION_ADD_SUCCESS = "RECOMMENDATION_ADD_SUCCESS";

export const RECOMMENDATION_FAIL = "RECOMMENDATION_FAIL";

export const GET_RECOMMENDATIONS = "GET_RECOMMENDATIONS";

export const DELETE_RECOMMENDATION = "DELETE_RECOMMENDATION";

export const DELETE_SINGLE_RECOMMENDATION = "DELETE_SINGLE_RECOMMENDATION";

export const GET_ONE_RECOMMENDATION = "GET_ONE_RECOMMENDATION";

export const UPDATE_RECOMMENDATION = "UPDATE_RECOMMENDATION";

export const UPDATE_RECOMMENDATION_TRANSLATION = "UPDATE_RECOMMENDATION_TRANSLATION";

export const GET_CUISINES = "GET_CUISINES";

export const GET_FEATURES = "GET_FEATURES";
export const CUISINES_FAIL = "CUISINES_FAIL";
export const FEATURES_FAIL = "FEATURES_FAIL";

export const CHECK_RECOMMENDATIONS = "CHECK_RECOMMENDATIONS";

// Items

export const UPDATE_ITEM = "UPDATE_ITEM";

export const GET_ONE_ITEM = "GET_ONE_ITEM";

export const GET_ITEMS = "GET_ITEMS";

export const DELETE_ITEM = "DELETE_ITEM";

export const ITEM_FAIL = "ITEM_FAIL";

export const ITEM_ADD_SUCCESS_STEP0 = "ITEM_ADD_SUCCESS_STEP0";

export const ITEM_ADD_SUCCESS_STEP1 = "ITEM_ADD_SUCCESS_STEP1";

export const ITEM_ADD_SUCCESS_STEP2 = "ITEM_ADD_SUCCESS_STEP2";

export const ITEM_ADD_SUCCESS_STEP3 = "ITEM_ADD_SUCCESS_STEP3";

export const ITEM_ADD_SUCCESS_STEP4 = "ITEM_ADD_SUCCESS_STEP4";

export const SET_TOP_FALSE = "SET_TOP_FALSE";

export const SET_TOP_TRUE = "SET_TOP_TRUE";

export const SET_ITEM_LOADING = "SET_ITEM_LOADING";

export const DELETE_SINGLE_ITEM = "DELETE_SINGLE_ITEM";

export const UPDATE_ITEM_TRANSLATION = "UPDATE_ITEM_TRANSLATION";

export const GENERATE_ITEMS_LIST = "GENERATE_ITEMS_LIST";

export const GENERATE_ITEMS_LIST_FAIL = "GENERATE_ITEMS_LIST_FAIL";

export const SET_ITEM_WIDGET_LOADING = "SET_ITEM_WIDGET_LOADING";

export const GET_GENERATED_ITEMS = "GET_GENERATED_ITEMS";

export const CLEAR_GENERATED_ITEMS = "CLEAR_GENERATED_ITEMS";

export const APPROVE = "APPROVE";

export const REJECT = "REJECT";

export const CHECK_ITEMS = "CHECK_ITEMS";

export const SET_ITEM_EMPTY = "SET_ITEM_EMPTY";

export const GET_UPDATED_TICKET_TYPE = "GET_UPDATED_TICKET_TYPE";

export const GET_TICKET_TRANSLATION = "GET_TICKET_TRANSLATION";

// Attractions

export const SET_ATTRACTION_LOADING = "SET_ATTRACTION_LOADING";

export const ATTRACTION_ADD_SUCCESS = "ATTRACTION_ADD_SUCCESS";

export const ATTRACTION_FAIL = "ATTRACTION_FAIL";

export const GET_ATTRACTIONS = "GET_ATTRACTIONS";

export const DELETE_ATTRACTION = "DELETE_ATTRACTION";

export const GET_ONE_ATTRACTION = "GET_ONE_ATTRACTION";

export const UPDATE_ATTRACTION = "UPDATE_ATTRACTION";

export const UPDATE_ATTRACTION_TRANSLATION = "UPDATE_ATTRACTION_TRANSLATION";

export const DELETE_SINGLE_ATTRACTION = "DELETE_SINGLE_ATTRACTION";

// Experiences

export const SET_EXPERIENCE_LOADING = "SET_EXPERIENCE_LOADING";

export const EXPERIENCE_ADD_SUCCESS = "EXPERIENCE_ADD_SUCCESS";

export const EXPERIENCE_FAIL = "EXPERIENCE_FAIL";

export const GET_EXPERIENCES = "GET_EXPERIENCES";

export const DELETE_EXPERIENCE = "DELETE_EXPERIENCE";

export const GET_ONE_EXPERIENCE = "GET_ONE_EXPERIENCE";

export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";

export const UPDATE_EXPERIENCE_TRANSLATION = "UPDATE_EXPERIENCE_TRANSLATION";

// Stops

export const STOPS_FAIL = "STOPS_FAIL";

export const DELETE_STOP = "DELETE_STOP";

export const STOP_ADD_SUCCESS = "STOP_ADD_SUCCESS";

export const GET_STOPS = "GET_STOPS";

export const SORT_STOPS = "SORT_STOPS";

// Offers

export const UPDATE_OFFER = "UPDATE_OFFER";

export const GET_ONE_OFFER = "GET_ONE_OFFER";

export const GET_OFFERS = "GET_OFFERS";

export const DELETE_OFFER = "DELETE_OFFER";

export const OFFER_FAIL = "OFFER_FAIL";

export const OFFER_ADD_SUCCESS = "OFFER_ADD_SUCCESS";

// Promotions

export const UPDATE_PROMOTION = "UPDATE_PROMOTION";

export const GET_ONE_PROMOTION = "GET_ONE_PROMOTION";

export const GET_PROMOTIONS = "GET_PROMOTIONS";

export const DELETE_PROMOTION = "DELETE_PROMOTION";

export const PROMOTION_FAIL = "PROMOTION_FAIL";

export const PROMOTION_ADD_SUCCESS = "PROMOTION_ADD_SUCCESS";

export const SET_PROMOTION_LOADING = "SET_PROMOTION_LOADING";

export const UPDATE_PROMOTION_TRANSLATION = "UPDATE_PROMOTION_TRANSLATION";
export const GET_PROMOTION_TRANSLATION = "GET_PROMOTION_TRANSLATION";

export const DELETE_SINGLE_PROMOTION = "DELETE_SINGLE_PROMOTION";

export const SET_PROMOTION_EMPTY = "SET_PROMOTION_EMPTY";

// News

export const UPDATE_NEWS = "UPDATE_NEWS";

export const GET_ONE_NEWS = "GET_ONE_NEWS";

export const GET_ALL_NEWS = "GET_ALL_NEWS";

export const DELETE_NEWS = "DELETE_NEWS";

export const NEWS_FAIL = "NEWS_FAIL";

export const NEWS_ADD_SUCCESS = "NEWS_ADD_SUCCESS";

export const SET_NEWS_LOADING = "SET_NEWS_LOADING";

export const UPDATE_NEWS_TRANSLATION = "UPDATE_NEWS_TRANSLATION";

// Jobs

export const UPDATE_JOB = "UPDATE_JOB";

export const GET_ONE_JOB = "GET_ONE_JOB";

export const DELETE_JOB = "DELETE_JOB";

export const JOB_FAIL = "JOB_FAIL";

export const JOB_ADD_SUCCESS = "JOB_ADD_SUCCESS";

export const SET_JOB_LOADING = "SET_JOB_LOADING";

export const SET_JOB_EMPTY = "SET_JOB_EMPTY";

// Facilities

export const SET_FACILITY_LOADING = "SET_FACILITY_LOADING";

export const FACILITY_ADD_SUCCESS = "FACILITY_ADD_SUCCESS";

export const FACILITY_FAIL = "FACILITY_FAIL";

export const GET_FACILITIES = "GET_FACILITIES";

export const GET_LOCATION_FACILITIES = "GET_LOCATION_FACILITIES";

export const DELETE_FACILITY = "DELETE_FACILITY";

export const GET_ONE_FACILITY = "GET_ONE_FACILITY";

export const UPDATE_FACILITY = "UPDATE_FACILITY";

export const UPDATE_FACILITY_TRANSLATION = "UPDATE_FACILITY_TRANSLATION";

export const SET_FACILITY_EMPTY = "SET_FACILITY_EMPTY";

// Reports

export const REPORT_FAIL = "REPORT_FAIL";

export const INVOICE_FAIL = "INVOICE_FAIL";

export const CLEAR_INVOICE = "CLEAR_INVOICE";

export const DELETE_REPORT = "DELETE_REPORT";

export const REPORT_ADD_SUCCESS = "REPORT_ADD_SUCCESS";

export const INVOICE_ADD_SUCCESS = "INVOICE_ADD_SUCCESS";

export const GET_ONE_REPORT = "GET_ONE_REPORT";

export const GET_REPORTS = "GET_REPORTS";

export const UPDATE_REPORT = "UPDATE_REPORT";

export const SET_REPORT_LOADING = "SET_REPORT_LOADING";

export const HEADER_INFO_SUCCESS = "HEADER_INFO_SUCCESS";

export const CLEAR_HEADER_AND_REPORTS = "CLEAR_HEADER_AND_REPORTS";

export const PRODUCTION_REPORTS_ADD_SUCCESS = "PRODUCTION_REPORTS_ADD_SUCCESS";

export const CART_REPORTS_ADD_SUCCESS = "CART_REPORTS_ADD_SUCCESS";

export const TRAFFIC_REPORTS_BY_COUNTRY_ADD_SUCCESS = "TRAFFIC_REPORTS_BY_COUNTRY_ADD_SUCCESS";

export const GET_DROPLIST_TRAFFIC_REPORTS = "GET_DROPLIST_TRAFFIC_REPORTS";

export const AVERAGE_CART_VALUE_SUCCESS = "AVERAGE_CART_VALUE_SUCCESS";

// Widgets

export const SET_WIDGET_LOADING = "SET_WIDGET_LOADING";

export const WIDGET_ADD_SUCCESS = "WIDGET_ADD_SUCCESS";

export const WIDGET_FAIL = "WIDGET_FAIL";

export const GET_WIDGETS = "GET_WIDGETS";

export const DELETE_WIDGET = "DELETE_WIDGET";

export const GET_ONE_WIDGET = "GET_ONE_WIDGET";

export const UPDATE_WIDGET = "UPDATE_WIDGET";

export const GET_ONE_ELEMENT = "GET_ONE_ELEMENT";

export const ELEMENT_FAIL = "ELEMENT_FAIL";

export const SORT_ELEMENTS = "SORT_ELEMENTS";

export const SET_WIDGET_EMPTY = "SET_WIDGET_EMPTY";

export const UPDATE_WIDGET_TRANSLATION = "UPDATE_WIDGET_TRANSLATION";

// Vouchers

export const SET_VOUCHER_LOADING = "SET_VOUCHER_LOADING";

export const VOUCHER_ADD_SUCCESS = "VOUCHER_ADD_SUCCESS";

export const VOUCHER_FAIL = "VOUCHER_FAIL";

export const GET_VOUCHERS = "GET_VOUCHERS";

export const DELETE_VOUCHER = "DELETE_VOUCHER";

export const GET_ONE_VOUCHER = "GET_ONE_VOUCHER";

export const UPDATE_VOUCHER = "UPDATE_VOUCHER";

export const UPDATE_VOUCHER_TRANSLATION = "UPDATE_VOUCHER_TRANSLATION";

export const DELETE_SINGLE_VOUCHER = "DELETE_SINGLE_VOUCHER";

export const GET_PARTNER_VOUCHERS = "GET_PARTNER_VOUCHERS";

export const VOUCHER_PARTNER_ADD_SUCCESS = "VOUCHER_PARTNER_ADD_SUCCESS";

// Stops

export const ELEMENTS_FAIL = "ELEMENTS_FAIL";

export const DELETE_ELEMENT = "DELETE_ELEMENT";

export const ELEMENT_ADD_SUCCESS = "ELEMENT_ADD_SUCCESS";

export const GET_ELEMENTS = "GET_ELEMENTS";

// Campaigns

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";

export const GET_ONE_CAMPAIGN = "GET_ONE_CAMPAIGN";

export const GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS";

export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";

export const CAMPAIGN_FAIL = "CAMPAIGN_FAIL";

export const CAMPAIGN_ADD_SUCCESS = "CAMPAIGN_ADD_SUCCESS";

export const SET_CAMPAIGN_LOADING = "SET_CAMPAIGN_LOADING";

// PromoCodes

export const UPDATE_PROMOCODE = "UPDATE_PROMOCODE";

export const GET_ONE_PROMOCODE = "GET_ONE_PROMOCODE";

export const GET_ALL_PROMOCODES = "GET_ALL_PROMOCODES";

export const DELETE_PROMOCODE = "DELETE_PROMOCODE";

export const PROMOCODE_FAIL = "PROMOCODE_FAIL";

export const PROMOCODE_ADD_SUCCESS = "PROMOCODE_ADD_SUCCESS";

export const SET_PROMOCODE_LOADING = "SET_PROMOCODE_LOADING";

export const SET_PROMOCODE_EMPTY = "SET_PROMOCODE_EMPTY";

// Statistics

export const GET_DASHBOARD_STATS = "GET_DASHBOARD_STATS";

export const GET_DASHBOARD_ORDERS = "GET_DASHBOARD_ORDERS";

export const GET_DASHBOARD_BOOKINGS = "GET_DASHBOARD_BOOKINGS";

export const GET_CONCIERGE_ORDERS = "GET_CONCIERGE_ORDERS";

export const STATS_FAIL = "STATS_FAIL";

export const SET_STATS_LOADING = "SET_STATS_LOADING";

export const GET_TOPITEMS = "GET_TOPITEMS";

export const GET_COMPANION_ANALYTICS = "GET_COMPANION_ANALYTICS";
export const COMPANION_ANALYTICS_FAIL = "COMPANION_ANALYTICS_FAIL";

// Trends

export const GET_TRENDS = "GET_TRENDS";

export const TRENDS_FAIL = "TRENDS_FAIL";

export const SET_TRENDS_LOADING = "SET_TRENDS_LOADING";

export const ADD_TREND = "ADD_TREND";

export const REMOVE_TREND_ITEM = "REMOVE_TREND_ITEM";

export const REMOVE_TREND_RECOMMENDATION = "REMOVE_TREND_RECOMMENDATION";

export const CITIES_FAIL = "CITIES_FAIL";

export const GET_UPDATED_DESTINATION_ID = "GET_UPDATED_DESTINATION_ID";

// PDF

export const PDF_SUCCESS = "PDF_SUCCESS";

export const PDF_FAIL = "PDF_FAIL";

// CSV

export const CSV_SUCCESS = "CSV_SUCCESS";

export const CSV_FAIL = "CSV_FAIL";

// export

export const SET_EXPORT_LOADING = "SET_EXPORT_LOADING";

// Destinations

export const UPDATE_DESTINATION = "UPDATE_DESTINATION";

export const DESTINATION_ADD_SUCCESS = "DESTINATION_ADD_SUCCESS";

export const GET_ONE_DESTINATION = "GET_ONE_DESTINATION";

export const GET_ALL_DESTINATION = "GET_ALL_DESTINATION";

export const DELETE_DESTINATION = "DELETE_DESTINATION";

export const DESTINATION_FAIL = "DESTINATION_FAIL";

export const SET_DESTINATION_LOADING = "SET_DESTINATION_LOADING";

export const CLEAR_DESTINATIONS_ERRORS = "CLEAR_DESTINATIONS_ERRORS";

export const UPDATE_DESTINATION_TRANSLATION = "UPDATE_DESTINATION_TRANSLATION";

// Curators

export const SET_CURATOR_LOADING = "SET_CURATOR_LOADING";

export const CURATOR_ADD_SUCCESS = "CURATOR_ADD_SUCCESS";

export const CURATOR_FAIL = "CURATOR_FAIL";

export const UPDATE_CURATOR = "UPDATE_CURATOR";

export const SET_CURATOR_EMPTY = "DESTINATION_FAIL";

export const DELETE_CURATOR = "DELETE_CURATOR";

export const GET_ONE_CURATOR = "GET_ONE_CURATOR";

export const GET_CURATORS = "GET_CURATORS";

// VENUES

export const VENUE_ADD_SUCCESS = "VENUE_ADD_SUCCESS";

export const SET_VENUE_LOADING = "SET_VENUE_LOADING";

export const VENUE_FAIL = "VENUE_FAIL";

export const UPDATE_VENUE = "UPDATE_VENUE";

export const DELETE_SINGLE_VENUE = "DELETE_SINGLE_VENUE";

export const GET_ONE_VENUE = "GET_ONE_VENUE";

export const GET_ALL_VENUES = "GET_ALL_VENUES";

// Refresh

export const REFRESH_LOCATION = "REFRESH_LOCATION";

export const REFRESH_LOCATION_FAIL = "REFRESH_LOCATION_FAIL";

export const SET_REFRESH_LOADING = "SET_REFRESH_LOADING";

// Itineraries

export const ITINERARY_ADD_SUCCESS = "ITINERARY_ADD_SUCCESS";

export const SET_ITINERARY_LOADING = "SET_ITINERARY_LOADING";

export const ITINERARY_FAIL = "ITINERARY_FAIL";

export const UPDATE_ITINERARY = "UPDATE_ITINERARY";

export const DELETE_SINGLE_ITINERARY = "DELETE_SINGLE_ITINERARY";

export const GET_ONE_ITINERARY = "GET_ONE_ITINERARY";

export const GET_ALL_ITINERARIES = "GET_ALL_ITINERARIES";

export const ITINERARY_STOP_ADD_SUCCESS = "ITINERARY_STOP_ADD_SUCCESS";

export const ITINERARY_STOPS_FAIL = "ITINERARY_STOPS_FAIL";

export const DELETE_SINGLE_STOP = "DELETE_SINGLE_STOP";

// Orders

export const FETCH_CANCEL_REASONS_REQUEST = "FETCH_CANCEL_REASONS_REQUEST";
export const FETCH_CANCEL_REASONS_SUCCESS = "FETCH_CANCEL_REASONS_SUCCESS";
export const FETCH_CANCEL_REASONS_FAILURE = "FETCH_CANCEL_REASONS_FAILURE";

export const FETCH_ITEM_QUOTE_REQUEST = "FETCH_ITEM_QUOTE_REQUEST";
export const FETCH_ITEM_QUOTE_SUCCESS = "FETCH_ITEM_QUOTE_SUCCESS";
export const FETCH_ITEM_QUOTE_FAILURE = "FETCH_ITEM_QUOTE_FAILURE";

export const MARK_AS_READ_FAILURE = "MARK_AS_READ_FAILURE";
export const MARK_AS_READ_SUCCESS = "MARK_AS_READ_SUCCESS";

export const CLEAR_ITEM_QUOTE = "CLEAR_ITEM_QUOTE";
export const CANCEL_ITEM_REQUEST = "CANCEL_ITEM_REQUEST";
export const CANCEL_ITEM_SUCCESS = "CANCEL_ITEM_SUCCESS";
export const CANCEL_ITEM_FAILURE = "CANCEL_ITEM_FAILURE";
