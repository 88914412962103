/* eslint-disable no-use-before-define */
import React from "react";
import { Html, Head, Body, Container, Section, Text, Link } from "@react-email/components";
import PropTypes from "prop-types";
import FooterSection from "../components/FooterSection";
import DetailsCard from "../components/DetailsCard";

const B2POffersPromotionsConfirmation = ({ data = {} }) => {
  /* ****** Constants ****** */
  const {
    guests = "2",
    time = "07:00",
    date = "13/11/2000",
    phone = "1234567890",
    name = "Lokalee User",
    email = "lokalee.app",
    _id = "",
    ref: {
      location = { name: "Lokalee Location" },
      title = "Offers & Promotions Confirmation",
      recommendation = { title: "Lokalee Recommendation" },
    } = {},
  } = data;
  const isProduction = process.env.NODE_ENV === "production";
  const apiBaseUrl = isProduction ? "https://" : "https://staging.";

  return (
    <Html>
      <Head />
      <Body style={styles.body}>
        <Container style={styles.container}>
          <Section style={styles.header}>
            <img
              alt="Lokalee Logo"
              style={styles.logo}
              src="https://email-assets.lokalee.app/email/emailImages/logo.png"
            />
          </Section>

          <Section style={styles.whiteContainer}>
            <Text style={styles.title}>Pending</Text>
            <Text style={styles.highlight}>Request.</Text>
            <Text style={styles.greeting}>
              Dear {recommendation?.title ? recommendation.title : location.name},
            </Text>
            <Text style={styles.message}>
              You have a pending request to book {title}. Kindly review the details to get back to
              the guest with confirmation.
            </Text>
            <Text style={styles.detailsTitle}>Booking Details</Text>

            <DetailsCard
              numOfGuests={guests}
              bookingTime={time}
              bookingDate={date}
              visitorName={name}
              visitorPhone={phone}
              visitorEmail={email}
            />

            <Section style={styles.buttonContainer}>
              <Link
                href={`${apiBaseUrl}lokalee.app/recommendations/confirmationSent?bookingId=${_id}`}
                style={styles.button}
              >
                Confirm Booking
              </Link>
            </Section>

            <Text style={styles.additionalMessage}>
              In case you cannot confirm, please contact the guest to inform them.
            </Text>

            <div style={styles.separator} />

            <div style={styles.footerContainer}>
              <Text style={styles.footerText}>If you need assistance, get in touch</Text>

              <a href="mailto:cs@lokalee.app">
                <p style={styles.link}>Help Center</p>
              </a>
            </div>
          </Section>

          <FooterSection />
        </Container>
      </Body>
    </Html>
  );
};

export default B2POffersPromotionsConfirmation;

const styles = {
  body: {
    margin: 0,
    padding: 0,
    backgroundColor: "#f4f4f4",
    fontFamily: "Arial, sans-serif",
  },

  container: {
    width: "600px",
    padding: "24px",
    margin: "20px auto",
    borderRadius: "8px",
    backgroundColor: "#E6EBE9",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },

  header: {
    height: "75px",
  },

  whiteContainer: {
    padding: "24px",
    borderRadius: "8px",
    marginBottom: "24px",
    backgroundColor: "#ffffff",
  },

  logo: {
    height: "40px",
  },

  title: {
    fontSize: "54px",
    fontWeight: "700",
    marginBottom: "32px",
  },

  highlight: {
    color: "#28a745",
    fontSize: "54px",
    fontWeight: "700",
    marginBottom: "24px",
  },

  greeting: {
    fontSize: "16px",
    fontWeight: "700",
    marginBottom: "10px",
  },

  message: {
    margin: "0px",
    fontSize: "16px",
    marginBottom: "24px",
  },

  detailsTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "12px",
  },

  separator: {
    margin: "10px 0",
    borderTop: "1px solid #E6EBE9",
  },

  buttonContainer: {
    textAlign: "center",
    marginBottom: "24px",
  },

  button: {
    color: "#fff",
    textAlign: "center",
    marginBottom: "24px",
    fontSize: "16px",
    borderRadius: "8px",
    padding: "16px 24px",
    textDecoration: "none",
    backgroundColor: "#28a745",
  },

  additionalMessage: {
    fontSize: "14px",
    textAlign: "center",
    marginBottom: "24px",
  },

  footerContainer: {
    textAlign: "center",
    fontSize: "12px",
    color: "#888",
  },

  footerText: {
    margin: "0",
    color: "black",
    fontSize: "12px",
    alignText: "center",
  },

  link: {
    margin: "0",
    fontSize: "14px",
    color: "#00850D",
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
};

B2POffersPromotionsConfirmation.propTypes = {
  data: PropTypes.shape({}),
};
