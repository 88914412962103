const travelStylesList = [
  { label: "First-Timer Friendly", value: "First-Timer Friendly" },
  { label: "Couples' Getaway", value: "Couples' Getaway" },
  { label: "Family Adventures", value: "Family Adventures" },
  { label: "Solo Travelers", value: "Solo Travelers" },
  { label: "Travel with Friends", value: "Travel with Friends" },
  { label: "Budget-Friendly", value: "Budget-Friendly" },
  { label: "Luxury Escapes", value: "Luxury Escapes" },
];

export default travelStylesList;
