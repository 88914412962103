/* eslint-disable no-underscore-dangle */

// Libraries
import { createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Layout
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";

// Material UI components
import Grid from "@mui/material/Grid2";
import { updateFacility, deleteFacility, setFacilityEmpty } from "store/actions/facilityActions";
import { getPdf, getCsv } from "store/actions/exportAction";
import columnsMap from "components/MaterialTablePicker/TableColumns";

const AllFacilities = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.facilities.loading);
  const added = useSelector((state) => state.facilities.added);
  const user = useSelector((state) => state.users.user);

  const deleteFacilitiyFn = (id) => {
    dispatch(deleteFacility(id));
  };

  const updateFacilityFn = (data) => {
    const updatedData = { _id: data._id, title: data.title };
    dispatch(updateFacility(updatedData));
  };

  useEffect(() => {
    dispatch(setFacilityEmpty());
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12 }}>
          <MDBox>
            <MaterialTablePicker
              key={added}
              title="Other Facilities"
              columns={[
                columnsMap.titleCol,
                columnsMap.locationNameWithFilter,
                columnsMap.pdfFileCol,
              ]}
              tableRef={tableRef}
              searchField="facilities"
              exportMenuField="facilities"
              asyncTable
              asyncURL={`${process.env.REACT_APP_API}/facilities?`}
              currentUrl="otherFacilities"
              addUrl="otherFacilities/new-facility"
              onRowClickURL="/admin/otherFacilities"
              idSynonymName="_id"
              sortField="createdAt"
              direction="desc"
              editableUpdate={updateFacilityFn}
              editableDelete={deleteFacilitiyFn}
              updateRoleAction
              deleteRoleAction
              editableRole
              isSearchable={false}
              isLoading={loading}
              actionsName="Facility"
              actionsRole
              getPdf={(value, query) => {
                if (value) {
                  dispatch(
                    getPdf(value, `stats/pdf${query}&fields=title,locationName,pdf`, "Facilities")
                  );
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for PDF export.");
                }
              }}
              getCsv={(query) => {
                if (user?.role === "admin") {
                  dispatch(getCsv(`stats/csv${query}&fields=title,locationName,pdf`, "Facilities"));
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for CSV export.");
                }
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default AllFacilities;
