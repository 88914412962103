/* eslint-disable no-use-before-define */
import React from "react";
import PropTypes from "prop-types";
import { Img, Html, Head, Body, Text, Preview, Section, Container } from "@react-email/components";

import ReservationDetails from "../Components/ReservationDetails";
import HeaderComponent from "../Components/HeaderComponent";

const B2B2COffersPromotionsCancellation = ({ data = {} }) => {
  /* ****** Constants ****** */
  const {
    guests = 2,
    name = "User",
    date = "2025-1-5",
    time = "09:00AM - 4:00PM",
    geoLocationData: { address = "Dubai, UAE" } = {},
    geoLocation: { coordinates = [35.64868439956602, 34.01099356215512] } = {},
    locationLogo = "https://email-assets.lokalee.app/email/emailImages/HiltonHotelsLogo.png",
    ref: {
      title = "Offers & Promotions Confirmation",
      logo = "https://email-assets.lokalee.app/email/emailImages/wakame-Modern-Asian-Cuisine.png",
    } = {},
  } = data;

  return (
    <Html>
      <Head />

      <Preview>Your reservation is cancelled! 🎉</Preview>

      <Body style={styles.body}>
        <Container style={styles.container}>
          <HeaderComponent name={name} locationLogo={locationLogo} />

          <Section style={styles.hero}>
            <Img
              style={styles.icon}
              alt="Celebration Icon"
              src="https://email-assets.lokalee.app/email/emailImages/calendar-delete.png"
            />

            <Section>
              <Text style={styles.title}>Booking</Text>
              <Text style={styles.cancelStyle}>Canceled</Text>
            </Section>
          </Section>

          <Section style={styles.reservationDetails}>
            <Text style={styles.description}>
              Thank you for letting us know that your plans have changed. Your request to cancel
              this booking is successful.
            </Text>

            <Text style={styles.sectionTitle}>Reservation Details</Text>

            <ReservationDetails
              time={time}
              date={date}
              image={logo}
              title={title}
              numOfGuests={guests}
            />

            <a href={`https://maps.google.com/?q=${coordinates[1]},${coordinates[0]}`}>
              <Img
                width="600"
                height="121"
                style={styles.map}
                alt="Map of the Location"
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${coordinates[1]},${coordinates[0]}&zoom=15&size=600x121&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_API}`}
              />

              <Text style={styles.address}>{address}</Text>
            </a>

            <div style={styles.seperatorLine} />

            <Text style={styles.footerText}>If you need assistance, get in touch. </Text>

            <a href="mailto:cs@lokalee.app">
              <Text style={styles.helpCenterLink}>Help Center</Text>
            </a>
          </Section>
        </Container>
      </Body>
    </Html>
  );
};

const styles = {
  body: {
    margin: 0,
    padding: 0,
    fontFamily: "Helvetica",
    backgroundColor: "#f4f4f4",
  },

  container: {
    padding: "24px",
    maxWidth: "637px",
    margin: "20px auto",
    borderRadius: "8px",
    backgroundColor: "#E6EBE9",
  },

  hero: {
    marginBottom: "40px",
  },

  icon: {
    width: "100px",
    height: "100px",
    margin: "0px auto",
  },

  title: {
    fontSize: "54px",
    fontWeight: "700",
    textAlign: "center",
  },

  cancelStyle: {
    fontSize: "54px",
    color: "#28a745",
    marginTop: "35px",
    fontWeight: "700",
    textAlign: "center",
  },

  reservationDetails: {
    padding: "20px",
    borderRadius: "8px",
    marginBottom: "20px",
    backgroundColor: "#fff",
  },

  description: {
    fontSize: "16px",
    lineHeight: "20px",
    marginBottom: "24px",
  },

  sectionTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "10px",
  },

  map: {
    margin: "20px 0",
    borderRadius: "8px",
  },

  address: {
    color: "#4F6FDE",
    fontSize: "15px",
    marginBottom: "24px",
    textDecoration: "underline",
  },

  seperatorLine: {
    height: "1px",
    marginBottom: "24px",
    backgroundColor: "#D8D8D8",
  },

  footerText: {
    margin: "0px",
    fontSize: "12px",
    textAlign: "center",
  },

  helpCenterLink: {
    margin: "0px",
    color: "#00850D",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "center",
    textDecoration: "underline",
  },

  preferences: {
    fontSize: "12px",
    textAlign: "center",
  },

  link: {
    color: "#3F3E3E",
    fontSize: "12px",
    textAlign: "center",
    textDecoration: "underline",
  },
};

B2B2COffersPromotionsCancellation.propTypes = {
  data: PropTypes.shape({}),
};

export default B2B2COffersPromotionsCancellation;
