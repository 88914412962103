import React from "react";
import PropTypes from "prop-types";
import { Grid2, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import AccessibilityForm from "../AccessibilityForm";

const PhysicalRestrictionsSection = ({
  values,
  setFieldValue,
  selectedAdditionalInfo,
  handleAdditionalInfoChange,
  restrictions,
  handleHealthRestrictionChange,
  healthRestrictions,
  physicalDifficultyLevelV,
  radioOptions,
  helperTextStyle = {},
}) => (
  <MDBox mt={1.625}>
    <Grid2 item size={{ xs: 12 }}>
      <div style={{ display: "flex", flexDirection: "column", marginBottom: "15px" }}>
        <MDTypography variant="h6">Add any Physical restrictions</MDTypography>
        <MDTypography variant="regular" style={{ fontSize: "small" }}>
          This information will help travelers know if this is a good ticket/pass for them
        </MDTypography>
      </div>

      <AccessibilityForm
        selectedAdditionalInfo={selectedAdditionalInfo}
        handleAccessibilityChange={handleAdditionalInfoChange}
        setFieldValue={setFieldValue}
        physicalRestrictionsV={values?.additionalInfo || values?.physicalRestrictions}
      />
    </Grid2>
    <Grid2 item size={{ xs: 12 }} mt={3}>
      <div>
        <FormLabel component="legend">Health restrictions </FormLabel>
        <FormLabel component="legend" style={{ fontSize: "12px" }}>
          Check all that apply
        </FormLabel>
        <FormGroup>
          {healthRestrictions.map((restriction) => (
            <FormControlLabel
              key={restriction.value}
              control={
                <Checkbox
                  checked={
                    values?.healthRestrictions?.includes(restriction.value) ||
                    restrictions.includes(restriction.value)
                  }
                  onChange={handleHealthRestrictionChange}
                  value={restriction.value}
                />
              }
              label={restriction.label}
            />
          ))}
        </FormGroup>
      </div>
    </Grid2>
    <Grid2 item size={{ xs: 12 }} mt={3}>
      <FormLabel component="legend">Select the physical difficulty level</FormLabel>
      <DynamicRadioGroup
        isColumn
        value={physicalDifficultyLevelV}
        options={radioOptions}
        name="physicalDifficultyLevel"
        setFieldValue={setFieldValue}
        disabled={false}
        helperTextStyle={helperTextStyle}
      />
    </Grid2>
  </MDBox>
);

PhysicalRestrictionsSection.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  selectedAdditionalInfo: PropTypes.instanceOf(Array).isRequired,
  handleAdditionalInfoChange: PropTypes.func.isRequired,
  restrictions: PropTypes.instanceOf(Array).isRequired,
  handleHealthRestrictionChange: PropTypes.func.isRequired,
  healthRestrictions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  physicalDifficultyLevelV: PropTypes.string.isRequired,
  radioOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  helperTextStyle: PropTypes.instanceOf(Object),
};

export default PhysicalRestrictionsSection;
