import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid2, Typography } from "@mui/material";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import PinPicker from "components/AsyncSelect/PinPicker";
import VenuePicker from "components/AsyncSelect/VenuePicker";
import { venuesOptions } from "../constants";

const StopsPart = ({
  containStopsV = false,
  setFieldValue,
  isOneVenue = {},
  isOneVenueV = "yes",
  values,
  pin = {},
  handlePinChange = () => {},
  selectedPin = {},
  venuesV = null,
  venues = [],
  handleVenueChange = () => {},
  selectedVenue = {},
}) =>
  (containStopsV || values?.itinerary?.itineraryItems?.length > 0) && (
    <>
      <Typography variant="h6" sx={{ marginBottom: "5px", marginRight: "0.5rem" }}>
        Does your ticket offer admission to more than one venue? *
      </Typography>
      <DynamicRadioGroup
        name={isOneVenue.name}
        value={isOneVenueV || (values?.itinerary?.itineraryItems?.length > 1 ? "yes" : "no")}
        options={venuesOptions}
        setFieldValue={setFieldValue}
      />
      <Typography variant="h6" sx={{ marginBottom: "5px", marginRight: "0.5rem" }}>
        What venue does your ticket offer admission to? *
      </Typography>
      <Typography sx={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {pin.label}
      </Typography>
      <Grid2 item size={{ xs: 12 }}>
        <PinPicker
          isClearable
          handleChange={handlePinChange}
          setFieldValue={setFieldValue}
          selectedPin={selectedPin || {}}
        />
      </Grid2>
      <Grid2 item size={{ xs: 12 }}>
        <VenuePicker
          type={venuesV || values?.venues}
          isClearable
          venues={venues}
          handleChange={handleVenueChange}
          setFieldValue={setFieldValue}
          selectedVenue={selectedVenue || {}}
        />
      </Grid2>
    </>
  );

StopsPart.propTypes = {
  containStopsV: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  isOneVenue: PropTypes.instanceOf(Object),
  isOneVenueV: PropTypes.string,
  pin: PropTypes.instanceOf(Object),
  handlePinChange: PropTypes.func,
  selectedPin: PropTypes.instanceOf(Object),
  venuesV: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  venues: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  handleVenueChange: PropTypes.func,
  selectedVenue: PropTypes.instanceOf(Object),
};

export default memo(StopsPart);
