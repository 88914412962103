import React from "react";
import { Body, Container, Html, Text } from "@react-email/components";
import PropTypes from "prop-types";

import FooterSection from "../../FooterSection";
import LogoSection from "../../LogoSection/index";
import ReservationCard from "../Components/ReservationCard";

const ClientAutoConfirmation = ({ data = {} }) => {
  /* ****** Constants ****** */
  const { recommendation = { title: "Lokalee" } } = data;

  return (
    <Html>
      <Body
        style={{
          display: "flex",
          color: "#222D29",
          alignItems: "center",
          fontFamily: "Helvetica",
          justifyContent: "center",
          backgroundColor: "#f7fafc",
        }}
      >
        <Container
          style={{
            maxWidth: "600px",
          }}
        >
          <LogoSection />

          <Text
            style={{
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "25px",
              padding: "0px 32px",
            }}
          >
            Hello {recommendation.title},
          </Text>

          <Text
            style={{
              fontSize: "24px",
              fontWeight: "400",
              lineHeight: "26.5px",
              padding: "30px 32px",
            }}
          >
            A guest has just made a reservation at your restaurant
          </Text>

          <div style={{ padding: "0px 24px", marginBottom: "34px" }}>
            <ReservationCard data={data} />
          </div>

          <FooterSection hasSocialLinks={false} />
        </Container>
      </Body>
    </Html>
  );
};

ClientAutoConfirmation.propTypes = {
  data: PropTypes.shape({
    guests: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    time: PropTypes.string,
    date: PropTypes.string,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};

export default ClientAutoConfirmation;
