/* eslint-disable no-alert */

// Libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import { DropzoneArea } from "react-mui-dropzone";

// Mui components
import Alert from "@mui/material/Alert";

const MultiImage = ({ setFieldValue, images = [], filesLimitNb = 6, mobileImage = false }) => {
  const [imgObj, setImgObj] = useState({ img: "", err: false });

  const handleChange = (e) => {
    let image;
    if (e.length > 0) {
      image = URL.createObjectURL(e[e.length - 1]);
      const img = new Image();
      img.src = image;
      img.onload = () => {
        if (img.width > 800 && img.height > 600) {
          setFieldValue(mobileImage ? "mobileImages" : "images", e);
          // setImgObj({ img, err: false });
        } else {
          setFieldValue(mobileImage ? "mobileImages" : "images", e);
          // alert(`Sorry, this image doesn't look like the size we wanted. It's
          // ${img.width} x ${img.height} but we require minimum size of 800x600.`);
          setImgObj({ img, err: true });
          setTimeout(() => {
            setImgObj({ img, err: false });
          }, 3000);
        }
      };
    } else {
      setFieldValue(mobileImage ? "mobileImages" : "images", e);
    }
  };

  return (
    <div>
      <DropzoneArea
        onChange={handleChange}
        filesLimit={filesLimitNb}
        initialFiles={images?.slice(0, 6)}
        acceptedFiles={["image/jpeg", "image/jpg", "image/png", "image/bmp"]}
        maxFileSize={2000000}
        classes={{
          root: "dropzoneArea-root",
        }}
      />

      {imgObj?.err && (
        <Alert severity="error" sx={{ width: "100%" }}>
          {`Sorry, this image doesn't look like the size we wanted. It's
          // ${imgObj?.img.width} x ${imgObj?.img.height} but we require minimum size of 800x600.`}
        </Alert>
      )}
    </div>
  );
};

MultiImage.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  images: PropTypes.instanceOf(Array),
  filesLimitNb: PropTypes.number,
  mobileImage: PropTypes.bool,
};

export default MultiImage;
