/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import { Route, Routes, Navigate } from "react-router";

// react-router components
import AuthRoutes from "views/AuthRoutes";
import AdminRoutes from "views/AdminRoutes";
import PartnerRoutes from "views/PartnerRoutes";
import ConciergeRoutes from "./ConciergeRoute";

const Router = () => (
  <Routes>
    {/* <Route path="/authentication/*" element={<AuthRoutes />} /> */}
    <Route path="/authentication">
      <Route path="*" element={<AuthRoutes />} />
    </Route>

    {/* <Route path="/admin/*" element={<AdminRoutes />} /> */}
    <Route path="/admin">
      <Route path="*" element={<AdminRoutes />} />
    </Route>

    {/* <Route path="/partner/*" element={<PartnerRoutes />} /> */}
    <Route path="/partner">
      <Route path="*" element={<PartnerRoutes />} />
    </Route>

    {/* <Route path="/concierge/*" element={<ConciergeRoutes />} /> */}
    <Route path="/concierge">
      <Route path="*" element={<ConciergeRoutes />} />
    </Route>

    {/* <Route
        path="/hero/*"
        element={
          <HeroRoutes />
        }
      /> */}

    <Route path="*" element={<Navigate to="/authentication/login" />} />
  </Routes>
);

export default Router;
