/* eslint-disable react/no-array-index-key */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Avatar, Divider } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
// import { areAllValuesEmpty } from "components/helper";
import { useSelector } from "react-redux";

const Review = ({ formData, editPage = false }) => {
  const { values } = formData;

  const [attractionInfoData, setAttractionInfoData] = useState({});
  const [additionalInfoData, setAdditionalInfoData] = useState({});
  const [fields, setFields] = useState([]);
  const travelDesks = useSelector((state) => state.travelDesks.travelDesks);
  const selectedTravelDesks = travelDesks.filter(
    (travelDesk) => travelDesk.id === values.travelDesk
  );
  const selectedTravelDeskNames = selectedTravelDesks.map((travelDesk) => travelDesk.name);
  useEffect(() => {
    if (values) {
      setAttractionInfoData({
        title: values?.title,
        subHeader: values?.subHeader,
        travelDesk: selectedTravelDeskNames,
        destination: values?.destination?.label || values?.destination,
      });
      setAdditionalInfoData({
        tag: values?.tag?.label || values?.tag,
        retail_price: values?.retail_price,
        label: values?.callToAction?.label,
        url: values?.type === "url" && values?.callToAction?.url,
        description: values?.type === "msg" && values?.description,
        sponsored: values?.sponsored,
        currated: values?.currated,
        special: values?.special,
        experienceX: values?.experienceX,
        exclusive: values?.exclusive,
        insider: values?.insider,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([
      { id: 0, title: "Attraction info", data: attractionInfoData },
      { id: 1, title: "Additional info", data: additionalInfoData },
    ]);
  }, [attractionInfoData, additionalInfoData]);

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {!editPage ? (
            <Grid item size={{ xs: 12, sm: 6 }}>
              {values?.logo ? (
                <MDAvatar
                  src={URL.createObjectURL(values?.logo)}
                  alt="profile-image"
                  size="xl"
                  sx={{ "& img": { height: "100%" } }}
                />
              ) : (
                <BigAvatar
                  alt="Change your profile photo"
                  src={URL.revokeObjectURL(values?.logo)}
                  imgProps={{
                    style: {
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              )}
            </Grid>
          ) : (
            ""
          )}
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              size={{ xs: 12 }}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DetailList data={field.data} title={field?.title} />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}{" "}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
};

export default Review;
