/* eslint-disable no-underscore-dangle */
// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";

const DestinationDropdown = ({
  dataList,
  destination,
  destinationV = null,
  setFieldValue,
  ...rest
}) => {
  const uniqueDestinations = [...new Map(dataList.map((item) => [item._id, item])).values()];

  return (
    <Autocomplete
      id="roles"
      // defaultValue={destinationV}
      options={uniqueDestinations}
      onChange={(e, newValue) => {
        setFieldValue("destination", newValue || null);
      }}
      value={destinationV || null}
      getOptionLabel={(option) => option.destinationName || ""}
      isOptionEqualToValue={(option, value) => option._id === value?._id}
      renderInput={(params) => (
        <FormField
          {...params}
          {...rest}
          // InputLabelProps={{ shrink: true }}
          label={destination?.label}
          name={destination?.name}
          // onChange={() => null}
          onChange={params.onChange}
        />
      )}
    />
  );
};

// typechecking props for DestinationDropdown
DestinationDropdown.propTypes = {
  destination: PropTypes.instanceOf(Object).isRequired,
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      destinationName: PropTypes.string,
      slug: PropTypes.string,
    })
  ).isRequired,
  destinationV: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func.isRequired,
};

export default DestinationDropdown;
