/* eslint-disable import/no-extraneous-dependencies */
// Libraries
import PropTypes from "prop-types";
import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateRangePicker } from "react-date-range";

// @mui material components
import { TreeItem, TreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { subDays } from "date-fns";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import FormField from "components/FormField";

// eslint-disable-next-line no-unused-vars
const DynamicDateRange = ({
  selectedOption = [],
  selectedRanges = [],
  handleDateChange = () => {},
  isTodayIncluded = false,
  hideDaterange,
  hideDateRangeFn,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Grid item size={{ xs: 12 }}>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        onNodeSelect={() => {
          hideDateRangeFn(!hideDaterange);
        }}
        expanded={!hideDaterange ? ["1"] : []}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ paddingTop: "30px" }}
        style={{ onHover: isHovered ? "70px" : "20px" }}
      >
        <TreeItem
          nodeId="1"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          label="Date Range"
        >
          <Box size={{ xs: 5, md: 6 }}>
            <DateRangePicker
              inputRanges={[]}
              renderInput={(params) => <FormField {...params} {...rest} onChange={() => null} />}
              onChange={(date) => {
                handleDateChange(date);
              }}
              showSelectionPreview
              rangeColors={["#003005", "#3ecf8e", "#fed14c"]}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={selectedOption}
              staticRanges={selectedRanges?.length > 0 ? selectedRanges : undefined}
              maxDate={
                isTodayIncluded
                  ? new Date()
                  : subDays(
                      new Date(
                        Date.UTC(
                          new Date().getUTCFullYear(),
                          new Date().getUTCMonth(),
                          new Date().getUTCDate()
                        )
                      ),
                      1
                    )
              }
              direction="horizontal"
            />
          </Box>
        </TreeItem>
      </TreeView>
    </Grid>
  );
};

// typechecking props for DynamicDateRange
DynamicDateRange.propTypes = {
  selectedRanges: PropTypes.instanceOf(Array),
  hideDaterange: PropTypes.bool.isRequired,
  isTodayIncluded: PropTypes.bool,
  hideDateRangeFn: PropTypes.func.isRequired,
  selectedOption: PropTypes.instanceOf(Object),
  handleDateChange: PropTypes.func,
};

export default DynamicDateRange;
