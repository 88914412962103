import React from "react";
import PropTypes from "prop-types";
import { Body, Container, Section, Html, Text, Img } from "@react-email/components";

import { convertToAMPM } from "../../../../utils/function/utilFunctions";

import FooterSection from "../../FooterSection";
import LogoSection from "../../LogoSection/index";
import IfYouNeedSupportSection from "../../IfYouNeedSupport";

const UserConfirmation = ({ data = {} }) => {
  /* ***** Constants  ****** */
  const {
    guests = 2,
    time = "08:00",
    date = "2 Apr 2024",
    name = "Lokalee User",
    ref = {
      logo: "https://email-assets.lokalee.app/email/emailImages/fullWidth-cover.png",
      title: "Lake boat ride with Dubai Fountain show Standard price",
      geoLocationData: {
        address:
          "Restaurant Village Four Seasons Resort - Jumeirah Beach Rd - Jumeirah 2 - Dubai - United Arab Emirates",
      },
    },
  } = data;

  return (
    <Html>
      <Body
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "Helvetica",
          justifyContent: "center",
          backgroundColor: "#f7fafc",
        }}
      >
        <Container
          style={{
            maxWidth: "600px",
          }}
        >
          <LogoSection />

          <Section
            style={{
              gap: "20px",
              display: "flex",
              color: "#222D29",
              padding: "30px 0",
            }}
          >
            <Text
              numberOfLines={1}
              style={{
                fontSize: "25px",
                fontWeight: "700",
                lineHeight: "26.5px",
              }}
            >
              Dear {name},
            </Text>

            <Text
              numberOfLines={3}
              style={{
                fontSize: "24px",
                fontWeight: "400",
                lineHeight: "26.5px",
              }}
            >
              Thank you for your reservation at {ref.title}.
            </Text>
          </Section>

          <Section
            style={{
              marginBottom: "24px",
            }}
          >
            <div
              style={{
                padding: "4px 24px 24px",
                borderRadius: "11.54px",
                fontFamily: "Helvetica",
                backgroundColor: "#F2F2F2",
              }}
            >
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: "14.43px",
                }}
              >
                Here are the location details:
              </Text>

              <Img
                width="100%"
                height="280px"
                src={ref.logo}
                alt="Reservation"
                style={{
                  objectFit: "cover",
                  borderRadius: "11.54px",
                }}
              />

              <Text
                numberOfLines={2}
                style={{
                  fontSize: "22px",
                  fontWeight: "400",
                  lineHeight: "23.55px",
                }}
              >
                {ref.title}
              </Text>

              <table style={{ marginBottom: "24px" }}>
                <tbody>
                  <tr>
                    <td style={{ paddingRight: "4px" }}>
                      <Text
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          padding: "2px 6px",
                          borderRadius: "2px",
                          border: "1px solid #222D29",
                        }}
                      >
                        {date.replace(/\s*(\d{4})$/, ", $1")}
                      </Text>
                    </td>

                    <td style={{ paddingRight: "4px" }}>
                      <Text
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          padding: "2px 6px",
                          borderRadius: "2px",
                          border: "1px solid #222D29",
                        }}
                      >
                        {convertToAMPM(time)}
                      </Text>
                    </td>

                    <td style={{ paddingRight: "4px" }}>
                      <Text
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          padding: "2px 6px",
                          borderRadius: "2px",
                          border: "1px solid #222D29",
                        }}
                      >
                        {guests > 1 ? `${guests} guests` : `${guests} guest`}
                      </Text>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div style={{ border: "1px solid #BAB9B9", marginBottom: "24px" }} />

              <Text
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "21.46px",
                }}
              >
                Address
              </Text>

              <a
                href={`https://www.google.com/maps/search/?api=1&query=${ref.geoLocationData.address}`}
              >
                <Text
                  style={{
                    margin: "0",
                    color: "#222D29",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "19.32px",
                    textDecoration: "underline",
                  }}
                >
                  {ref.geoLocationData.address}
                </Text>
              </a>
            </div>
          </Section>

          <Text
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "23px",
              textAlign: "center",
              padding: "0px 24px",
            }}
          >
            We hope you have the best dining experience with us.
          </Text>

          <IfYouNeedSupportSection showTitle={false} />

          <FooterSection />
        </Container>
      </Body>
    </Html>
  );
};

UserConfirmation.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
  }),

  data: PropTypes.shape({
    time: PropTypes.string,
    date: PropTypes.string,
    guests: PropTypes.number,
    ref: PropTypes.shape({
      title: PropTypes.string,
      logo: PropTypes.string,
      geoLocationData: PropTypes.shape({
        address: PropTypes.string,
      }),
    }),
  }),
};

export default UserConfirmation;
