/* eslint-disable no-underscore-dangle */
import {
  SET_RECOMMENDATION_LOADING,
  RECOMMENDATION_ADD_SUCCESS,
  RECOMMENDATION_FAIL,
  GET_RECOMMENDATIONS,
  CLEAR_ERRORS,
  DELETE_RECOMMENDATION,
  GET_ONE_RECOMMENDATION,
  UPDATE_RECOMMENDATION,
  GET_ONE_PARTNER,
  GET_ONE_LOCATION,
  LOGOUT,
  UPDATE_RECOMMENDATION_TRANSLATION,
  DELETE_SINGLE_RECOMMENDATION,
  CHECK_RECOMMENDATIONS,
  GET_CUISINES,
  GET_FEATURES,
  CUISINES_FAIL,
  FEATURES_FAIL,
} from "../actions/Types";

const initialState = {
  loading: false,
  recommendations: [],
  error: null,
  added: false,
  recommendation: null,
  cuisines: [],
  recExp: [],
  features: [],
};

const variable = (state = initialState, action) => {
  switch (action.type) {
    case RECOMMENDATION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        recommendations: [action.payload.data, ...state.recommendations],
        added: true,
        recommendation: null,
      };
    case GET_ONE_RECOMMENDATION:
      return {
        ...state,
        loading: false,
        error: null,
        recommendation: action.payload.data,
        added: false,
      };
    case GET_ONE_PARTNER:
    case GET_ONE_LOCATION: // Clear recommendations when partner is selcted to avoid pulling previous partner recommendations
      return {
        ...state,
        recommendations: [],
      };
    case GET_RECOMMENDATIONS:
      return {
        ...state,
        loading: false,
        error: null,
        recommendations: action.payload.data,
        added: false,
        recommendation: null,
      };
    case DELETE_RECOMMENDATION:
      return {
        ...state,
        loading: false,
        recommendations: state.recExp.filter(
          (recommendation) => recommendation._id !== action.payload.data.recommendation._id
        ),
      };
    case DELETE_SINGLE_RECOMMENDATION:
      return {
        ...state,
        loading: false,
        recommendation: null,
      };
    case CHECK_RECOMMENDATIONS:
      return {
        ...state,
        loading: false,
        error: null,
        recExp: action.payload.data,
        added: false,
        recommendations: null,
      };
    case UPDATE_RECOMMENDATION:
      return {
        ...state,
        recommendation: action.payload.data.recommendation,
        recommendations: state.recommendations.map((recommendation) =>
          recommendation._id === action.payload.data.recommendation._id
            ? action.payload.data.recommendation
            : recommendation
        ),
        loading: false,
        added: true,
      };
    case UPDATE_RECOMMENDATION_TRANSLATION:
      return {
        ...state,
        recommendation: action.payload.data?.recommendation,
      };

    case RECOMMENDATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FEATURES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CUISINES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_RECOMMENDATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_CUISINES:
      return {
        ...state,
        loading: false,
        error: null,
        cuisines: action.payload.cuisines,
        added: false,
      };
    case GET_FEATURES:
      return {
        ...state,
        loading: false,
        error: null,
        features: action.payload.features,
        added: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        recommendations: [],
        error: null,
        added: false,
        recommendation: null,
      };

    default:
      return state;
  }
};
export default variable;
