/*eslint-disable*/
import React, { useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";

import MaterialTablePicker from "components/MaterialTablePicker";

import Grid from "@mui/material/Grid2";

import {
  setLoading,
  clearErrors,
  deleteWidget,
  updateWidget,
  setWidgetEmpty,
} from "store/actions/widgetActions";
import setAlert from "store/actions/alertActions";
import { getPdf, getCsv } from "store/actions/exportAction";
import columnsMap from "components/MaterialTablePicker/TableColumns";

const WidgetsList = () => {
  const tableRef = createRef();

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.widgets.loading);
  const error = useSelector((state) => state.widgets.error);
  const widgets = useSelector((state) => state.widgets.widgets);
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  useEffect(() => {
    dispatch(setWidgetEmpty());
  }, []);

  const deleteWidgetFn = (id) => {
    dispatch(deleteWidget(id));
  };

  const updateWidgetFn = (data) => {
    dispatch(updateWidget(data));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item size={{ xs: 12 }}>
          <MDBox>
            <MaterialTablePicker
              screen="Transportation Services List"
              title="Transportation Services"
              columns={[
                columnsMap.titleCol,
                columnsMap.locationNameWithFilter,
                columnsMap.creationDateAsync,
              ]}
              tableRef={tableRef}
              asyncTable
              sortField="title"
              searchField="widgets"
              exportMenuField="widgets"
              idSynonymName="_id"
              onRowClickURL={`/${user.role}/widgets`}
              currentUrl="widgets"
              asyncURL={`${process.env.REACT_APP_API}/widgets?`}
              addUrl="widgets/new-widget"
              actionsName="TransportationService"
              editableUpdate={updateWidgetFn}
              editableDelete={deleteWidgetFn}
              updateRoleAction
              deleteRoleAction
              isLoading={loading}
              editableRole={user.role === "admin"}
              actionsRole={user.role === "admin"}
              getPdf={(value, query) => {
                if ((value, query)) {
                  dispatch(
                    getPdf(
                      value,
                      `stats/pdf${query}&fields=title,location,createdAt`,
                      "Transportation Services"
                    )
                  );
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for PDF export.");
                }
              }}
              getCsv={(query) => {
                if (user?.role === "admin") {
                  dispatch(
                    getCsv(
                      `stats/csv${query}&fields=title,location,createdAt`,
                      "Transportation Services"
                    )
                  );
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for CSV export.");
                }
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};
export default WidgetsList;
