/* eslint-disable no-underscore-dangle */
import {
  SET_PROMOCODE_LOADING,
  PROMOCODE_ADD_SUCCESS,
  PROMOCODE_FAIL,
  GET_ALL_PROMOCODES,
  CLEAR_ERRORS,
  DELETE_PROMOCODE,
  GET_ONE_PROMOCODE,
  UPDATE_PROMOCODE,
  LOGOUT,
  SET_PROMOCODE_EMPTY,
} from "../actions/Types";

const initialState = {
  loading: false,
  promoCodes: [],
  error: null,
  added: false,
  promoCode: null,
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case PROMOCODE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        promoCodes: [...state.promoCodes, action.payload.data.data],
        added: true,
        promoCode: null,
      };
    case GET_ONE_PROMOCODE:
      return {
        ...state,
        loading: false,
        error: null,
        promoCode: action.payload.data,
        added: false,
      };

    case GET_ALL_PROMOCODES:
      return {
        ...state,
        loading: false,
        error: null,
        promoCodes: action.payload.data.data,
        added: false,
        promoCode: null,
      };
    case SET_PROMOCODE_EMPTY:
      return {
        ...state,
        loading: false,
        error: null,
        promoCode: {},
      };
    case DELETE_PROMOCODE:
      return {
        ...state,
        loading: false,
        promoCodes: state.promoCodes.filter(
          (promoCode) => promoCode._id !== action.payload.data.data._id
        ),
      };
    case UPDATE_PROMOCODE:
      return {
        ...state,
        added: true,
        promoCode: action.payload.data,
        loading: false,
        promoCodes: state.promoCodes.map((promoCode) =>
          promoCode._id === action.payload.data._id ? action.payload.data : promoCode
        ),
      };
    case PROMOCODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_PROMOCODE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        promoCodes: [],
        error: null,
        added: false,
        promoCode: null,
      };

    default:
      return state;
  }
};
export default variable;
