/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
import {
  GET_USER_INFO,
  USER_ERROR,
  LOGOUT,
  CLEAR_ERRORS,
  SET_USER_LOADING,
  USER_LOADED,
  EMAIL_VERIFIED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_VERIFICATION_REQUIRED,
  SET_OPEN_VERIFICATION_MODAL,
  RESET_EMAIL_SENT,
  RESET_CODE_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  RESET_CODE_DISABLED,
  UPDATE_USER,
  UPDATED_PROFILE,
  CLEAR_USER_LOADING,
  CLEAR_DATA,
  LINK_SENT,
  RESET_PASS_SUCCESSS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_ONE_USER,
  EDIT_ERROR,
  UPDATE_SPECIFIC_USER,
  GET_UPDATED_FILTER,
  CLEAR_FILTER,
  DEACTIVATE,
  ACTIVATE,
  SET_USERS_LOADING,
  SET_USER_LOAD,
  GET_USERS,
  PDF_FAIL,
  PDF_SUCCESS,
  CSV_FAIL,
  CSV_SUCCESS,
  CLEAR_ONE_USER,
  TOKEN_CONFIRMED,
  CHECK_EMAIL,
  CHECK_EMAIL_FAIL,
  GET_NO_FILTER,
} from "../actions/Types";

const initialState = {
  loading: true,
  loadUser: false,
  error: null,
  user: null,
  token: null,
  isAuthenticated: null,
  openVerificationModal: false,
  mailResend: 0,
  resetCodeSuccess: false,
  profileUpdated: false,
  linkSent: false,
  resetPassSuccess: false,
  refresh: true,
  registered: false,
  changed: false,
  updateLoading: false,
  loadingUsers: false,
  specificUser: null,
  route: null,
  filters: [],
  pdf: [],
  checkEmail: [],
  errorEmail: null,
  isLoggedIn: false,
  onlineStoreFilters: [],
};

// the prefix is used to reuse types
const PREFIX = "USER_";

export default (state = initialState, action) => {
  function upsert(array, item) {
    // (1)
    // make a copy of the existing array
    const newArray = array.slice();

    const i = newArray.findIndex((_item) => _item._id === item._id);
    if (i > -1) {
      newArray[i] = item;
      return newArray;
    }
    // (2)

    newArray.unshift(item);
    return newArray;
  }

  switch (action.type) {
    case GET_USER_INFO:
      return {
        ...state,
        loading: false,
        error: null,
        info: action.payload.data,
        failed: false,
      };

    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.data.user,
        loadingUsers: false,
        isAuthenticated: true,
        loading: false,
        loadUser: false,
        changed: true,
        updateLoading: false, // Check user.validated
      };
    case UPDATE_SPECIFIC_USER:
      return {
        ...state,
        specificUser: action.payload.data?.data,
        loadingUsers: false,
        isAuthenticated: true,
        loading: false,
        loadUser: false,
        changed: true,
        updateLoading: false,
      };
    case RESET_EMAIL_SENT: // Completed step 1 of passwprd reset
      return {
        ...state,
        loading: false,
        loadUser: false,
        error: null,
        user: state.isAuthenticated
          ? { ...action.payload.data.user, ...state.user }
          : action.payload.data.user,
      };
    case EMAIL_VERIFIED: // Final step of signup. will get user info and token
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        loadUser: false,
        error: null,
        isAuthenticated: true,
        user: action.payload.data.user,
        token: action.payload.token,
      };

    case LINK_SENT:
      return {
        ...state,
        linkSent: true,
        loading: false,
        loadUser: false,
        error: action.payload.message,
      };

    case RESET_CODE_DISABLED:
      return {
        ...state,
        resetCodeSuccess: false,
      };
    case RESET_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        loadUser: false,
        resetCodeSuccess: true,
      };

    case UPDATED_PROFILE:
      return {
        ...state,
        profileUpdated: action.payload,
      };
    case AUTH_ERROR:
      return {
        ...state,
        authError: true,
        loading: false,
        loadUser: false,
        refresh: false,
      };
    case TOKEN_CONFIRMED:
      return {
        ...state,
        email_token: true,
        user: action.payload.data.user,
        loadingUsers: false,
        loadUser: false,
        loading: false,
      };
    case LOGIN_SUCCESS: // Will Login Unconfirmed email
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoggedIn: true,
        user: action.payload.data.user,
        token: action.payload.token,
        loading: false,
        loadUser: false,
        openVerificationModal: false,
      };
    case SET_OPEN_VERIFICATION_MODAL:
      return {
        ...state,
        openVerificationModal: action.payload,
      };
    case RESET_PASS_SUCCESSS: // checks if email verified and handler set (step 2 and 3)
      return {
        ...state,
        user: action.payload.data.user,
        isAuthenticated: true,
        resetPassSuccess: true,
        loading: false,
        loadUser: false,
      };
    case USER_ERROR:
      return {
        ...state,
        loading: false,
        loadUser: false,
        error: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        loadUser: false,
        error: action.payload,
      };
    case LOGIN_VERIFICATION_REQUIRED:
      return {
        ...state,
        loading: false,
        loadUser: false,
        openVerificationModal: true,
        user: { email: action.payload },
      };

    case SET_USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_USER_LOAD:
      return {
        ...state,
        loadUser: true,
      };
    case SET_USERS_LOADING:
      return {
        ...state,
        loadingUsers: true,
      };
    case CLEAR_USER_LOADING:
      return {
        ...state,
        loading: false,
        loadUser: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        loadUser: false,
        errorEmail: null,
        page: 1,
        linkSent: false,
        resetPassSuccess: false,
        route: null,
        refresh: false,
      };

    case GET_USERS:
      return {
        ...state,
        users: action.payload.data,
        loading: false,
        loadUser: false,
        loadingUsers: false,
        registered: false,
        specificUser: null,
      };
    case USER_LOADED:
      return {
        ...state,
        user: action.payload.data.data,
        isAuthenticated: true,
        error: null,
        loading: false,
        refresh: false,
      };

    case PREFIX + CLEAR_DATA: {
      // to clear a specific variable form the state, we pass it in an array (created to avoid multiple customized cases)
      const variables = action.payload;
      const tempState = state;
      for (let i = 0; variables && i < variables.length; i++) {
        tempState[variables[i]] = initialState[variables[i]];
      }

      return variables?.length > 0 ? tempState : initialState;
    }

    case CLEAR_ONE_USER:
      return {
        ...state,
        loading: false,
        specificUser: null,
        registered: false,
        error: null,
      };
    case LOGOUT:
      return {
        ...state,
        loading: false,
        loadUser: false,
        user: null,
        error: null,
        // info: null,
        token: null,
        isAuthenticated: null,
        resetCodeSuccess: false,
        linkSent: false,
        resetPassSuccess: false,
        refresh: true,
        registered: false,
        loadingUsers: false,
        isLoggedIn: false,
      };

    case REGISTER_SUCCESS: // Completed step 1 of registration
      return {
        ...state,
        loading: false,
        loadUser: false,
        error: null,
        registered: true,
        loadingUsers: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        loadingUsers: false,
        error: action.payload,
        registered: false,
      };
    case GET_ONE_USER:
      return {
        ...state,
        loading: false,
        loadUser: false,
        error: null,
        registered: true,
        specificUser: action.payload.data.data,
      };
    case GET_UPDATED_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case GET_NO_FILTER:
      return {
        ...state,
        onlineStoreFilters: action.payload,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filters: [],
        specificUser: null,
      };
    case DEACTIVATE:
      return {
        ...state,
        users: upsert(state.users, action.payload.data),
        loadingUsers: false,
      };
    case ACTIVATE:
      return {
        ...state,
        users: upsert(state.users, action.payload.data),
        loadingUsers: false,
      };

    case EDIT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case PDF_FAIL:
      return {
        ...state,
        loading: false,
        loadUser: false,
        error: action.payload,
      };
    case PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        loadUser: false,
        error: null,
        pdf: action.payload,
      };
    case CSV_FAIL:
      return {
        ...state,
        loading: false,
        loadUser: false,
        loadingUsers: false,
        error: action.payload,
      };
    case CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        loadUser: false,
        loadingUsers: false,
        error: null,
        csv: action.payload,
      };
    case CHECK_EMAIL:
      return {
        ...state,
        checkEmail: action.payload.users,
      };

    case CHECK_EMAIL_FAIL:
      return {
        ...state,
        errorEmail: action.payload,
      };
    default:
      return state;
  }
};
