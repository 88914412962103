/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDButton from "components/MDButton";
import { CardHeader, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MaterialTablePicker from "components/MaterialTablePicker";
import { Formik, Form } from "formik";
import MDBox from "components/MDBox";
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Loader from "components/Loader";
import {
  getCountryPartnerTrafficReports,
  getDroplistTrafficReports,
  clearHeaderAndReports,
  setLoading,
} from "store/actions/reportActions";
import DynamicDateRange from "components/DynamicDateRange";
import {
  addYears,
  endOfYear,
  isSameDay,
  startOfDay,
  subDays,
  startOfYear,
  subYears,
  endOfDay,
} from "date-fns";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import DynamicTabs from "components/DynamicTabs";
import Spinner from "components/Spinner";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import CityReportPicker from "components/AutoComplete/ReportsPickers/CityReportPicker";
import CountryReportPicker from "components/AutoComplete/ReportsPickers/CountryReportPicker";
import PartnerReportPicker from "components/AutoComplete/ReportsPickers/PartnerReportPicker";
import LocationReportPicker from "components/AutoComplete/ReportsPickers/LocationReportPicker";
import Danger from "components/Danger";
import columnsMap from "components/MaterialTablePicker/TableColumns";
import { defaultStaticRanges } from "react-date-range";
import PdfTrafficReportByPartner from "./PdfTrafficReportByPartner";
import CsvTrafficReportByPartner from "./CsvTrafficReportByPartner";
import initialValues from "./TrafficByPartnerList/schemas/initialValues";
import validations from "./TrafficByPartnerList/schemas/validations";

const TrafficByPartner = () => {
  // const { formField, values, errors, touched, setFieldValue } = formData;
  // const { partner } = formField;
  // const { partner: partnerV, locations: locationsV } = values;
  const tableRef = createRef();
  const formRef = useRef();
  const dispatch = useDispatch();

  const cities = useSelector((state) => state.reports.droplistTrafficReportCity);
  const locations = useSelector((state) => state.reports.droplistTrafficReportLocation);
  const reports = useSelector((state) => state.reports.trafficReportsByCountry);
  const added = useSelector((state) => state.reports.added);
  const countries = useSelector((state) => state.reports.droplistTrafficReportCountry);
  const partners = useSelector((state) => state.reports.droplistTrafficReportPartner);
  const loading = useSelector((state) => state.reports.loading);
  const handleValidation = () => validations[0];
  const currentValidation = handleValidation();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [trafficCountryRes, setTrafficCountryRes] = useState({});
  const [selectedCity, setSelectedCity] = useState(null);
  const [hideDaterange, setHideDaterange] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [arr, setArr] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [initialArr, setInitialArr] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [generate, setGenerate] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        7
      ),
      endDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        1
      ),
      key: "selection",
    },
  ]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async (val, actions) => {
    await sleep(1000);

    dispatch(setLoading());
    dispatch(
      getCountryPartnerTrafficReports({
        dateRange,
        selectedLocation,
        selectedCity,
        selectedCountry,
        selectedPartner,
      })
    );
    // actions.setSubmitting(false);
    // actions.resetForm();
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);

    setAnchorEl(null);
  };

  const filteredData = arr.reduce((acc, curr) => {
    const existingCity = acc.find((item) => item.countryName === curr.countryName);
    if (existingCity) {
      existingCity.countryResults.push(...curr.countryResults);
    } else {
      const newCountry = JSON.parse(JSON.stringify(curr));
      acc.push(newCountry);
    }
    return acc;
  }, []);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    setTrafficCountryRes(filteredData[newValue]);
  };

  const handleFileDownloadIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedOption(null);
  };

  const handleSubmit = (val, actions) => {
    if (!selectedPartner) {
      return;
    }
    submitForm(val, actions);
  };

  const handleReset = (val, actions) => {
    setArr([]);
    setTrafficCountryRes({});
    setSelectedCity(null);
    setSelectedCountry(null);
    setSelectedLocation(null);
    setSelectedPartner(null);
    setDateRange([
      {
        startDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          7
        ),
        endDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          1
        ),
        key: "selection",
      },
    ]);
  };

  const handlePartnerChange = (value) => {
    setGenerate(false);
    if (!value) {
      setSelectedPartner(null);
      setSelectedCountry(null);
      setSelectedCity(null);
      setSelectedLocation(null);
    } else {
      setSelectedPartner(value);
    }
  };

  const handleCountryChange = (value) => {
    setGenerate(false);
    if (!value) {
      setSelectedCountry(null);
    } else {
      setSelectedCountry(value);
    }
  };

  const handleCityChange = (value) => {
    setGenerate(false);
    if (!value) {
      setSelectedCity(null);
    } else {
      setSelectedCity(value);
    }
  };

  const handleLocationChange = (value) => {
    setGenerate(false);
    if (!value) {
      setSelectedLocation(null);
    } else {
      setSelectedLocation(value);
    }
  };

  const trafficReportArrayFn = () => {
    const arr2 = [];
    reports[0].partnerResults.forEach((country) => {
      arr2.push(country);
    });

    setArr(arr2);
  };

  const handleDateChange = (date, event) => {
    if (date?.selection?.startDate && isSameDay(date.selection.startDate, date.selection.endDate)) {
      const newStartDate = date.selection.startDate;
      const newEndDate = dateRange[0].endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    } else {
      const newStartDate = date?.range1?.startDate
        ? date?.range1.startDate
        : date?.selection?.startDate;
      const newEndDate = date?.range1?.endDate ? date?.range1.endDate : date?.selection?.endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    }
  };

  const handleDownload = (option) => {
    if (option === "pdf") {
      return <PdfTrafficReportByPartner arr={filteredData} />;
    }
    if (option === "csv") {
      return <CsvTrafficReportByPartner arr={filteredData} />;
    }
    return null;
  };

  const filteredStaticRanges = defaultStaticRanges.filter(
    (staticRange) => staticRange.label !== "Today"
  );

  const selectedRanges = [
    ...filteredStaticRanges,
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          7
        ),
        endDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          1
        ),
      }),
      isSelected(range) {
        return (
          isSameDay(range.startDate, range.endDate) &&
          isSameDay(range.startDate, startOfDay(subDays(new Date(), 6))) &&
          isSameDay(range.endDate, endOfDay(new Date()))
        );
      },
    },
    {
      label: "This Year",
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          1
        ),
      }),
      isSelected(range) {
        return (
          isSameDay(range.startDate, range.endDate) &&
          isSameDay(range.startDate, startOfYear(new Date())) &&
          isSameDay(range.endDate, endOfDay(new Date()))
        );
      },
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: startOfYear(addYears(new Date(), -1)),
        endDate: endOfYear(addYears(new Date(), -1)),
      }),
      isSelected(range) {
        return (
          isSameDay(range.startDate, range.endDate) &&
          isSameDay(range.startDate, startOfYear(subYears(new Date(), 1))) &&
          isSameDay(range.endDate, endOfYear(subYears(new Date(), 1)))
        );
      },
    },
  ];

  const countryNames = filteredData.map((country) => country.countryName);

  useEffect(() => {
    clearHeaderAndReports();
    setTrafficCountryRes({});
    setArr([]);
  }, []);

  useEffect(() => {
    dispatch(setLoading());
    dispatch(
      getDroplistTrafficReports(selectedCountry, selectedCity, selectedLocation, selectedPartner)
    );
    setTrafficCountryRes({});
    setArr([]);
    // eslint-disable-next-line
  }, [selectedCountry, selectedCity, selectedLocation, selectedPartner]);

  useEffect(() => {
    if (reports?.length > 0) trafficReportArrayFn();
  }, [reports]);

  useEffect(() => {
    if (filteredData && generate) setTrafficCountryRes(filteredData[0]);
  }, [filteredData.length, added, generate]);

  useEffect(() => {
    if (partners?.length > 0) {
      setInitialArr(partners);
      setIsInitialLoad(false);
    }
  }, [partners, isInitialLoad]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid item size={{ xs: 12, lg: 8 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidation}
          onSubmit={handleSubmit}
          innerRef={(f) => {
            formRef.current = f;
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form autoComplete="off">
              <Grid container spacing={3}>
                <Grid item size={{ xs: 6 }}>
                  {" "}
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Partner *
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <PartnerReportPicker
                      partners={initialArr}
                      setFieldValue={setFieldValue}
                      partner={selectedPartner}
                      handleChange={handlePartnerChange}
                    />
                  </MDBox>
                  {errors?.partner && touched?.partner && <Danger validation={errors?.partner} />}
                </Grid>
                <Grid item size={{ xs: 6 }}>
                  {" "}
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Country
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <CountryReportPicker
                      countries={countries}
                      setFieldValue={setFieldValue}
                      country={selectedCountry}
                      handleChange={handleCountryChange}
                      trafficByCountryPage={false}
                    />
                  </MDBox>
                </Grid>
                <Grid item size={{ xs: 6 }}>
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    City
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <CityReportPicker
                      cities={cities}
                      setFieldValue={setFieldValue}
                      city={selectedCity}
                      handleChange={handleCityChange}
                      setValCity={() => {}}
                    />
                  </MDBox>
                </Grid>
                <Grid item size={{ xs: 6 }}>
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Location
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <LocationReportPicker
                      locations={locations}
                      setFieldValue={setFieldValue}
                      location={selectedLocation}
                      handleChange={handleLocationChange}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {" "}
                <Grid item size={{ xs: 6 }}>
                  <DynamicDateRange
                    defaultValue={dateRange}
                    selectedOption={dateRange}
                    selectedRanges={selectedRanges}
                    handleDateChange={handleDateChange}
                    hideDaterange={hideDaterange}
                    hideDateRangeFn={setHideDaterange}
                  />
                  <MDBox style={{ fontSize: "small" }}>
                    Selected Date Range: {dateRange[0]?.startDate.toDateString()} to{" "}
                    {dateRange[0].endDate.toDateString()}
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "right",
                  // marginRight: "45px",
                  marginTop: "20px",
                }}
              >
                {" "}
                <div style={{ marginTop: "20px" }}>
                  <MDButton
                    onClick={(val, actions) => {
                      if (Object.keys(errors).length === 0) {
                        setHideDaterange(true);
                        setGenerate(true);
                      }
                    }}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    Generate Report
                  </MDButton>
                </div>
                <div style={{ textAlign: "right", marginTop: "20px", marginLeft: "8px" }}>
                  <MDButton
                    onClick={(val, actions) => {
                      handleReset(val, actions);
                      setFieldValue("city", null);
                      setFieldValue("location", null);
                      setFieldValue("country", null);
                      setFieldValue("partner", "");
                      setFieldValue("dateRange", {});
                    }}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    Reset Filters
                  </MDButton>
                </div>
              </MDBox>
              {selectedPartner && arr.length > 0 && trafficCountryRes?.countryResults && (
                <div
                  style={{
                    width: "100%",
                    height: "21px",
                    marginTop: "10px",
                    backgroundColor: "#d3d3d3",
                  }}
                />
              )}

              {filteredData.length > 0 &&
                // !hideDiv &&
                selectedPartner !== "" &&
                trafficCountryRes?.countryResults && (
                  <MDBox>
                    <CardHeader style={{ textAlign: "left", marginBottom: "-20px" }}>
                      <h3
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <b style={{ color: "grey" }}> Partner name:</b>{" "}
                        {filteredData[0].countryResults[0].cityResults[0].partner}{" "}
                      </h3>
                      <h6
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        Traffic by country report
                      </h6>
                    </CardHeader>
                  </MDBox>
                )}

              {filteredData?.length > 0 && selectedPartner && (
                <MDBox sx={{ mt: 5, mb: 5 }}>
                  {trafficCountryRes?.countryResults && (
                    <DynamicTabs
                      detailTabs={countryNames}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                    />
                  )}
                </MDBox>
              )}
              {filteredData.length > 0 &&
                // !hideDiv &&
                selectedPartner !== "" &&
                trafficCountryRes?.countryResults && (
                  <MDBox>
                    <h3
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <b style={{ color: "grey" }}> Partner name:</b>{" "}
                      {filteredData[0].countryResults[0].cityResults[0].partner}{" "}
                    </h3>
                    <h6
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: "14px",
                        textTransform: "none",
                        fontWeight: "lighter",
                      }}
                    >
                      Traffic by partner report
                    </h6>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          float: "right",
                          textAlign: "center",
                          marginRight: "75px",
                        }}
                      >
                        <div>
                          <FileDownloadIcon
                            style={{ cursor: "pointer", width: "30px", height: "30px" }}
                            onClick={handleFileDownloadIconClick}
                          >
                            <MoreVertIcon />
                          </FileDownloadIcon>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                          >
                            <MenuItem
                              onClick={() => {
                                handleOptionClick("pdf");
                              }}
                            >
                              Download as PDF
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleOptionClick("csv");
                              }}
                            >
                              Download as CSV
                            </MenuItem>
                          </Menu>
                        </div>
                      </span>
                    </div>
                  </MDBox>
                )}

              <Grid item size={{ xs: 12 }} sx={{ mt: 3 }}>
                {trafficCountryRes &&
                  selectedPartner &&
                  trafficCountryRes?.countryResults?.map((val, i) => (
                    <MDBox key={val?.cityName}>
                      <span
                        style={{
                          marginRight: "30px",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                      >
                        City: <b style={{ color: "grey" }}>{val.cityName}</b>{" "}
                      </span>
                      <MaterialTablePicker
                        screen="traffic by partner"
                        title="Traffic by partner"
                        searchField="traffic by partner"
                        exportMenuField="trafficByPartner"
                        currentUrl="locations"
                        tableRef={tableRef}
                        columns={[
                          columnsMap.location,
                          columnsMap.screenPageViews,
                          columnsMap.trafficTotalUsers,
                          columnsMap.newUsers,
                          columnsMap.signups,
                          columnsMap.logins,
                          columnsMap.purchaseRevenue,
                          columnsMap.totalTickets,
                          columnsMap.averagePurchaseRevenuePerUser,
                        ]}
                        data={val?.cityResults}
                        isLoading={loading}
                        filteringOptions={false}
                        isSearchable={false}
                        isExportable={false}
                        actionsName="Traffic by partner"
                      />
                    </MDBox>
                  ))}
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>

      <Footer />
      <Spinner loading={loading} />
      {selectedOption && handleDownload(selectedOption)}
    </DashboardLayout>
  );
};

export default TrafficByPartner;
