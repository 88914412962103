/* eslint-disable no-underscore-dangle */
import {
  SET_PARTNER_LOADING,
  PARTNER_ADD_SUCCESS,
  PARTNER_FAIL,
  GET_PARTNERS,
  CLEAR_ERRORS,
  DELETE_PARTNER,
  GET_ONE_PARTNER,
  UPDATE_PARTNER,
  LOGOUT,
  CHECK_PARTNERS,
  CHECK_PARTNERS_FAIL,
} from "../actions/Types";

const initialState = {
  loading: false,
  partners: [],
  error: null,
  added: false,
  partner: null,
  checkPartners: [],
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case PARTNER_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        partners: [...state.partners, action.payload.data],
        added: true,
        partner: null,
      };
    case GET_ONE_PARTNER:
      return {
        ...state,
        loading: false,
        error: null,
        partner: action.payload.data,
        added: false,
      };

    case GET_PARTNERS:
      return {
        ...state,
        loading: false,
        error: null,
        partners: action.payload.data,
        added: false,
        partner: null,
      };
    case DELETE_PARTNER:
      return {
        ...state,
        loading: false,
        partners: state.partners.filter(
          (partner) => partner._id !== action.payload.data.partner._id
        ),
      };
    case UPDATE_PARTNER:
      return {
        ...state,
        added: true,
        loading: false,
        partner: action.payload.data.partner,
        partners: state.partners.map((partner) =>
          partner._id === action.payload.data.partner._id ? action.payload.data.partner : partner
        ),
      };
    case PARTNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_PARTNER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        partners: [],
        error: null,
        added: false,
        partner: null,
      };

    case CHECK_PARTNERS:
      return {
        ...state,
        checkPartners: action.payload.partners,
      };
    case CHECK_PARTNERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default variable;
