/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars

// Libraries
import { useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Layout
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";

// Material UI components
import Grid from "@mui/material/Grid2";

// Redux Actions
import {
  getPartners,
  clearErrors,
  deletePartner,
  updatePartner,
  setLoading,
} from "store/actions/partnerAction";
import setAlert from "store/actions/alertActions";
import { getPdf, getCsv } from "store/actions/exportAction";
import columnsMap from "components/MaterialTablePicker/TableColumns";

const Partners = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.partners.loading);
  const partners = useSelector((state) => state.partners.partners);
  const error = useSelector((state) => state.partners.error);
  const user = useSelector((state) => state.users.user);
  const added = useSelector((state) => state.partners.added);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getPartners());
  }, [added]);

  const deletePartnerFn = (id) => {
    dispatch(deletePartner(id));
  };

  const updatePartnerFn = (data) => {
    dispatch(updatePartner(data));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12 }}>
          <MDBox>
            <MaterialTablePicker
              screen="PartnersList"
              title="Partners"
              searchField="partners"
              exportMenuField="partners"
              onRowClickURL="/admin/partners"
              currentUrl="partners"
              tableRef={tableRef}
              columns={[
                columnsMap.partnerName,
                columnsMap.numberOfLicences,
                columnsMap.contactName,
                columnsMap.contactEmail,
                columnsMap.createdAt,
              ]}
              data={partners}
              isLoading={loading}
              filteringOptions={false}
              addUrl="partners/new-partner"
              actionsName="Partner"
              editableUpdate={updatePartnerFn}
              editableDelete={deletePartnerFn}
              updateRoleAction
              deleteRoleAction
              editableRole={user?.role === "admin"}
              actionsRole={user?.role === "admin"}
              // }}
              getPdf={(value) => {
                if (value) {
                  dispatch(
                    getPdf(
                      value,
                      `stats/pdf?limit=${partners?.length}&page=1&search=&sort=name&dir=asc&fields=name,licence,contactName,contactEmail,createdAt`,
                      "Partners"
                    )
                  );
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for PDF export.");
                }
              }}
              getCsv={() => {
                if (user?.role === "admin") {
                  dispatch(
                    getCsv(
                      `stats/csv?limit=${partners?.length}&page=1&search=&sort=name&dir=asc&fields=name,licence,contactName,contactEmail,createdAt`,
                      "Partners"
                    )
                  );
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for CSV export.");
                }
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default Partners;
