/* eslint-disable no-use-before-define */
import React from "react";
import { Text, Section } from "@react-email/components";

const FooterSection = () => (
  <Section style={styles.footer}>
    <Text style={styles.footerHeader}>
      Advanced Digital Technology, Dubai
      <br />
      United Arab Emirates
    </Text>

    <table style={styles.lokaleeLinks} cellPadding="0" cellSpacing="0" border="0">
      <tr>
        <td style={{ textAlign: "center", padding: "0 8px" }}>
          <a href="https://www.business.lokalee.app/aboutus" style={styles.footerLinks}>
            About
          </a>
        </td>

        <td style={{ textAlign: "center", padding: "0 8px" }}>|</td>

        <td style={{ textAlign: "center", padding: "0 8px" }}>
          <a href="https://www.business.lokalee.app/news" style={styles.footerLinks}>
            News
          </a>
        </td>

        <td style={{ textAlign: "center", padding: "0 8px" }}>|</td>

        <td style={{ textAlign: "center", padding: "0 8px" }}>
          <a href="https://www.business.lokalee.app/careers" style={styles.footerLinks}>
            Careers
          </a>
        </td>
      </tr>
    </table>

    <a href="https://lokalee.app">
      <p style={{ ...styles.footerLinks, fontWeight: "400", marginBottom: "16px" }}>
        Visit Lokalee.app
      </p>
    </a>

    <table cellPadding="0" cellSpacing="0" border="0" style={{ margin: "0 auto" }}>
      <tr>
        <td style={{ padding: "0 10px" }}>
          <a
            href="https://www.linkedin.com/company/linkedin.com-lokalee/mycompany/"
            style={styles.socialLinks}
          >
            <img
              alt="LinkedIn Logo"
              style={styles.sociallogo}
              src="https://email-assets.lokalee.app/email/emailImages/linkedin.png"
            />
          </a>
        </td>

        <td style={{ padding: "0 10px" }}>
          <a href="https://www.facebook.com/lokaleeapp" style={styles.socialLinks}>
            <img
              alt="Facebook Logo"
              style={styles.sociallogo}
              src="https://email-assets.lokalee.app/email/emailImages/facebook.png"
            />
          </a>
        </td>

        <td style={{ padding: "0 10px" }}>
          <a href="https://www.instagram.com/lokalee.app/" style={styles.socialLinks}>
            <img
              alt="Instagram Logo"
              style={styles.sociallogo}
              src="https://email-assets.lokalee.app/email/emailImages/instagram.png"
            />
          </a>
        </td>
      </tr>
    </table>
  </Section>
);

const styles = {
  footer: {
    color: "#888",
    textAlign: "center",
    fontSize: "12px",
  },

  footerHeader: {
    margin: "0",
    color: "#3F3E3E",
    fontSize: "14px",
    alignText: "center",
  },

  lokaleeLinks: {
    margin: "16px auto",
  },

  footerLinks: {
    fontSize: "14px",
    color: "#3F3E3E",
    fontWeight: "bold",
    textDecoration: "underline",
  },

  lokaleeContainerLinks: {
    width: "50%",
    margin: "0 auto",
    marginBottom: "24px",
  },

  sociallogo: {
    height: "24px",
  },
};

export default FooterSection;
