/* eslint-disable react/require-default-props */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const ComplexStatisticsCard = ({
  color = "info",
  title,
  count = 0,
  subHeader = {
    color: "success",
    text: "",
    label: "",
    action: "",
    amount: "",
  },
  icon,
}) => (
  <Card>
    <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
      <MDBox
        variant="gradient"
        bgColor={color}
        color={color === "light" ? "dark" : "white"}
        coloredShadow={color}
        borderRadius="xl"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="4rem"
        height="4rem"
        mt={-3}
      >
        <Icon fontSize="medium" color="inherit">
          {icon}
        </Icon>
      </MDBox>
      <MDBox textAlign="right" lineHeight={1.25}>
        <MDTypography variant="button" fontWeight="light" color="text">
          {title}
        </MDTypography>
        <MDTypography variant="h4">{count}</MDTypography>
      </MDBox>
    </MDBox>
    <Divider sx={{ marginBottom: "5px" }} />
    <MDBox pb={2} px={2}>
      {subHeader?.action?.length > 0 ? (
        <MDButton
          component={Link}
          to={subHeader.action}
          variant="text"
          color="info"
          style={{ padding: 0 }}
        >
          <Icon>add</Icon>&nbsp;{subHeader.label}
        </MDButton>
      ) : (
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color={subHeader.color}>
            {subHeader.amount}
          </MDTypography>
          &nbsp;{subHeader.label}
        </MDTypography>
      )}
    </MDBox>
  </Card>
);

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subHeader: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    action: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
