import { CLEAR_ERRORS, SET_STATS_LOADING } from "./Types";

// import factory from './actionsFactory';
// eslint-disable-next-line no-undef
const factory = require("./actionsFactory");

export const getDashboardStats = (timeZone) =>
  factory.get(
    // eslint-disable-next-line no-undef
    `${process.env.REACT_APP_API}/stats/adminDashboard?timeZone=${timeZone}`,
    "GET_DASHBOARD_STATS",
    "STATS_FAIL"
  );

export const getCompanionAnalytics = (id) =>
  factory.get(
    // eslint-disable-next-line no-undef
    `${process.env.REACT_APP_API}/stats/analytics/guestCompanionReport?location=${id}`,
    "GET_COMPANION_ANALYTICS",
    "COMPANION_ANALYTICS_FAIL"
  );

export const getTopViewedItems = (id) => {
  const url = id
    ? `${process.env.REACT_APP_API}/stats/analytics/itemReport?location=${id}`
    : `${process.env.REACT_APP_API}/stats/analytics/itemReport`;
  return factory.get(url, "GET_TOPITEMS", "STATS_FAIL");
};

export const getPartnerDashboard = (location, timeZone) =>
  factory.get(
    // eslint-disable-next-line no-undef
    `${process.env.REACT_APP_API}/stats/partnerDashboard/${location}?timeZone=${timeZone}`,
    "GET_DASHBOARD_STATS",
    "STATS_FAIL"
  );

export const getOrders = (location) =>
  factory.get(
    // eslint-disable-next-line no-undef
    `${process.env.REACT_APP_API}/stats/orders/${location}`,
    "GET_DASHBOARD_ORDERS",
    "STATS_FAIL"
  );

export const getBookings = (location) => {
  if (location) {
    return factory.get(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API}/stats/bookings/${location}?sort=createdAt&dir=asc`,
      "GET_DASHBOARD_BOOKINGS",
      "STATS_FAIL"
    );
  }

  return factory.get(
    // eslint-disable-next-line no-undef
    `${process.env.REACT_APP_API}/stats/bookings?sort=createdAt&dir=asc`,
    "GET_DASHBOARD_BOOKINGS",
    "STATS_FAIL"
  );
};

export const getConciergeOrders = (id) =>
  factory.get(
    // eslint-disable-next-line no-undef
    `${process.env.REACT_APP_API}/stats/conciergeDashboard?id=${id}`,
    "GET_CONCIERGE_ORDERS",
    "STATS_FAIL"
  );

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_STATS_LOADING });
