/* eslint-disable no-use-before-define */
import {
  Box,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  Typography,
  InputLabel,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Form, Formik } from "formik";

import { useDispatch } from "react-redux";
import { updateSettingsConfigs } from "store/actions/locationAction";

import FormField from "components/FormField";
import MDButton from "components/MDButtonLoading";

import colors from "assets/theme/base/colors";
import onlineStoreForm from "../../schemas/OnlineStore/form";
import validations from "../../schemas/OnlineStore/validation";

const company = [
  { label: "Sky Software", value: "sky" },
  { label: "Message Box", value: "messagebox" },
];

const ConfigureSettings = ({ setIconPressed, settingsConfigs, locationId }) => {
  /* ** Constants ** */
  const dispatch = useDispatch();
  const { settingValidation } = validations;
  const { onlineFormId, settingFields } = onlineStoreForm;
  const { staffEmail, systemPicker, paymentMethod, orderProcessingType } = settingFields;
  const initialValues = {
    staffEmail: settingsConfigs.staffEmail,
    orderProcessingType: [{ name: "emailToCs", label: "Email to CS" }],
    paymentMethod: settingsConfigs.paymentMethod?.length
      ? paymentMethod.options.filter((option) =>
          settingsConfigs.paymentMethod.includes(option.name)
        )
      : paymentMethod.options,
  };

  const handleSaveBttn = async (values, resetForm, validateForm) => {
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    const updatedValues = {
      staffEmail: values.staffEmail,
      paymentMethod: values.paymentMethod.map((item) => item.name),
    };

    dispatch(updateSettingsConfigs(updatedValues, locationId));
    resetSettingsForm(resetForm);
  };
  const resetSettingsForm = (resetForm) => {
    setIconPressed("");
    resetForm();
  };

  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={settingValidation}
    >
      {({ values, errors, resetForm, setErrors, setFieldValue, validateForm }) => (
        <Form id={onlineFormId} autoComplete="off" style={styles.formStyle}>
          <Box style={styles.paymentContainer}>
            <Typography variant="h6" component="legend">
              Payment Method *
            </Typography>

            <Box>
              <FormGroup style={styles.checkBoxContainer}>
                {paymentMethod?.options.map(({ name, label }) => (
                  <FormControlLabel
                    key={name}
                    label={label}
                    control={
                      <Checkbox
                        name={name}
                        checked={values.paymentMethod.map((item) => item.name).includes(name)}
                        onChange={(e) => {
                          const updatedPaymentMethods = e.target.checked
                            ? [...values.paymentMethod, { name, label }]
                            : values.paymentMethod.filter((item) => item.name !== e.target.name);

                          if (errors.paymentMethod) {
                            const { paymentMethod: _, ...rest } = errors;
                            setErrors(rest);
                          }
                          setFieldValue("paymentMethod", updatedPaymentMethods);
                        }}
                      />
                    }
                  />
                ))}
              </FormGroup>

              {errors.paymentMethod && (
                <Typography variant="body2" color="error" style={styles.errorStyle}>
                  *{errors.paymentMethod}
                </Typography>
              )}
            </Box>
          </Box>

          <Box>
            <Typography variant="h6" component="legend" style={{ marginBottom: "8px" }}>
              Choose Your Order Processing Type
            </Typography>

            <FormGroup style={styles.checkBoxContainer}>
              {orderProcessingType.options.map((option) => {
                const { name, label } = option;

                return (
                  <FormControlLabel
                    key={name}
                    label={label}
                    style={{
                      cursor: name === "guestRequest" ? "not-allowed" : "pointer",
                    }}
                    control={
                      <Checkbox
                        name={name}
                        disabled={name === "guestRequest"}
                        checked={values.orderProcessingType.map((item) => item.name).includes(name)}
                        onChange={(e) => {
                          const updatedOrderProcessingType = e.target.checked
                            ? [...values.orderProcessingType, { name, label }]
                            : values.orderProcessingType.filter(
                                (item) => item.name !== e.target.name
                              );

                          if (errors.orderProcessingType) {
                            const { orderProcessingType: _, ...rest } = errors;
                            setErrors(rest);
                          }
                          setFieldValue("orderProcessingType", updatedOrderProcessingType);
                        }}
                      />
                    }
                  />
                );
              })}
            </FormGroup>

            {errors.orderProcessingType && (
              <Typography variant="body2" color="error" style={styles.errorStyle}>
                *{errors.orderProcessingType}
              </Typography>
            )}
          </Box>

          <Box>
            {values.orderProcessingType.map((item) => item.name).includes("emailToCs") && (
              <FormField
                outlined
                name={staffEmail.name}
                label={staffEmail.label}
                value={values[staffEmail.name]}
                error={errors[staffEmail.name]}
                placeholder={staffEmail.placeholder}
                onChange={(e) => setFieldValue(staffEmail.name, e.target.value)}
                success={values[staffEmail.name]?.length > 0 && !errors[staffEmail.name]}
                onFocus={() => {
                  if (errors[staffEmail.name]) {
                    const { [staffEmail.name]: _, ...rest } = errors;
                    setErrors(rest);
                  }
                }}
              />
            )}

            {values.orderProcessingType.map((item) => item.name).includes("guestRequest") && (
              <FormControl
                fullWidth
                key={systemPicker.name}
                error={errors[systemPicker.name]}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: errors[systemPicker.name] ? "#d32f2f" : colors.primaryC.main,
                    },
                    "&:hover fieldset": {
                      borderColor: errors[systemPicker.name] ? "#d32f2f" : colors.primaryC.main,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: errors[systemPicker.name] ? "#d32f2f" : colors.primaryC.main,
                    },
                  },
                }}
              >
                <InputLabel id={`${systemPicker.name}-label`}>{systemPicker.label}</InputLabel>

                <Select
                  id={systemPicker.name}
                  label={systemPicker.label}
                  value={values[systemPicker.name]}
                  labelId={`${systemPicker.name}-label`}
                  onChange={(e) => {
                    setFieldValue(systemPicker.name, e.target.value);
                  }}
                  onClick={() => {
                    if (errors[systemPicker.name]) {
                      const { [systemPicker.name]: _, ...rest } = errors;
                      setErrors(rest);
                    }
                  }}
                >
                  {company.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>

                {errors[systemPicker.name] && (
                  <FormHelperText sx={{ marginLeft: 0, fontWeight: "400" }}>
                    *{errors[systemPicker.name]}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Box>

          <div style={styles.actionBttnContainer}>
            <MDButton
              color="dark"
              rel="noreferrer"
              variant="outlined"
              onClick={() => resetSettingsForm(resetForm)}
            >
              Cancel
            </MDButton>

            <div style={styles.actionBttns}>
              <MDButton
                color="dark"
                rel="noreferrer"
                variant="outlined"
                onClick={() => handleSaveBttn(values, resetForm, validateForm)}
              >
                Save
              </MDButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ConfigureSettings;

const styles = {
  formStyle: {
    gap: "20px",
    display: "flex",
    padding: "20px",
    flexDirection: "column",
  },

  paymentContainer: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",
  },

  checkBoxContainer: {
    width: "100%",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #ccc",
  },

  errorStyle: {
    marginTop: "5px",
    marginLeft: "10px",
  },

  actionBttnContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
};

ConfigureSettings.propTypes = {
  locationId: PropTypes.string.isRequired,
  setIconPressed: PropTypes.func.isRequired,
  settingsConfigs: PropTypes.instanceOf(Object).isRequired,
};
