import React from "react";
import { Text } from "@react-email/components";
import PropTypes from "prop-types";

const IfYouNeedSupportSection = ({ showTitle = true }) => {
  // To prevent blinking when user clicks to open the external email app
  const handleEmailClick = (e) => {
    e.preventDefault();
    window.location.href = "mailto:cs@lokalee.app";
  };

  return (
    <>
      {showTitle && (
        <Text
          style={{
            fontSize: "20px",
            color: "#000000",
            fontWeight: "700",
            lineHeight: "23px",
            textAlign: "center",
            marginBottom: "0.5rem",
          }}
        >
          If you need any assistance:
        </Text>
      )}

      <div
        style={{
          color: "#ffffff",
          padding: "15.5px",
          textAlign: "center",
          backgroundColor: "#BAB9B9",
        }}
      >
        <img
          src="https://email-assets.lokalee.app/email/emailImages/inbox.png"
          alt="Inbox"
          style={{
            width: "33px",
            height: "20px",
            display: "block",
            margin: "0 auto",
          }}
        />

        <p
          style={{
            margin: "0",
            fontSize: "11px",
            color: "#ffffff",
            lineHeight: "15px",
            fontWeight: "700",
          }}
        >
          Email us:
        </p>

        <a
          href="mailto:cs@lokalee.app"
          onClick={handleEmailClick}
          style={{
            fontSize: "11px",
            color: "#ffffff",
            fontWeight: "700",
            lineHeight: "15px",
            textDecoration: "none",
          }}
        >
          cs@lokalee.app
        </a>
      </div>
    </>
  );
};

IfYouNeedSupportSection.propTypes = {
  showTitle: PropTypes.bool,
};
export default IfYouNeedSupportSection;
