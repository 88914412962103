/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, createRef } from "react";
// @mui/material
import MaterialTablePicker from "components/MaterialTablePicker";

// Store
import setAlert from "store/actions/alertActions";
import { deletePromoCode, clearErrors, setPromoCodeEmpty } from "store/actions/promoCodeActions";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid2";
import MDBox from "components/MDBox";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import { getPdf, getCsv } from "store/actions/exportAction";
import columnsMap from "components/MaterialTablePicker/TableColumns";

const PromoCodes = () => {
  const dispatch = useDispatch();
  const tableRef = createRef();

  const loading = useSelector((state) => state.promoCodes.loading);
  const error = useSelector((state) => state.promoCodes.error);
  const added = useSelector((state) => state.promoCodes.added);
  const user = useSelector((state) => state.users.user);

  const deletePromoCodeFn = (id) => {
    dispatch(deletePromoCode(id));
  };
  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  useEffect(() => {
    dispatch(setPromoCodeEmpty());
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item size={{ xs: 12 }}>
          <MDBox>
            <MaterialTablePicker
              key={added}
              screen="PromoCodesList"
              title=""
              searchField="promoCodes"
              exportMenuField="promoCodes"
              onRowClickURL="/admin/promoCodes"
              currentUrl="promoCodes"
              idSynonymName="_id"
              columns={[
                columnsMap.titleCol,
                columnsMap.code,
                columnsMap.method,
                columnsMap.valueType,
                columnsMap.valueCol,
                columnsMap.status,
                columnsMap.used,
              ]}
              tableRef={tableRef}
              asyncTable
              isLoading={loading}
              asyncURL={`${process.env.REACT_APP_API}/promoCodes?`}
              addUrl="promoCodes/new-promoCode"
              actionsName="Promocode"
              editableDelete={deletePromoCodeFn}
              deleteRoleAction
              isSearchable={false}
              editableRole={user.role === "admin"}
              actionsRole={user.role === "admin"}
              getPdf={(value, query) => {
                if (value) {
                  dispatch(
                    getPdf(
                      value,
                      `stats/pdf${query}&fields=title,code,method,type,value,status,used`,
                      "PromoCodes"
                    )
                  );
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for PDF export.");
                }
              }}
              getCsv={(query) => {
                if (user?.role === "admin") {
                  dispatch(
                    getCsv(
                      `stats/csv${query}&fields=title,code,method,type,value,status,used`,
                      "PromoCodes"
                    )
                  );
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for PDF export.");
                }
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default PromoCodes;
