/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, createRef, useState } from "react";
import MaterialTablePicker from "components/MaterialTablePicker";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router";
import Tooltip from "@mui/material/Tooltip";

// // Store
import setAlert from "store/actions/alertActions";
import {
  deleteRecommendation,
  updateRecommendation,
  duplicateRecommendation,
  clearErrors,
  getLocationRecommendations,
  deleteSingleRecommendation,
} from "store/actions/recommendationActions";
import Grid from "@mui/material/Grid2";
import { useDispatch, useSelector } from "react-redux";
import MDButton from "components/MDButton";
import Footer from "components/Footer";
import columnsMap from "components/MaterialTablePicker/TableColumns";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import { getUserPartnerLocations, setLoading, setLocation } from "store/actions/locationAction";
import LocationUserPartner from "components/LocationUserPartner";
import { getPdf, getCsv } from "store/actions/exportAction";
import form from "../new-recommendation/schemas/form";

const Recommendations = () => {
  const { location } = form.formField;

  const navigate = useNavigate();
  const tableRef = createRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.recommendations.loading);
  const recommendation = useSelector((state) => state.recommendations.recommendation);
  const error = useSelector((state) => state.partners.error);
  const user = useSelector((state) => state.users.user);
  const added = useSelector((state) => state.recommendations.added);
  const locations = useSelector((state) => state.locations.locations);
  const locationPartner = useSelector((state) => state.locations.location);
  const [onChangeLocationID, setOnChangeLocationID] = useState(false);
  const [locationId, setLocationId] = useState(user?.role === "partner" ? locations[0]?._id : null);
  const handleChange = (option, val) => {
    if (recommendation?.location?._id) dispatch(deleteSingleRecommendation());
    if (locationPartner) dispatch(deleteSingleRecommendation());

    tableRef.current.onQueryChange();
    setOnChangeLocationID(true);
    setLocationId(val?._id);
    dispatch(setLocation(val?._id));
    dispatch(getLocationRecommendations(val?._id));
  };

  useEffect(() => {
    dispatch(setLocation(locationId));
  }, [locationId]);

  useEffect(() => {
    if (user?.role === "partner") {
      dispatch(setLoading());
      dispatch(getUserPartnerLocations());
    }
  }, [user.role]);

  useEffect(() => {
    if (locations?.length > 0 && !recommendation?.location?._id) {
      setLocationId(locations[0]?._id);
    }
  }, [locations]);
  // to add use Effect for set locaton ID when navigating back from edit-recommendation
  useEffect(() => {
    if (
      recommendation?.location?._id &&
      locationId !== recommendation?.location?._id &&
      user?.role === "partner" &&
      locationId
    ) {
      setLocationId(recommendation?.location?._id);
      dispatch(setLocation(locationId || recommendation?.location?._id));
      dispatch(getLocationRecommendations(locationId || recommendation?.location?._id));
    }
  }, [recommendation?.location?._id, locationId]);

  // to add use Effect for set locaton ID when navigating back from new-recommendation
  useEffect(() => {
    if (
      locationPartner &&
      locationId !== locationPartner &&
      user?.role === "partner" &&
      locationId
    ) {
      setLocationId(locationPartner?._id || locationPartner);
      dispatch(setLocation(locationPartner?._id || locationPartner));

      const id = locationPartner?._id || locationPartner;
      dispatch(getLocationRecommendations(id));
    }
  }, [locationPartner, locationId]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  const deleteRecommendationFn = (rec) => {
    dispatch(deleteRecommendation(rec?.id));
  };
  const duplicateRecommendationFn = (data, value) => {
    dispatch(duplicateRecommendation(value?.id));
  };

  const updateRecommendationFn = (data) => {
    const updatedData = {
      _id: data._id,
      title: data.title,
      badges: data.badges[0],
    };
    dispatch(updateRecommendation(updatedData));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item size={{ xs: 12 }}>
          <div
            style={{
              display: user.role === "admin" ? "flex" : "none",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <MDButton
              variant="contained"
              color="primary"
              onClick={() => navigate("/admin/recommendations/Dup-Recommendations")}
            >
              <Tooltip title="See Duplicates">
                <ContentCopyIcon />
              </Tooltip>
            </MDButton>
          </div>
          <Grid item size={{ xs: 4 }}>
            {user?.role === "partner" && locations.length > 0 && (
              <LocationUserPartner
                defaultLocationId={locationId}
                handleChange={handleChange}
                locations={locations || {}}
                location={location}
              />
            )}
          </Grid>
          <Grid item size={{ xs: 12 }}>
            {((user.role === "partner" && locationId) || user.role === "admin") && (
              <MaterialTablePicker
                key={added}
                screen="RecommendationList"
                title="Recommendations"
                columns={[
                  columnsMap.titleCol,
                  columnsMap.primaryDestination,
                  columnsMap.tagsWithFilter,
                  columnsMap.sourceRecommendations,
                  columnsMap.reservation,
                ]}
                tableRef={tableRef}
                asyncTable
                sortField="title"
                searchField="recommendations"
                exportMenuField="recommendations"
                idSynonymName="_id"
                onRowClickURL={`/${user.role}/recommendations`}
                currentUrl="recommendations"
                asyncURL={
                  user.role === "admin"
                    ? `${process.env.REACT_APP_API}/recommendations?`
                    : onChangeLocationID && user?.role === "partner"
                    ? `/v2/locations/${locationId}/recommendations?`
                    : recommendation?.location?._id &&
                      recommendation?.location?._id !== locationId &&
                      user?.role === "partner"
                    ? `/v2/locations/${recommendation?.location?._id}/recommendations?`
                    : locationPartner && locationPartner !== locationId && user?.role === "partner"
                    ? `/v2/locations/${locationPartner}/recommendations?`
                    : `/v2/locations/${locationId}/recommendations?`
                }
                addUrl="recommendations/new-recommendation"
                actionsName="Recommendation"
                actionsChild={() =>
                  user.role === "admin"
                    ? {
                        icon: "library_add",
                        tooltip: "Duplicate",
                        onClick: duplicateRecommendationFn,
                      }
                    : ""
                }
                editableUpdate={updateRecommendationFn}
                editableDelete={deleteRecommendationFn}
                updateRoleAction
                deleteRoleAction
                isLoading={loading}
                editableRole={user.role === "admin"}
                actionsRole={user.role === "admin" || user.role === "partner"}
                getPdf={(value, query) => {
                  if (value) {
                    if (user?.role === "partner") {
                      dispatch(
                        getPdf(
                          value,
                          `stats/pdf${query}&fields=title,primaryDestination,tags,source`,
                          "Recommendations",
                          `${locationId}`
                        )
                      );
                    } else if (user?.role === "admin") {
                      dispatch(
                        getPdf(
                          value,
                          `stats/pdf${query}&fields=title,primaryDestination,tags,source`,
                          "Recommendations"
                        )
                      );
                    }
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for PDF export.");
                  }
                }}
                getCsv={(query) => {
                  if (user?.role === "partner") {
                    dispatch(
                      getCsv(
                        `stats/csv${query}&fields=title,primaryDestination,tags,source`,
                        "Recommendations",
                        `${locationId}`
                      )
                    );
                  } else if (user?.role === "admin") {
                    dispatch(
                      getCsv(
                        `stats/csv${query}&fields=title,primaryDestination,tags,source`,
                        "Recommendations"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for CSV export.");
                  }
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};
export default Recommendations;
