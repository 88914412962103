/* eslint-disable no-use-before-define */
import React from "react";
import { Text, Img } from "@react-email/components";
import PropTypes from "prop-types";

const ReservationDetails = ({
  date = "2025-1-5",
  numOfGuests = "2 Adults",
  title = "Lokalee ticket",
  time = "09:00AM - 4:00PM",
  image = "https://via.placeholder.com/300x150",
}) => {
  const convertToDayMonthYear = (dateStr) => {
    const [year, month, day] = dateStr.split("-").map(Number);
    const formattedDate = new Date(year, month - 1, day);

    return formattedDate.toLocaleDateString("en-US", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <table style={styles.componentStyle} width="100%" cellPadding="0" cellSpacing="0" border="0">
      <tr>
        <td valign="top" width={123} style={{ paddingRight: "16px" }}>
          <Img style={styles.image} alt="Wakame Modern Asian Cuisine" src={image} />
        </td>

        <td valign="top" width="100%">
          <table border="0" width="100%" cellPadding="0" cellSpacing="0">
            <tr>
              <td>
                <Text style={styles.title}>{title}</Text>
              </td>
            </tr>

            <tr>
              <td style={{ paddingTop: "24px" }}>
                <table border="0" width="100%" cellPadding="0" cellSpacing="0">
                  <tr>
                    <td style={{ paddingBottom: "8px" }}>
                      <Text style={styles.text}>Guest:</Text>
                    </td>

                    <td style={{ paddingBottom: "8px" }}>
                      <Text style={styles.value}>{numOfGuests} Adults</Text>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ paddingBottom: "8px" }}>
                      <Text style={styles.text}>Time/Date:</Text>
                    </td>

                    <td>
                      <Text style={styles.value}>{time}</Text>
                      <Text style={styles.value}>{convertToDayMonthYear(date)}</Text>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  );
};

const styles = {
  componentStyle: {
    padding: "16px",
    height: "155px",
    overflow: "hidden",
    borderRadius: "8px",
    backgroundColor: "#E6EBE9",
    border: "1px solid #7F978D",
  },

  image: {
    width: "123px",
    height: "123px",
    borderRadius: "8px",
  },

  title: {
    margin: "0",
    fontSize: "20px",
    fontWeight: "bold",
  },

  text: {
    margin: "0",
    fontSize: "18px",
    fontWeight: "bold",
    fontFamily: "Helvetica",
  },

  value: {
    margin: "0",
    color: "black",
    fontSize: "14px",
    fontFamily: "Helvetica",
  },

  timeDate: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
};

ReservationDetails.propTypes = {
  title: PropTypes.string,
  time: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string,
  numOfGuests: PropTypes.number,
};

export default ReservationDetails;
