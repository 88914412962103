import * as Yup from "yup";
import checkout from "./formStops";

const {
  formField: { description, tickets, venues, ctaLabel, ctaTitle, ctaUrl },
} = checkout;

const validationsStops = [
  Yup.object().shape({
    [description.name]: Yup.string()
      .matches(/([A-Za-z])/, description.invalidMsg)
      .required(description.errorMsg),
    [tickets.name]: Yup.array().when("type", {
      is: (type) => type === "tickets",
      then: () => Yup.array().min(1, tickets?.invalidMsg).required(tickets?.errorMsg),
    }),
    [venues.name]: Yup.mixed()
      .nullable()
      .when("type", {
        is: (type) => type === "venues",
        then: () => Yup.mixed().required(venues?.errorMsg),

        otherwise: () => Yup.mixed().nullable(),
      }),

    [ctaLabel.name]: Yup.string().when("type", {
      is: (type) => type !== "venues" && type !== "tickets",
      then: () => Yup.string().required(ctaLabel.errorMsg),
    }),
    [ctaTitle.name]: Yup.string().when("type", {
      is: (type) => type !== "venues" && type !== "tickets",
      then: () => Yup.string().required(ctaTitle.errorMsg),
    }),
    [ctaUrl.name]: Yup.string().when("type", {
      is: (type) => type !== "venues" && type !== "tickets",
      then: () =>
        Yup.string()
          .matches(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
            ctaUrl.invalidMsg
          )
          .test("no-spaces", "URL cannot contain spaces", (value) => !/\s/.test(value)) // Check for spaces
          .required(ctaUrl.errorMsg),
    }),
  }),
];

export default validationsStops;
