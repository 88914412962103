/* eslint-disable no-underscore-dangle */
import {
  SET_ITINERARY_LOADING,
  ITINERARY_ADD_SUCCESS,
  GET_ALL_ITINERARIES,
  CLEAR_ERRORS,
  DELETE_SINGLE_ITINERARY,
  GET_ONE_ITINERARY,
  LOGOUT,
  ITINERARY_FAIL,
  ITINERARY_STOP_ADD_SUCCESS,
  ITINERARY_STOPS_FAIL,
  DELETE_SINGLE_STOP,
  UPDATE_ITINERARY,
} from "../actions/Types";

const initialState = {
  loading: false,
  itineraries: [],
  error: null,
  added: false,
  itinerary: null,
  expId: "",
  daysNb: 0,
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case ITINERARY_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        itineraries: [...state.itineraries, action.payload.data?.experience],
        added: true,
        itinerary: null,
        expId: action.payload.data?.experience?._id,
        daysNb: action.payload.data?.experience?.days,
      };
    case UPDATE_ITINERARY:
      return {
        ...state,
        loading: false,
        error: null,
        itinerary: action.payload.data.data,
        itineraries: [...state.itineraries, action.payload.data],
        added: true,
      };
    case GET_ONE_ITINERARY:
      return {
        ...state,
        loading: false,
        error: null,
        itinerary: action.payload.data,
        added: false,
      };

    case GET_ALL_ITINERARIES:
      return {
        ...state,
        loading: false,
        error: null,
        itineraries: action.payload.data,
        added: false,
        itinerary: null,
        expId: "",
        daysNb: 0,
      };
    case DELETE_SINGLE_ITINERARY:
      return {
        ...state,
        loading: false,
        itineraries: state.itineraries.filter(
          (itineraries) => itineraries._id !== action.payload.data.data?._id
        ),
        added: true,
      };
    case ITINERARY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_ITINERARY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ITINERARY_STOP_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        stops: action.payload.data?.experience?.stops,
        itinerary: action.payload.data?.experience,
      };
    case DELETE_SINGLE_STOP:
      return {
        ...state,
        loading: false,
        itinerary: action.payload.data,
        added: true,
      };
    case ITINERARY_STOPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        itineraries: [],
        error: null,
        added: false,
        itinerary: null,
      };

    default:
      return state;
  }
};
export default variable;
