/* eslint-disable no-use-before-define */
import PropTypes from "prop-types";
import React from "react";

const HeaderComponent = ({
  name = "User",
  locationLogo = "https://email-assets.lokalee.app/email/emailImages/HiltonHotelsLogo.png",
}) => (
  <table width="100%" cellPadding="0" cellSpacing="10" border="0">
    <tr>
      <td valign="top">
        <img alt="Lokalee Logo" style={styles.logo} src={locationLogo} />
      </td>

      <td valign="middle">
        <span style={styles.xStyle}>x</span>
      </td>

      <td valign="top">
        <img
          alt="Lokalee Logo"
          style={styles.logo}
          src="https://email-assets.lokalee.app/email/emailImages/logo-text.png"
        />
      </td>

      <td align="right" width="100%">
        <span style={styles.greeting}>Hello {name} !</span>
      </td>
    </tr>
  </table>
);

const styles = {
  logo: {
    width: "auto",
    height: "50px",
  },

  xStyle: {
    color: "black",
    fontSize: "12px",
    fontWeight: "200",
  },

  greeting: {
    fontSize: "20px",
    fontWeight: "700",
  },
};

export default HeaderComponent;

HeaderComponent.propTypes = {
  name: PropTypes.string,
  locationLogo: PropTypes.string,
};
