/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Html, Section, Text } from "@react-email/components";
import PropTypes from "prop-types";

import HeadSection from "../HeadSection";
import LogoSection from "../LogoSection";
import FooterSection from "../FooterSection";

const EmailToHotelService = (data = {}) => {
  const { user = {}, data: emailData = {} } = data;
  const { roomNumber = "101" } = user;
  const {
    totalPax = 1,
    paymentMethod = "Cash",
    invoiceNumber = "INV-0001",
    email = "guest@example.com",
    createdAt = new Date().toDateString(),
    items = [{ ref: { title: "Item 1" } }],
  } = emailData;

  return (
    <Html>
      <HeadSection />
      <Body
        style={{
          lineHeight: "1.625",
          backgroundColor: "#f7fafc",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <Container
          style={{
            padding: "0",
            maxWidth: "600px",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "#ffffff",
            border: "1px solid #eaeaea",
          }}
        >
          {/* Logo Section */}
          <LogoSection />

          {/* Greeting Section */}
          <Section
            style={{
              textAlign: "left",
              padding: "1.5rem 2rem 1rem 2rem",
            }}
          >
            <Text
              style={{
                fontSize: "24px",
                color: "#222D29",
                marginBottom: "1rem",
              }}
            >
              You have a pending premium service request from a guest. Kindly review the following
              details to ensure fulfillment within possibility.
            </Text>
          </Section>

          {/* Service Details Section */}
          <Section
            style={{
              maxWidth: "536px",
              padding: "16px 24px",
              borderRadius: "11.54px",
              margin: "0 auto 24px auto",
              backgroundColor: "#F2F2F2",
            }}
          >
            <Text
              style={{
                margin: "0px",
                fontSize: "14px",
                color: "#222D29",
                fontWeight: "bold",
                marginBottom: "4px",
              }}
            >
              SERVICE DETAILS
            </Text>

            {paymentMethod === "stripe" && (
              <div style={{ display: "flex", marginBottom: "4px" }}>
                <Text style={{ fontSize: "14px", color: "#222D29", margin: "0px" }}>
                  Order Number:
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#222D29",
                    margin: "0px",
                  }}
                >
                  {invoiceNumber}
                </Text>
              </div>
            )}

            <div style={{ display: "flex", marginBottom: "4px", margin: "0px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29", margin: "0px" }}>
                Order Details:
              </Text>
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#222D29",
                  margin: "0px",
                }}
              >
                {items.map((item) => item.ref.title).join(", ")}
              </Text>
            </div>

            <div style={{ display: "flex", marginBottom: "4px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29", margin: "0px" }}>
                Order Quantity:
              </Text>

              <Text
                style={{
                  margin: "0px",
                  fontSize: "14px",
                  color: "#222D29",
                  fontWeight: "bold",
                }}
              >
                {totalPax}
              </Text>
            </div>

            <div style={{ display: "flex", marginBottom: "4px", margin: "0px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29", margin: "0px" }}>
                Request Date & Time:
              </Text>

              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#222D29",
                  margin: "0px",
                }}
              >
                {createdAt}
              </Text>
            </div>

            <div style={{ display: "flex", marginBottom: "4px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29", margin: "0px" }}>
                Order Status:
              </Text>
              <Text
                style={{
                  margin: "0px",
                  fontSize: "14px",
                  color: "#222D29",
                  fontWeight: "bold",
                }}
              >
                {paymentMethod === "stripe" ? "Paid" : "Pending"}
              </Text>
            </div>
          </Section>

          {/* Guest Details Section */}
          <Section
            style={{
              maxWidth: "536px",
              padding: "16px 24px",
              borderRadius: "11.54px",
              backgroundColor: "#F2F2F2",
              margin: "0 auto 24px auto",
            }}
          >
            <Text
              style={{
                margin: "0px",
                fontSize: "14px",
                color: "#222D29",
                fontWeight: "bold",
                marginBottom: "4px",
              }}
            >
              GUEST DETAILS
            </Text>
            <div style={{ display: "flex", marginBottom: "4px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29", margin: "0px" }}>Last Name:</Text>

              <Text
                style={{
                  margin: "0px",
                  fontSize: "14px",
                  color: "#222D29",
                  fontWeight: "bold",
                }}
              >
                {email.split("@")[0]}
              </Text>
            </div>

            <div style={{ display: "flex", marginBottom: "4px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29", margin: "0px" }}>
                Room Number:
              </Text>

              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#222D29",
                  margin: "0px",
                }}
              >
                {roomNumber}
              </Text>
            </div>
            <div style={{ display: "flex", marginBottom: "4px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29", margin: "0px" }}>Email:</Text>
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#222D29",
                  margin: "0px",
                }}
              >
                {email}
              </Text>
            </div>
          </Section>

          {/* Footer Section */}
          <Section
            style={{
              textAlign: "left",
              padding: "24px",
              borderTop: "1px solid #eaeaea",
            }}
          >
            <Text
              style={{
                margin: "0px",
                fontSize: "20px",
                fontWeight: "700",
                color: "#222D29",
                marginBottom: "20px",
              }}
            >
              Thank you,
            </Text>

            <Text style={{ fontSize: "20px", color: "#222D29", fontWeight: "400", margin: "0px" }}>
              Lokalee Operations Team
            </Text>
          </Section>
          <FooterSection hasSocialLinks={false} />
        </Container>
      </Body>
    </Html>
  );
};

export default EmailToHotelService;

EmailToHotelService.propTypes = {
  data: PropTypes.shape({}),
};
