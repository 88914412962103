/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import {
  SET_PROMOTION_LOADING,
  CLEAR_ERRORS,
  PROMOTION_ADD_SUCCESS,
  PROMOTION_FAIL,
  UPDATE_PROMOTION,
  DELETE_SINGLE_PROMOTION,
  SET_PROMOTION_EMPTY,
} from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add promotion
export const addPromotion = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("title", data.title.trim());
  form.append("summary", data.summary);
  form.append("description", data.description);
  if (data.bookingEmail) form.append("bookingEmail", data.bookingEmail);
  form.append("minCover", data.minCover);
  form.append("maxCover", data.maxCover);

  if (data?.recommendation && Object.keys(data?.recommendation).length !== 0)
    form.append(
      "recommendation",
      data?.recommendation?._id ? data?.recommendation?._id : data?.recommendation?.value
    );

  if (data.openEnded !== undefined) form.append("openEnded", data.openEnded);
  form.append("needsConfirmation", data.needsConfirmation);
  form.append("startDate", data.startDate);
  if (!data.openEnded) {
    form.append("endDate", data?.endDate);
  }
  if (data?.location) form.append("location", data.location?._id || data.location);

  // open hours is added to helper.js
  data.openHours?.map((openHour) => {
    form.append("openHours", JSON.stringify(openHour));
    return openHour;
  });
  if (image) form.append("image", image);
  try {
    const res = await axios.post(`${URL}/promotions`, form, config);

    dispatch({
      type: PROMOTION_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROMOTION_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Update promotion
export const updatePromotion = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  form.append("title", data.title.trim());
  if (data.summary) form.append("summary", data.summary.trim());
  if (data.description) form.append("description", data.description.trim());
  if (data.bookingEmail) form.append("bookingEmail", data.bookingEmail);
  if (data.minCover) form.append("minCover", data.minCover);
  if (data.maxCover) form.append("maxCover", data.maxCover);
  if (data.openEnded !== undefined) form.append("openEnded", data.openEnded);
  if (data.source !== undefined) form.append("source", data.source);
  if (data.needsConfirmation !== undefined)
    form.append("needsConfirmation", data.needsConfirmation);
  if (data.startDate) form.append("startDate", data?.startDate);
  if (data.openEnded === false) {
    if (data.endDate) form.append("endDate", data?.endDate);
  }
  if (data?.location)
    form.append(
      "location",
      typeof data?.location === "object" && Object.keys(data?.location).length !== 0
        ? data.location?._id
        : typeof data?.location === "string"
        ? data.location
        : ""
    );
  if (data?.recommendation && Object.keys(data?.recommendation).length !== 0)
    form.append(
      "recommendation",
      data?.recommendation?._id ? data?.recommendation?._id : data?.recommendation?.value
    );
  data.openHours?.map((openHour) => {
    form.append("openHours", JSON.stringify(openHour));
    return openHour;
  });
  // For logo
  if (image) {
    image.path = image?.name;
    form.append("image", image);
    // form.append("hasLogo", true);
  }

  if (data?.description?.length === 0) {
    form.append("description", "");
  }
  if (data?.minCover?.length === 0) {
    form.append("minCover", "");
  }
  if (data?.maxCover?.length === 0) {
    form.append("maxCover", "");
  }
  try {
    const res = await axios.patch(`${URL}/promotions/${data._id}`, form, config);

    dispatch({
      type: UPDATE_PROMOTION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROMOTION_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Delete promotion - set active as false
export const deletePromotion = (id) =>
  factory.patch({ active: false }, `${URL}/promotions/${id}`, "DELETE_PROMOTION", "PROMOTION_FAIL");

// Get city promotions
export const getCityItems = (city) =>
  factory.get(`${URL}/items?sort=name&dir=asc&city=${city}`, "GET_PROMOTIONS", "PROMOTION_FAIL");

// get One promotion
export const getOnePromotion = (id) =>
  factory.get(`${URL}/promotions/${id}`, "GET_ONE_PROMOTION", "PROMOTION_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_PROMOTION_LOADING });

// Get Partner promotions

export const getPartnerItems = (partner) =>
  factory.get(
    `${URL}/partners/${partner}/items?itemType=promotion`,
    "GET_PROMOTIONS",
    "PROMOTION_FAIL"
  );

export const setPromotionEmpty = () => ({ type: SET_PROMOTION_EMPTY });

// Get Location promotions

export const getLocationItems = (location) =>
  factory.get(
    `${URL}/locations/${location}/items?itemType=promotion`,
    "GET_PROMOTIONS",
    "PROMOTION_FAIL"
  );

// Get Location promotions
export const getLocationPromotions = (location) =>
  factory.get(`${URL}/locations/${location}/promotions`, "GET_PROMOTIONS", "PROMOTION_FAIL");

export const updatePromotionTranslations = (data, id) =>
  factory.patch(
    {
      title: data.title,
      summary: data.summary,
      description: data.description,
    },
    `${URL}/promotions/${id}/translations?locale=ar`,
    "UPDATE_PROMOTION_TRANSLATION",
    "PROMOTION_FAIL"
  );

export const getPromotionTranslations = (id) =>
  factory.get(
    `${URL}/promotions/${id}/translations?locale=ar`,
    "GET_PROMOTION_TRANSLATION",
    "PROMOTION_FAIL"
  );

export const deleteSinglePromotion = () => ({ type: DELETE_SINGLE_PROMOTION });
