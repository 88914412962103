/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Avatar, Divider } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
import { areAllValuesEmpty } from "components/helper";
import { useSelector } from "react-redux";

const Review = ({ formData, editPage = false, selectedPrimaryDestination = {} }) => {
  const { values } = formData;
  const [locationInfoData, setLocationInfoData] = useState({});
  const [addressInfoData, setAddressInfoData] = useState({});
  const [addInfoData, setAddInfoData] = useState({});
  // const [disclaimerInfoData, setDisclaimerInfoData] = useState({});
  const [fields, setFields] = useState([]);
  const partners = useSelector((state) => state.partners.partners);
  const travelDesks = useSelector((state) => state.travelDesks.travelDesks);

  const selectedPartners = partners.filter((part) => part.id === values.partner);
  const selectedTravelDesks = travelDesks.filter((travelDes) => travelDes.id === values.travelDesk);
  const selectedPartnerNames = selectedPartners.map((part) => part.name);
  const selectedTravelDeskNames = selectedTravelDesks.map((travelDes) => travelDes.name);

  useEffect(() => {
    if (values) {
      setLocationInfoData({
        Name: values?.name,
        Partner: selectedPartnerNames,
        locationEmail: values?.email,
        Top: values?.top,
        TRN: values?.trn,
        primary: values?.primary,
        background: values?.background,
        button: values?.button,
        disclaimerText: values?.disclaimer && values?.disclaimerText,
      });
      setAddressInfoData({
        primaryDestination: selectedPrimaryDestination?.label,
        destinations:
          values?.destinations && values?.destinations.length > 0
            ? values?.destinations.map((option) => option.label).join(", ")
            : [],
        Address: values?.address,
        Country: values?.country,
        City: values?.city,
        Lat: values?.lat,
        Lng: values?.lng,
      });
      setAddInfoData({
        TravelDesk: selectedTravelDeskNames,
        NumRooms: values?.numRooms,
        ServiceTitle: values?.serviceTitle,
        DinningLabel: values?.dinningLabel,
        facilities_and_services: values?.enableServices,
        trendTitle: values?.trendTitle,
        trendSubTitle: values?.trendSubTitle,
        template: values?.template,
        images: values?.images,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([
      { id: 0, title: "Location info", data: locationInfoData },
      { id: 1, title: "Address", data: addressInfoData },
      { id: 2, title: "Additional info", data: addInfoData },
    ]);
  }, [locationInfoData, addressInfoData, addInfoData]);

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {!editPage ? (
            <Grid item size={{ xs: 12, sm: 6 }}>
              {values?.logo ? (
                <MDAvatar
                  src={URL.createObjectURL(values?.logo)}
                  alt="profile-image"
                  size="xl"
                  sx={{ "& img": { height: "100%" } }}
                  bgColor="dark"
                />
              ) : (
                <BigAvatar
                  alt="Change your profile photo"
                  src={URL.revokeObjectURL(values?.logo)}
                  imgProps={{
                    style: {
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              )}
            </Grid>
          ) : (
            ""
          )}
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              size={{ xs: 12 }}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList data={field.data} title={field?.title} />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}{" "}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
};

export default Review;
