/* eslint-disable no-use-before-define */
import PropTypes from "prop-types";
import React from "react";

const DetailsCard = ({
  numOfGuests = "2",
  bookingTime = "07:00",
  bookingDate = "13/11/2000",
  visitorPhone = "1234567890",
  visitorName = "Lokalee User",
  visitorEmail = "lokalee.app",
}) => (
  <table style={styles.table}>
    <tbody>
      <tr>
        <td style={styles.leftCell}>Guests</td>
        <td style={styles.rightCell}>{numOfGuests}</td>
      </tr>
      <tr>
        <td style={styles.leftCell}>Booking Time</td>
        <td style={styles.rightCell}>{bookingTime}</td>
      </tr>
      <tr>
        <td style={styles.leftCell}>Booking Date</td>
        <td style={styles.rightCell}>{bookingDate}</td>
      </tr>

      <tr>
        <td colSpan="2" aria-label="Separator">
          <div style={styles.separator} />
        </td>
      </tr>

      <tr>
        <td style={styles.leftCell}>Name</td>
        <td style={styles.rightCell}>{visitorName}</td>
      </tr>
      <tr>
        <td style={styles.leftCell}>Phone</td>
        <td style={styles.rightCell}>{visitorPhone}</td>
      </tr>
      <tr>
        <td style={styles.leftCell}>Email</td>
        <td style={styles.rightCell}>{visitorEmail}</td>
      </tr>
    </tbody>
  </table>
);

const styles = {
  table: {
    width: "100%",
    padding: "16px",
    overflow: "hidden",
    borderRadius: "8px",
    tableLayout: "auto",
    marginBottom: "24px",
    borderCollapse: "separate",
    border: "1px solid #5F7D71",
  },

  leftCell: {
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },

  rightCell: {
    textAlign: "right",
  },

  separator: {
    margin: "10px 0",
    borderTop: "1px solid #E6EBE9",
  },
};

export default DetailsCard;

DetailsCard.propTypes = {
  numOfGuests: PropTypes.string,
  bookingTime: PropTypes.string,
  bookingDate: PropTypes.string,
  visitorPhone: PropTypes.string,
  visitorName: PropTypes.string,
  visitorEmail: PropTypes.string,
};
