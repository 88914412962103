/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
// Libraries
import React from "react";
import PropTypes from "prop-types";

// Material UI components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";

// Custom Components
import DynamicCard from "components/DynamicCard";
import Loader from "components/Loader";
import imageReturn from "utils/function/imageReturn";
import MDTypography from "components/MDTypography";

const CardsList = ({
  data,
  loading = false,
  url = undefined,
  cardTitle = "",
  source = undefined,
  children = "",
  user = {},
  isExperience = false,
  sourceId = "",
}) => {
  // Ensure data is an array
  const dataArray = Array.isArray(data) ? data : [];

  return (
    <Card>
      <div style={{ display: "flex", justifyContent: "space-between", padding: "24px" }}>
        {cardTitle?.length > 0 && (
          <MDTypography component="p" variant="body1">
            {cardTitle}
          </MDTypography>
        )}
        {children}
      </div>

      {loading ? (
        <Loader />
      ) : dataArray.length === 0 ? (
        <MDTypography
          component="p"
          variant="body2"
          style={{ textAlign: "center", padding: "24px" }}
        >
          No data to display
        </MDTypography>
      ) : (
        <Grid container pt={2}>
          {dataArray.map((item) => (
            <Grid
              item
              key={item._id}
              size={{ xs: 4 }}
              style={{ marginBottom: "30px", paddingLeft: "16px", paddingRight: "16px" }}
            >
              <DynamicCard
                image={imageReturn(item)}
                styleLabel={user?.role === "curator" && isExperience ? item?.label : null}
                title={item?.name || item.title}
                subHeader={
                  user?.role !== "curator"
                    ? item?.geoLocation?.address || item?.geoLocation?.city
                    : ""
                }
                destinationName={
                  user?.role === "curator" && item?.primaryDestination?.destinationName
                }
                nbOfDays={
                  user?.role === "curator" && item?.days
                    ? `${item?.days} ${item?.days === 1 ? "Day" : "Days"}`
                    : null
                }
                startDate={item?.startDate}
                endDate={item?.endDate}
                action={{
                  type: "internal",
                  route: url
                    ? `${url}/${item?._id}${source ? `?source=${source}` : ""}${
                        sourceId ? `&sourceId=${sourceId}` : ""
                      }`
                    : undefined,
                  color: "info",
                  label: "See More",
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Card>
  );
};

// Typechecking props for the CardsList
CardsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  loading: PropTypes.bool,
  user: PropTypes.instanceOf(Object),
  url: PropTypes.string,
  cardTitle: PropTypes.string,
  source: PropTypes.string,
  children: PropTypes.node, // Define children as a prop
  isExperience: PropTypes.bool,
  sourceId: PropTypes.string,
};

export default CardsList;
