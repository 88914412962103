/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

import React from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid2";
import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { Card, CardContent, Typography, Box } from "@mui/material";

const ItineraryRating = ({ formData }) => {
  const { reviews = [] } = formData.values || {};

  return (
    <Grid container spacing={2} sx={{ maxHeight: 300, padding: 1 }}>
      {reviews.map((item) => {
        const { _id = "", liked = undefined, text = "", userName = "Guest" } = item || {};

        return (
          <Grid item size={{ xs: 12, sm: 6 }} key={_id}>
            <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
              <CardContent sx={{ "&:last-child": { paddingBottom: "8px" } }}>
                {liked !== undefined && (
                  <Box display="flex" alignItems="center" sx={{ fontSize: "0.875rem", gap: "5px" }}>
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ mr: 1, display: "flex", alignItems: "center", margin: 0 }}
                    >
                      <strong>Score:</strong>
                    </Typography>

                    <Box display="flex" alignItems="center">
                      {!liked ? (
                        <ThumbDown sx={{ color: "black", transform: "scale(1.1)" }} />
                      ) : (
                        <ThumbUp sx={{ color: "black", transform: "scale(1.1)" }} />
                      )}
                    </Box>
                  </Box>
                )}

                <Typography
                  variant="body2"
                  component="div"
                  sx={{ fontSize: "0.875rem", marginBottom: "5px" }}
                >
                  <strong>Name:</strong> {userName}
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ fontSize: "0.875rem", marginBottom: "5px", whiteSpace: "pre-line" }}
                >
                  <strong>Comment:</strong> {text?.replace(/\\n/g, "\n")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

// Typechecking props for ItineraryRating
ItineraryRating.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
};

export default ItineraryRating;
