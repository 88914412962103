/* eslint-disable no-underscore-dangle */
import React from "react";
import { Body, Container, Html, Text } from "@react-email/components";

import PropTypes from "prop-types";

import FooterSection from "../../FooterSection";
import LogoSection from "../../LogoSection/index";
import ReservationCard from "../Components/ReservationCard";

const ClientConfirmation = ({ data = {} }) => {
  /* ****** Constants ****** */
  const isProduction = process.env.NODE_ENV === "production";
  const apiBaseUrl = isProduction ? "" : "https://staging.";
  const {
    _id = "",
    guest = 2,
    time = "08:00",
    name = "John Doe",
    date = "2 Apr 2024",
    phone = "+123456789",
    email = "john.doe@example.com",
    recommendation = { title: "Lokalee" },
  } = data;

  return (
    <Html>
      <Body
        style={{
          display: "flex",
          color: "#222D29",
          alignItems: "center",
          fontFamily: "Helvetica",
          justifyContent: "center",
          backgroundColor: "#f7fafc",
        }}
      >
        <Container
          style={{
            maxWidth: "600px",
          }}
        >
          <LogoSection />

          <Text
            style={{
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "25px",
              padding: "0px 32px",
            }}
          >
            Hello {recommendation.title},
          </Text>

          <Text
            style={{
              fontSize: "24px",
              fontWeight: "400",
              lineHeight: "26.5px",
              padding: "30px 32px",
            }}
          >
            You have a pending reservation request. Kindly review the details and get back to our
            guest the soonest:
          </Text>

          <div style={{ padding: "0px 24px", marginBottom: "34px" }}>
            <ReservationCard
              action={`${apiBaseUrl}lokalee.app/recommendations/confirmationSent?bookingId=${_id}`}
              data={{
                guests: guest,
                time,
                name,
                date,
                phone,
                email,
              }}
            />
          </div>

          <FooterSection hasSocialLinks={false} />
        </Container>
      </Body>
    </Html>
  );
};

ClientConfirmation.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export default ClientConfirmation;
