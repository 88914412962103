// Libraries
import { useEffect, createRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Layout
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";

// Material UI components
import Grid from "@mui/material/Grid2";

// Redux Actions
import setAlert from "store/actions/alertActions";
import { getPdf, getCsv } from "store/actions/exportAction";
import {
  getItineraries,
  clearErrors,
  setLoading,
  deleteItinerary,
} from "store/actions/itineraryActions";
import columnsMap from "components/MaterialTablePicker/TableColumns";

const Experiences = () => {
  // Constants
  const tableRef = createRef();
  const dispatch = useDispatch();

  // States
  const [itineraryType, setItineraryType] = useState("Curated");

  // Selectors
  const user = useSelector((state) => state.users.user);
  const added = useSelector((state) => state.itineraries.added);
  const error = useSelector((state) => state.itineraries.error);
  const loading = useSelector((state) => state.itineraries.loading);
  const itineraries = useSelector((state) => state.itineraries.itineraries);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getItineraries(itineraryType));
  }, [dispatch, added, itineraryType]);

  // Determine props based on itineraryType
  const isCurated = itineraryType === "Curated";
  const deleteRoleAction = isCurated;
  const actionsRole = isCurated ? user?.role === "admin" : false;
  const editableRole = isCurated ? user?.role === "admin" : false;
  const editableDelete = isCurated ? (id) => dispatch(deleteItinerary(id)) : undefined;
  const columns = isCurated
    ? [columnsMap.titleCol, columnsMap.travelStyle, columnsMap.curator, columnsMap.nbOfDays]
    : [columnsMap.titleCol, columnsMap.nbOfDays];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12 }}>
          <MDBox>
            <MaterialTablePicker
              data={itineraries}
              isLoading={loading}
              title="Itineraries"
              tableRef={tableRef}
              actionsRole={actionsRole}
              editableRole={editableRole}
              editableDelete={editableDelete}
              deleteRoleAction={deleteRoleAction}
              filteringOptions={false}
              actionsName="Experience"
              screen="ItinerariesList"
              currentUrl="experiences"
              searchField="experiences"
              exportMenuField="experiences"
              itineraryType={itineraryType}
              onRowClickURL="/admin/experiences"
              addUrl="experiences/new-experience"
              setItineraryType={setItineraryType}
              columns={columns}
              getPdf={(value) => {
                if (value) {
                  dispatch(
                    getPdf(
                      value,
                      `stats/pdf?limit=${itineraries?.length}&page=1&search=&sort=title&dir=dsc&fields=title,curator,days,label&aiGenerated=false`,
                      "Experiences"
                    )
                  );
                }
              }}
              getCsv={() => {
                if (user?.role === "admin") {
                  dispatch(
                    getCsv(
                      `stats/csv?limit=${itineraries?.length}&page=1&search=&sort=title&dir=dsc&fields=title,curator,days,label&aiGenerated=false`,
                      "Experiences"
                    )
                  );
                }
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default Experiences;
