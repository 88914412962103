import React from "react";
import { Routes, Route, Navigate } from "react-router";
// LCM Auth Routes - no authentication required
import routes from "auth.routes";

const Auth = () => {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <Routes>
      {getRoutes(routes)}
      <Route path="*" element={<Navigate to="/authentication/login" />} />
    </Routes>
  );
};

export default Auth;
