import React from "react";
import { Img, Section } from "@react-email/components";

const LogoSection = () => (
  <Section style={{ textAlign: "center", backgroundColor: "#f7fafc" }}>
    <Img
      alt="Logo"
      src="https://email-assets.lokalee.app/email/emailImages/logo.png"
      style={{
        height: "auto",
        maxWidth: "300px",
        margin: "40px auto",
      }}
    />
  </Section>
);

export default LogoSection;
