import React from "react";

// react-router components
import { Navigate, Routes, Route } from "react-router";

// LCM concierge routes - user role = concierge required
import routes from "concierge.routes";
import Concierge from "./Concierge";

const ConciergeRoutes = () => {
  // const isAuthenticated = useSelector((state) => state.users.isAuthenticated);
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={<Concierge>{route.component}</Concierge>}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <Routes>
      {getRoutes(routes)}
      <Route path="*" element={<Navigate to="/concierge/dashboards" />} />
    </Routes>
  );
};

export default ConciergeRoutes;
