/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import FormField from "components/FormField";
import CuratorPicker from "components/AsyncSelect/CuratorPicker";
import Danger from "components/Danger";
import PropTypes from "prop-types";

const CuratorFields = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleCuratorChange,
  curator,
}) => {
  useEffect(() => {
    if (values?.role === "curator") {
      setFieldValue("curator.name", values?.name || "");
      setFieldValue("curator.contact", values?.phone || "");
      setFieldValue("curator.email", values?.email || "");
      setFieldValue("curator._id", values?._id || "");
      setFieldValue("curatorOrProv", "curator");
    }
  }, [values.role, values.name, values.phone, values.email, setFieldValue]);
  return (
    <>
      <Grid item size={{ xs: 12 }}>
        <CuratorPicker
          disabled={values?.role === "curator"}
          curator={curator}
          handleChange={handleCuratorChange}
          setFieldValue={setFieldValue}
          selectedCurator={values?.role === "curator" ? values : values?.curator}
        />
        {errors?.curator && touched.curator && <Danger validation={errors.curator} />}
      </Grid>
      <Grid item size={{ xs: 12 }}>
        <FormField
          type="text"
          label="Curator Name"
          name="curator.name"
          value={
            values?.role === "curator"
              ? values?.name
              : values?.curator?.name || values?.curator?.label || ""
          }
          disabled
        />
      </Grid>
      <Grid item size={{ xs: 12 }}>
        <FormField
          type="text"
          label="Curator Contact"
          name="curator.contact"
          value={
            values?.role === "curator"
              ? values?.phone
              : values?.curator?.contact || values?.curator?.phone || ""
          }
          disabled
        />
      </Grid>
      <Grid item size={{ xs: 12 }}>
        <FormField
          type="email"
          label="Curator Email"
          name="curator.email"
          value={values?.role === "curator" ? values?.email : values?.curator?.email || ""}
          disabled
        />
      </Grid>
    </>
  );
};

CuratorFields.propTypes = {
  values: PropTypes.shape({
    role: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    contact: PropTypes.string,
    phone: PropTypes.string,
    _id: PropTypes.string,
    email: PropTypes.string,
    curator: PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      contact: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  curator: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleCuratorChange: PropTypes.func.isRequired,
};

export default React.memo(CuratorFields);
