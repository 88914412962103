/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable import/no-cycle */

import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import {
  IconButton,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import MDBox from "components/MDBox";
import DynamicTabs from "components/DynamicTabs";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { addStop, deleteStop } from "store/actions/itineraryActions";
import { Formik, Form } from "formik";
import validationsStops from "pages/content/experiences/new-experience/schemas/validationsStops";
import initialValuesStops from "pages/content/experiences/new-experience/schemas/initialValuesStops";
import ItineraryStops from "pages/content/experiences/new-experience/components/IntineraryStops";
import formStops from "pages/content/experiences/new-experience/schemas/formStops";
import MDButton from "components/MDButton";

const normalizeStops = (stops, numberOfDays) => {
  if (!Array.isArray(stops)) return [];
  if (stops[0] && stops[0].stops) return stops;

  const groupedStops = stops.reduce((acc, stop) => {
    const dayIndex = acc.findIndex((item) => item.day === stop.day);
    if (dayIndex !== -1) {
      acc[dayIndex].stops.push(stop);
    } else {
      acc.push({ day: stop.day, stops: [stop] });
    }
    return acc;
  }, []);

  // Ensure that all days from 1 to numberOfDays are included
  for (let i = 1; i < numberOfDays; i += 1) {
    if (!groupedStops.some((group) => group.day === i)) {
      groupedStops.push({ day: i, stops: [] });
    }
  }

  return groupedStops;
};

const StopsList = ({ stops, numberOfDays, slotOptions }) => {
  // Create a dynamic slotOrder object based on slotOptions
  const slotOrder = useMemo(() => {
    const order = {};
    slotOptions.forEach((option, index) => {
      order[option.value] = index + 1; // Assign priority based on the slot options order
    });
    return order;
  }, [slotOptions]);

  // Function to sort stops by time slot
  const sortStopsBySlot = (stop) =>
    [...stop].sort((a, b) => {
      const slotA = a.slot ? slotOrder[a.slot] : slotOrder["all day"];
      const slotB = b.slot ? slotOrder[b.slot] : slotOrder["all day"];
      return slotA - slotB;
    });

  // Normalize and sort stops
  const sortedStops = useMemo(
    () =>
      normalizeStops(stops, numberOfDays)
        .map((dayGroup) => ({
          ...dayGroup,
          stops: sortStopsBySlot(dayGroup.stops), // Sort the stops by slot within each day
        }))
        .sort((a, b) => a.day - b.day), // Keep days sorted
    [stops, numberOfDays, slotOrder]
  );

  const dispatch = useDispatch();
  const { experience_id } = useParams();
  const { formField } = formStops;
  const [stopTabs, setStopTabs] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [filteredStops, setFilteredStops] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (sortedStops.length > 0) {
      const tabs = sortedStops.map((dayItem) => ({
        id: dayItem.day,
        title: `Day ${dayItem.day}`,
      }));
      setStopTabs(tabs);
    }
  }, [sortedStops]);

  useEffect(() => {
    if (sortedStops.length > 0 && stopTabs.length > 0) {
      const selectedDayStops = sortedStops[tabValue]?.stops || [];
      setFilteredStops(selectedDayStops);
    }
  }, [tabValue, sortedStops, stopTabs]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDelete = (stopId) => {
    dispatch(deleteStop(experience_id, stopId));
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={{ p: 2, width: "100%" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", overflow: "auto" }}
      >
        <Grid item size={{ xs: 12, md: "auto" }}>
          <MDBox>
            <DynamicTabs
              detailTabs={stopTabs}
              tabValue={tabValue}
              handleSetTabValue={handleSetTabValue}
            />
          </MDBox>
        </Grid>
        <Grid item>
          <MDButton variant="gradient" color="dark" onClick={handleOpenDialog}>
            Add Stop
          </MDButton>
        </Grid>
      </Grid>

      {filteredStops?.map((stop, stopIndex) => (
        <Box
          key={stop._id}
          sx={{
            mb: 3,
            py: 4,
            borderBottom: "1px solid #ddd",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box>
            <MDTypography variant="h6" fontWeight="bold">
              Stop {stopIndex + 1}:
            </MDTypography>
            <MDTypography variant="body2" fontWeight="regular" textTransform="capitalize">
              <b>Time Slot:</b> {stop.slot}
            </MDTypography>
            <MDTypography variant="body2" fontWeight="regular" textTransform="capitalize">
              <b>Description:</b> {stop.description}
            </MDTypography>
            <MDTypography variant="body2" fontWeight="regular" textTransform="capitalize">
              <b>Action:</b> {stop.type || "CTA"}
            </MDTypography>
            {stop.type === "Venue" && stop.ids.length > 0 && (
              <MDTypography variant="body2" fontWeight="regular" textTransform="capitalize">
                <b>Venue name:</b> {stop.ids.map((venue) => venue.title).join(", ")}
              </MDTypography>
            )}
            {stop.type === "Ticket" && stop.ids.length > 0 && (
              <MDTypography variant="body2" fontWeight="regular" textTransform="capitalize">
                <b>Ticket name:</b> {stop.ids.map((ticket) => ticket.title).join(", ")}
              </MDTypography>
            )}
            {stop.cta && (
              <Box>
                <MDTypography variant="body2" fontWeight="regular" textTransform="capitalize">
                  <b>Cta title:</b> {stop.ctaTitle}
                </MDTypography>
                <MDTypography variant="body2" fontWeight="regular" textTransform="capitalize">
                  <b>Cta label:</b> {stop.ctaLabel}
                </MDTypography>
                <MDTypography
                  variant="body2"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={{
                    wordBreak: "break-all",
                    whiteSpace: "normal",
                    maxWidth: "100%",
                    overflow: "hidden",
                    display: "block",
                  }}
                >
                  <b>Cta URL:</b> {stop.ctaUrl}
                </MDTypography>
              </Box>
            )}
            <MDTypography variant="body2" fontWeight="regular" textTransform="capitalize">
              <b>Media:</b> {stop.media || "None"}
            </MDTypography>
            {stop.video && (
              <MDTypography variant="body2" fontWeight="regular" textTransform="capitalize">
                <b>Video:</b> {stop.video}
              </MDTypography>
            )}
          </Box>
          <IconButton
            onClick={() => handleDelete(stop._id)}
            color="error"
            sx={{ height: "fit-content" }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        sx={{ padding: "24px" }}
      >
        <DialogTitle>Add Stop</DialogTitle>
        <Formik
          initialValues={initialValuesStops}
          validationSchema={validationsStops[0]}
          onSubmit={(values) => {
            dispatch(addStop(experience_id, tabValue, values));
            handleCloseDialog();
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <DialogContent>
                <ItineraryStops
                  formData={{ formField, values, errors, touched, setFieldValue }}
                  id={experience_id}
                  noTable
                  handleSlotChange={() => {}}
                  handleTicketChange={() => {}}
                  selectedTickets={[]}
                  handleMediaChange={() => {}}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
};

StopsList.propTypes = {
  stops: PropTypes.instanceOf(Array).isRequired,
  numberOfDays: PropTypes.number.isRequired,
  slotOptions: PropTypes.instanceOf(Array).isRequired, // Added prop type for slotOptions
};

export default StopsList;
