import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router";
import { styled } from "@mui/material/styles";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButtonLoading from "components/MDButtonLoading";

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover-2.png";
import { useDispatch, useSelector } from "react-redux";
import { forgotPass, setLoading, clearErrors } from "store/actions/userActions";
import setAlert from "store/actions/alertActions";
import colors from "assets/theme/base/colors";

// validate email
const validateEmail = (emailToValidate) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(emailToValidate).toLowerCase());
};

const { primaryC } = colors;

const MDInputF = styled(MDInput)(() => ({
  backgroundColor: "transparent!important",
  "& .MuiInputLabel-root": {
    color: primaryC.main, // Change the label color here
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: primaryC.main, // Change the label color here
  },
  // "& .MuiInput-underline:before": {
  //   borderBottomColor: primaryC.main, // Change the bottom border color here
  // },
  "& .MuiInput-underline:after": {
    borderBottomColor: primaryC.main, // Change the active (focused) border color here
  },
}));

const ResetPassword = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.users.error);
  const linkSent = useSelector((state) => state.users.linkSent);
  const loading = useSelector((state) => state.users.loading);
  const [email, setEmail] = useState("");
  const [validation, setValidation] = useState(false);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleResetPassword = (event) => {
    if (event) event.preventDefault(); // Prevent form submission
    if (email === "" || !validateEmail(email)) {
      setValidation(true);
      return;
    }
    setValidation(false);
    dispatch(setLoading());
    dispatch(forgotPass(email));
  };

  useEffect(() => {
    if (linkSent) {
      dispatch(setAlert(error, "success"));
      dispatch(clearErrors());
    }
  }, [linkSent, setAlert, clearErrors, error]);

  useEffect(() => {
    if (error && !linkSent) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [linkSent, error, setAlert, clearErrors]);

  if (linkSent) {
    return <Navigate to="/authentication/login" />;
  }

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1} p={1}>
            Enter the email provided to you by your account manager. We will send you a link to
            reset your password.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleResetPassword}>
            <MDBox mb={4}>
              <MDInputF
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={handleChangeEmail}
                error={validation}
                value={email}
                helperText={
                  <span
                    style={{
                      margin: 0,
                      fontFamily: "Helvetica",
                      //   "Roboto","Helvetica","Arial",sans-serif;
                      fontSize: "0.75rem",
                      fontWeight: 300,
                      lineHeight: 1.25,
                      opacity: 1,
                      textTransform: "none",
                      verticalAlign: "unset",
                      textDecoration: "none",
                      marginTop: "6px",
                      color: " #F44335",
                    }}
                  >
                    {validation ? "Please enter a valid Email!" : ""}
                  </span>
                }
                // helperText={validation ? "Please enter a valid password." : ""}
                onFocus={() => setValidation(false)}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButtonLoading
                loading={loading}
                loadingPosition="start"
                // eslint-disable-next-line react/jsx-no-useless-fragment
                startIcon={<></>}
                variant="gradient"
                color="primary"
                fullWidth
                onClick={handleResetPassword}
                size="large"
                type="button" // Prevent form submission
              >
                reset
              </MDButtonLoading>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                Nevermind...{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/login"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Go to login
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

export default ResetPassword;
