/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";

// Material UI components
import Grid from "@mui/material/Grid2";

// Custom Components
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";
import { createRef, useEffect } from "react";
import { clearErrors } from "store/actions/experienceActions";
import setAlert from "store/actions/alertActions";
import { useDispatch, useSelector } from "react-redux";
import { getTravelDeskAttractions, setLoading } from "store/actions/attractionActions";
import { useHandleError } from "components/helper";
import Spinner from "components/Spinner";
import columnsMap from "components/MaterialTablePicker/TableColumns";

const AttractionsTravelDeskList = ({ travelDeskId }) => {
  const tableRef = createRef();
  const dispatch = useDispatch();
  const attractions = useSelector((state) => state.attractions.attractions);
  const loading = useSelector((state) => state.attractions.loading);
  const error = useSelector((state) => state.experiences.error);
  const user = useSelector((state) => state.users.user);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getTravelDeskAttractions(travelDeskId));
  }, [travelDeskId]);

  return (
    <>
      {" "}
      <MDBox>
        <Grid>
          <MaterialTablePicker
            screen="AttractionsTravelDeskList"
            title=""
            searchField="attractionsList"
            currentUrl="attractions"
            tableRef={tableRef}
            columns={[columnsMap.titleCol, columnsMap.cityWithFilter, columnsMap.retailPrice]}
            data={attractions}
            isExportable={false}
            onRowClickURL={`/${user.role}/attractions`}
            filteringOptions={false}
            actionsName="attractions"
          />
        </Grid>
      </MDBox>
      <Spinner loading={loading} />
    </>
  );
};

// typechecking props for AttractionsTravelDeskList
AttractionsTravelDeskList.propTypes = {
  travelDeskId: PropTypes.string.isRequired,
};

export default AttractionsTravelDeskList;
