/* eslint-disable no-underscore-dangle */
import {
  SET_WIDGET_LOADING,
  WIDGET_ADD_SUCCESS,
  WIDGET_FAIL,
  CLEAR_ERRORS,
  DELETE_WIDGET,
  GET_ONE_WIDGET,
  UPDATE_WIDGET,
  LOGOUT,
  ELEMENT_ADD_SUCCESS,
  // GET_ELEMENTS,
  DELETE_ELEMENT,
  ELEMENTS_FAIL,
  GET_WIDGETS,
  GET_ONE_ELEMENT,
  SORT_ELEMENTS,
  UPDATE_WIDGET_TRANSLATION,
  SET_WIDGET_EMPTY,
} from "../actions/Types";

const initialState = {
  loading: false,
  widgets: [],
  error: null,
  added: false,
  widget: null,
  elements: [],
  element: {},
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case WIDGET_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        widgets: [...state.widgets, action.payload.data.data],
        added: true,
        widget: null,
      };
    case GET_ONE_WIDGET:
      return {
        ...state,
        loading: false,
        error: null,
        widget: action.payload.data,
        added: false,
      };
    case SET_WIDGET_EMPTY:
      return {
        ...state,
        loading: false,
        error: null,
        widget: {},
      };
    case GET_WIDGETS:
      return {
        ...state,
        loading: false,
        error: null,
        widgets: action.payload.data,
        added: false,
        // widget: null,
      };
    case DELETE_WIDGET:
      return {
        ...state,
        loading: false,
        widgets: state.widgets.filter((widget) => widget._id !== action.payload),
      };
    case UPDATE_WIDGET:
      return {
        ...state,
        added: true,
        widget: action.payload.data.data,
        loading: false,
        widgets: state.widgets.map((widget) =>
          widget._id === action.payload.data.data._id ? action.payload.data.data : widget
        ),
      };
    case WIDGET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_WIDGET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        widgets: [],
        error: null,
        added: false,
        widget: null,
      };
    case ELEMENT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        added: true,
        elements: action.payload.data.elements,
      };
    case DELETE_ELEMENT:
      return {
        ...state,
        loading: false,
        widget: action.payload.data.widget,
      };

    case GET_ONE_ELEMENT:
      return {
        ...state,
        loading: false,
        error: null,
        element: action.payload.data.data,
        added: true,
      };

    case SORT_ELEMENTS:
      return {
        ...state,
        added: true,
        elements: action.payload.data.data.elements,
      };

    case UPDATE_WIDGET_TRANSLATION:
      return {
        ...state,
        widget: action.payload.data?.widget,
      };

    case ELEMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default variable;
