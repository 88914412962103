/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";

const ParentPicker = ({
  handleChange = () => {},
  parent,
  setFieldValue = () => {},
  selectedParent = {},
  isClearable = false,
  type = "",
}) => {
  const filterRec = (data) =>
    data.map((parentData) => ({
      label: parentData?.destinationName,
      value: parentData._id,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url;
      if (type === "REGION") {
        url = `${process.env.REACT_APP_API}/destinations?destinationType=REGION`;
      }
      if (type === "CITY") {
        url = `${process.env.REACT_APP_API}/destinations?destinationType=CITY`;
      }

      if (inputValue) url += `&destinationName=${inputValue}`;
      url += "&sort=destinationName&dir=desc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(filterRec(result?.data));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.message);
        });
    });

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {parent.label}
      </Typography>{" "}
      <AsyncSelect
        // ref={selectRef}
        cacheOptions
        defaultOptions
        placeholder={
          selectedParent?.destinationName || selectedParent?.label || "Search for a parent"
        }
        isClearable={isClearable}
        key={type}
        menuPosition="fixed"
        loadOptions={promiseOptions}
        onChange={(option, { action }) => {
          if (type === "COUNTRY") setFieldValue("parentId", null);
          if (option) {
            handleChange(option);
            setFieldValue("parentId", option);
          }
          if (action === "clear") {
            handleChange(null);
            setFieldValue("parentId", {});
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            // border: "none!important",
            // boxShadow: "none",
            height: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          // option: (base) => ({
          //   ...base,
          //   padding: "18px 18px", // Adjust the padding as needed
          // }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            // overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
            overflow: "unset",
          }),
        }}
      />
    </div>
  );
};

ParentPicker.propTypes = {
  parent: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  isClearable: PropTypes.bool,
  type: PropTypes.string,
  selectedParent: PropTypes.instanceOf(Object),
};

export default ParentPicker;
