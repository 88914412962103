import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    name,
    role,
    partner,
    bio,
    languages,
    interests,
    destinations,
    // destinations,
    media,
    photo,
    phone,
    commission,
    balance,
    location,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string()
      .matches(/([A-Za-z])/, name.invalidMsg)
      .required(name.errorMsg),
    // [role.name]: Yup.string().required(role.errorMsg),
    [phone.name]: Yup.string().matches(
      /^(\+|00)[0-9 ]{1,3}[0-9 ]{4,14}(?:x.+)?$/,
      phone.invalidMsg
    ),
  }),
  Yup.object().shape({
    [partner.name]: Yup.lazy((value) => {
      if (typeof value === "string") {
        return Yup.string().required("Partner is required!");
      }
      if (typeof value === "object" && value !== null) {
        return Yup.object({
          name: Yup.string().required("Partner name is required!"),
        });
      }

      // Handle other cases if needed
      return Yup.mixed().required("Partner is required!");
    }),

    [role.name]: Yup.string().required(role.errorMsg),
  }),
  Yup.object().shape({
    [role.name]: Yup.string().required(role.errorMsg),
    [bio.name]: Yup.string().required(bio.errorMsg),
    [interests.name]: Yup.array().min(1, interests.errorMsg).required(),
    [languages.name]: Yup.array().min(1, languages.invalidMsg).required(),
    [destinations.name]: Yup.array().min(1, destinations.errorMsg).required(),
    [media.name]: Yup.lazy((value, { parent }) => {
      if (parent.type === "image") {
        return Yup.mixed().required(media.errorMsg);
      }
      return Yup.string()
        .required("A YouTube link is required!")
        .matches(
          /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=[\w-]{11}(&[\w-]+=[\w%-]*)*)|(youtu\.be\/[\w-]{11}(\?[\w-]+=[\w%-]*)*)$/,
          "Please enter a valid YouTube link."
        );
    }),
    [photo.name]: Yup.string().required(photo.errorMsg).nullable(),
  }),
  Yup.object().shape({
    locDestinations: Yup.object().shape(
      {
        location: Yup.mixed().when("destinations", {
          is: (desName) =>
            !desName ||
            (Array.isArray(desName) && desName.length === 0) ||
            (typeof desName === "string" && desName.trim() === "") ||
            (typeof desName === "object" && Object.keys(desName).length === 0),
          then: () =>
            Yup.mixed()
              .test(
                "is-valid-location",
                location.errorMsg,
                (value) =>
                  (typeof value === "object" && Object.keys(value).length > 0) ||
                  (typeof value === "string" && value.trim() !== "")
              )
              .required(location.errorMsg),
        }),
        destinations: Yup.mixed().when("location", {
          is: (loc) =>
            !loc ||
            (typeof loc === "object" && Object.keys(loc).length === 0) ||
            (typeof loc === "string" && loc.trim() === ""),
          then: () =>
            Yup.mixed()
              .test("is-valid-destination", destinations.errorMsg, (value) =>
                Array.isArray(value)
                  ? value.length > 0
                  : (typeof value === "string" && value.trim() !== "") ||
                    (typeof value === "object" && Object.keys(value).length > 0)
              )
              .required(destinations.errorMsg),
        }),
      },
      ["destinations", "location"]
    ),

    [balance.name]: Yup.number()
      .min(0, "Initial balance must be at least 0")
      .required(balance.errorMsg),
    [commission.name]: Yup.number()
      .min(0, "Commission must be between 0 and 50")
      .max(50, "Commission must be between 0 and 50")
      .required(commission.errorMsg),
  }),
];

export default validations;
