import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import typesList from "./TypesTagList";

const TypeTagPicker = ({
  typeV = {},
  setType = () => {},
  setFieldValue = () => {},
  setSelectedOptions = () => {},
}) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));

  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });

  return (
    <SelectF
      key={typeV?.value}
      options={typesList}
      placeholder="Select a tier"
      value={typeV?.label ? typeV : null}
      onChange={(value) => {
        if (value) {
          setFieldValue("type", value);
          setType(value);
          setFieldValue("parents", []);
          setFieldValue("persona", []);
          setFieldValue("interests", []);
          setSelectedOptions([]);
        } else {
          setType({});
        }
      }}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.label}`}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      isSearchable
      menuShouldBlockScroll
      menuPlacement="auto" // Automatically adjust menu position
      menuPortalTarget={null} // Remove portal rendering
    />
  );
};

TypeTagPicker.propTypes = {
  setType: PropTypes.func,
  setFieldValue: PropTypes.func,
  setSelectedOptions: PropTypes.func,
  typeV: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TypeTagPicker;
