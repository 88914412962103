/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";

const ServiceJobsCategoriesPicker = ({ setFieldValue = () => {}, serviceJobsObjV = null }) => {
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/tags?categoryFilter=true&type=JOBS`;

      if (inputValue) url += `&title=${inputValue}`;
      url += "&dir=asc";

      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          const formattedOptions = result.data.map((category) => ({
            label: category?.title,
            options: category?.subcategories.map((subcategory) => ({
              label: subcategory?.title,
              value: subcategory?._id,
              tag: subcategory?.parentTagIds[0],
              messageBoxCategoryId: subcategory?.messageBoxCategoryId,
            })),
          }));
          resolve(formattedOptions);
        })
        .catch((error) => {
          console.error(error.message);
          resolve([]);
        });
    });

  return (
    <div>
      <AsyncSelect
        key={serviceJobsObjV?.value || "picker"}
        cacheOptions
        value={serviceJobsObjV}
        defaultOptions
        placeholder={serviceJobsObjV?.label || "Search for a Service job"}
        menuPosition="fixed"
        loadOptions={promiseOptions}
        onChange={(option, { action }) => {
          if (option) {
            setFieldValue("serviceJobsObj", option);
          }
          if (action === "clear") {
            setFieldValue("serviceJobsObj", null);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({ ...base, height: "40px" }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          input: (base) => ({ ...base, fontSize: "0.875rem" }),
          menuList: (base) => ({ ...base, fontSize: "0.875rem" }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
            overflow: "unset",
          }),
        }}
      />
    </div>
  );
};

ServiceJobsCategoriesPicker.propTypes = {
  setFieldValue: PropTypes.func,
  serviceJobsObjV: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default ServiceJobsCategoriesPicker;
