/* eslint-disable no-underscore-dangle */
import {
  SET_TRAVELDESK_LOADING,
  CLEAR_ERRORS,
  TRAVELDESK_ADD_SUCCESS,
  TRAVELDESK_FAIL,
  UPDATE_TRAVELDESK,
  GET_ONE_TRAVELDESK,
  DELETE_TRAVELDESK,
  GET_TRAVELDESKS,
  LOGOUT,
} from "../actions/Types";

const initialState = {
  loading: false,
  travelDesks: [],
  error: null,
  added: false,
  travelDesk: null,
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case TRAVELDESK_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        travelDesks: [...state.travelDesks, action.payload.data.data],
        added: true,
        travelDesk: null,
      };
    case GET_ONE_TRAVELDESK:
      return {
        ...state,
        loading: false,
        error: null,
        travelDesk: action.payload.data,
        added: false,
      };

    case GET_TRAVELDESKS:
      return {
        ...state,
        loading: false,
        error: null,
        travelDesks: action.payload.data,
        added: false,
        travelDesk: null,
      };
    case DELETE_TRAVELDESK:
      return {
        ...state,
        loading: false,
        travelDesks: state.travelDesks.filter(
          (travelDesk) => travelDesk._id !== action.payload.data.data._id
        ),
      };
    case UPDATE_TRAVELDESK:
      return {
        ...state,
        added: true,
        travelDesk: action.payload.data.data,
        loading: false,
        travelDesks: state.travelDesks.map((travelDesk) =>
          travelDesk._id === action.payload.data.data._id ? action.payload.data.data : travelDesk
        ),
      };
    case TRAVELDESK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_TRAVELDESK_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        travelDesks: [],
        error: null,
        added: false,
        travelDesk: null,
      };

    default:
      return state;
  }
};
export default variable;
