const form = {
  formId: "new-promotion-form",
  formField: {
    // promotion page
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    summary: {
      name: "summary",
      label: "Summary",
      type: "text",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
    },
    minCover: {
      name: "minCover",
      label: "Minimum Cover",
      type: "number",
    },
    maxCover: {
      name: "maxCover",
      label: "Maximum Cover",
      type: "number",
    },
    recommendation: {
      name: "recommendation",
      label: "Recommendation",
      // type: "text",
      errorMsg: "Recommendation is required when location is empty!",
    },
    location: {
      name: "location",
      label: "Location *",
      // type: "text",
      errorMsg: "Location is required when recommendation is empty!",
    },
    logo: {
      name: "logo",
      label: "Logo *",
      type: "text",
      errorMsg: "Logo is required!",
    },
    bookingEmail: {
      name: "bookingEmail",
      label: "Booking Email *",
      type: "email",
      errorMsg: "Email is required!",
      invalidMsg: "Your email address is invalid (email@email.com)!",
    },
    locRec: {
      name: "locRec",
    },

    // time and date
    openEnded: {
      name: "openEnded",
      label: "Open Ended",
      type: "boolean",
    },
    needsConfirmation: {
      name: "needsConfirmation",
      label: "Needs Confirmation",
      type: "boolean",
    },
    startDate: {
      name: "startDate",
      label: "Start date",
      type: "text",
      //   errorMsg: "Date is required!",
    },
    endDate: {
      name: "endDate",
      label: "End date",
      type: "text",
      //   errorMsg: "Date is required!",
    },
    openHours: {
      name: "openHours",
      label: "Open Hours",
      type: "array",
      invalidMsg: "please select at least one day!",
    },
    source: {
      name: "source",
      label: "Source",
      type: "text",
    },
  },
};

export default form;
