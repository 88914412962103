import React from "react";

const FooterAddress = () => (
  <>
    <div
      style={{
        color: "black",
        fontSize: "11px",
        padding: "20px 0",
        marginTop: "10px",
        backgroundColor: "#F2F2F2",
      }}
    >
      <table
        cellPadding="0"
        cellSpacing="0"
        role="presentation"
        style={{
          margin: "0 auto",
          textAlign: "center",
          marginBottom: "10px",
        }}
      >
        <tbody>
          <tr>
            <td>
              <a href="https://www.business.lokalee.app/aboutus">
                <p
                  style={{
                    color: "black",
                    margin: "0 10px",
                    textDecoration: "underline",
                  }}
                >
                  About
                </p>
              </a>
            </td>

            <td style={{ padding: "0 5px" }}> | </td>

            <td>
              <a href="https://www.business.lokalee.app/careers">
                <p
                  style={{
                    color: "black",
                    margin: "0 10px",
                    textDecoration: "underline",
                  }}
                >
                  Careers
                </p>
              </a>
            </td>

            <td style={{ padding: "0 5px" }}> | </td>

            <td>
              <a href="https://www.business.lokalee.app/">
                <p
                  style={{
                    color: "black",
                    margin: "0 10px",
                    textDecoration: "underline",
                  }}
                >
                  Partner
                </p>
              </a>
            </td>

            <td style={{ padding: "0 5px" }}> | </td>

            <td>
              <a href="https://www.business.lokalee.app/curators">
                <p
                  style={{
                    color: "black",
                    margin: "0 10px",
                    textDecoration: "underline",
                  }}
                >
                  Hero
                </p>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <p style={{ margin: "10px auto 20px", maxWidth: "400px" }}>
        Advanced Digital Technology DWC-LLC Solutions - Dubai Hills Business Park - Building 1 -
        Office 406 – PO.BOX 390667 Dubai - United Arab Emirates
      </p>

      <p style={{ margin: "10px auto", fontWeight: "normal" }}>
        Copyright © {new Date().getFullYear()} Lokalee, All rights reserved.
      </p>
    </div>

    <table
      cellPadding="0"
      cellSpacing="0"
      role="presentation"
      style={{
        fontSize: "11px",
        color: "#394A43",
        padding: "10px 0",
        fontWeight: "bold",
        margin: "10px auto",
        textAlign: "center",
        backgroundColor: "white",
      }}
    >
      <tbody>
        <tr>
          <td>
            <a href="https://www.business.lokalee.app/privacy-policy">
              <p
                style={{
                  color: "#394A43",
                  margin: "0 10px",
                  textDecoration: "underline",
                }}
              >
                Privacy Policy
              </p>
            </a>
          </td>

          <td style={{ padding: "0 5px" }}> | </td>

          <td>
            <a href="https://www.business.lokalee.app/terms-conditions">
              <p
                style={{
                  color: "#394A43",
                  margin: "0 10px",
                  textDecoration: "underline",
                }}
              >
                Terms & Conditions
              </p>
            </a>
          </td>

          <td style={{ padding: "0 5px" }}> | </td>

          <td>
            <a href="https://www.business.lokalee.app/contactus" target="_blank" rel="noreferrer">
              <p
                style={{
                  color: "#394A43",
                  margin: "0 10px",
                  textDecoration: "underline",
                }}
              >
                Contact Us
              </p>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

export default FooterAddress;
