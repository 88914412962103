import React from "react";
import { Button, Section, Text } from "@react-email/components";

import PropTypes from "prop-types";

const ReservationCard = ({ data, action = null }) => {
  /* ****** Constants ****** */
  const { guests = 0, time = "", date = "", name, phone, email } = data;

  return (
    <Section
      style={{
        borderRadius: "12px",
        padding: "16px 24px",
        fontFamily: "Helvetica",
        backgroundColor: "#F2F2F2",
      }}
    >
      <Text
        style={{
          fontSize: "22px",
          fontWeight: "700",
          marginBottom: "12px",
          lineHeight: "25.75px",
        }}
      >
        Reservation Details
      </Text>

      <div
        style={{
          width: "100%",
          display: "block",
        }}
      >
        <Text
          numberOfLines={1}
          style={{
            margin: "0",
            fontSize: "16px",
            fontWeight: "400",
            marginBottom: "8px",
            lineHeight: "26.5px",
          }}
        >
          Guests: {guests}
        </Text>

        <Text
          numberOfLines={1}
          style={{
            margin: "0",
            fontSize: "16px",
            fontWeight: "400",
            marginBottom: "8px",
            lineHeight: "26.5px",
          }}
        >
          Reservation Time: {time}
        </Text>

        <Text
          numberOfLines={1}
          style={{
            margin: "0",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "26.5px",
          }}
        >
          Reservation Date: {date}
        </Text>
      </div>

      <div style={{ border: "1px solid #BAB9B9", margin: "16px 0" }} />

      <Text
        style={{
          fontSize: "22px",
          fontWeight: "700",
          marginBottom: "8px",
          lineHeight: "25.75px",
        }}
      >
        Guest Details
      </Text>

      <div
        style={{
          display: "block",
        }}
      >
        <Text
          numberOfLines={1}
          style={{
            margin: "0",
            fontSize: "16px",
            fontWeight: "400",
            marginBottom: "8px",
            lineHeight: "26.5px",
          }}
        >
          Full Name: {name}
        </Text>

        <Text
          numberOfLines={1}
          style={{
            margin: "0",
            fontSize: "16px",
            fontWeight: "400",
            marginBottom: "8px",
            lineHeight: "26.5px",
          }}
        >
          Phone Number: {phone}
        </Text>

        <Text
          numberOfLines={1}
          style={{
            margin: "0",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "26.5px",
          }}
        >
          Email: {email}
        </Text>
      </div>

      {action && (
        <>
          <Button
            href={action}
            style={{
              width: "100%",
              color: "#FFFFFF",
              fontSize: "14px",
              margin: "20px 0",
              padding: "12px 0",
              fontWeight: "600",
              borderRadius: "4px",
              textAlign: "center",
              backgroundColor: "#222D29",
            }}
          >
            Confirm Reservation
          </Button>

          <Text
            style={{
              margin: 0,
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "23px",
              fontFamily: "Arial",
              textAlign: "center",
            }}
          >
            Or contact the guest via:
          </Text>

          <table style={{ margin: "10px auto 0" }}>
            <tbody>
              <tr>
                <td style={{ paddingRight: "12px" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            alt="Whatsapp"
                            src="https://email-assets.lokalee.app/email/emailImages/whatsapp-fill.png"
                            style={{
                              width: "24px",
                              height: "24px",
                              display: "block",
                              marginRight: "4px",
                            }}
                          />
                        </td>

                        <td
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            textDecoration: "underline",
                          }}
                        >
                          <a
                            href={`https://wa.me/${phone}`}
                            style={{
                              color: "#222D29",
                              textDecoration: "underline",
                            }}
                          >
                            Whatsapp
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>

                <td style={{ paddingRight: "12px" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            alt="Whatsapp"
                            src="https://email-assets.lokalee.app/email/emailImages/mail-line.png"
                            style={{
                              width: "24px",
                              height: "24px",
                              display: "block",
                              marginRight: "4px",
                            }}
                          />
                        </td>

                        <td
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            textDecoration: "underline",
                          }}
                        >
                          <a
                            href={`mailto:${email}`}
                            style={{
                              color: "#222D29",
                              textDecoration: "underline",
                            }}
                          >
                            Email
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>

                <td style={{ paddingRight: "12px" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            alt="Whatsapp"
                            src="https://email-assets.lokalee.app/email/emailImages/phone-line.png"
                            style={{
                              width: "24px",
                              height: "24px",
                              display: "block",
                              marginRight: "4px",
                            }}
                          />
                        </td>

                        <td
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            textDecoration: "underline",
                          }}
                        >
                          <a
                            href={`tel:${phone}`}
                            style={{
                              color: "#222D29",
                              textDecoration: "underline",
                            }}
                          >
                            Phone
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </Section>
  );
};

ReservationCard.propTypes = {
  data: PropTypes.shape({
    guests: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    time: PropTypes.string,
    date: PropTypes.string,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  action: PropTypes.string,
};

export default ReservationCard;
