/* eslint-disable no-underscore-dangle */
import {
  SET_FACILITY_LOADING,
  FACILITY_ADD_SUCCESS,
  FACILITY_FAIL,
  GET_FACILITIES,
  CLEAR_ERRORS,
  DELETE_FACILITY,
  GET_ONE_FACILITY,
  UPDATE_FACILITY,
  LOGOUT,
  GET_LOCATION_FACILITIES,
  UPDATE_FACILITY_TRANSLATION,
  SET_FACILITY_EMPTY,
} from "../actions/Types";

const initialState = {
  loading: false,
  facilities: [],
  error: null,
  added: false,
  facility: null,
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case FACILITY_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        facilities: [...state.facilities, action.payload.data.facility],
        added: true,
        facility: null,
      };
    case GET_ONE_FACILITY:
      return {
        ...state,
        loading: false,
        error: null,
        facility: action.payload.data,
        added: false,
      };
    case SET_FACILITY_EMPTY:
      return {
        ...state,
        loading: false,
        error: null,
        facility: {},
      };
    case GET_FACILITIES:
      return {
        ...state,
        loading: false,
        error: null,
        facilities: action.payload.data,
        added: false,
        facility: null,
      };

    case GET_LOCATION_FACILITIES:
      return {
        ...state,
        loading: false,
        error: null,
        facilities: action.payload.data.facilities,
        added: false,
        facility: null,
      };
    case DELETE_FACILITY:
      return {
        ...state,
        loading: false,
        facilities: state.facilities.filter(
          (facility) => facility._id !== action.payload.data.facility._id
        ),
      };
    case UPDATE_FACILITY:
      return {
        ...state,
        added: true,
        facility: action.payload.data.data,
        loading: false,
        facilities: state.facilities.map((facility) =>
          facility._id === action.payload.data.facility._id
            ? action.payload.data.facility
            : facility
        ),
      };
    case UPDATE_FACILITY_TRANSLATION:
      return {
        ...state,
        facility: action.payload.data?.facility,
      };
    case FACILITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_FACILITY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        facilities: [],
        error: null,
        added: false,
        facility: null,
      };

    default:
      return state;
  }
};
export default variable;
