export const convertToAMPM = (time24) => {
  if (!time24) {
    return "";
  }

  const timeSplit = time24.split(":");
  let hours = parseInt(timeSplit[0], 10);
  const minutes = timeSplit[1];
  const period = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;
  return `${hours}:${minutes} ${period}`;
};

export const exportDayName = (day) => {
  switch (day) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";
    default:
      return "";
  }
};

export const onKeyClick = (e, type) => {
  if (type === "number") {
    const { value: currentValue } = e.target;
    const { key } = e;

    const isNumberKey = /^\d$/.test(key);
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "."];
    const decimalIndex = currentValue.indexOf(".");

    // Allow only numbers, allowed keys, and a single decimal point
    if (!isNumberKey && !allowedKeys.includes(key)) {
      e.preventDefault();
      return;
    }

    // Prevent multiple decimals
    if (key === "." && decimalIndex !== -1) {
      e.preventDefault();
      return;
    }

    // Prevent more than two decimal places
    if (
      decimalIndex !== -1 &&
      key !== "Backspace" &&
      key !== "Delete" &&
      currentValue.length - decimalIndex > 2
    ) {
      e.preventDefault();
    }
  }
};
