/* eslint-disable no-underscore-dangle */
import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";

import { clearSubTags, updateProduct } from "store/actions/locationAction";
import { useDispatch } from "react-redux";

import MDBox from "components/MDBox";
import DetailList from "components/DetailList";
import ViewEditActions from "components/DetailList/ViewEditActions";

import validations from "../../schemas/OnlineStore/validation";
import initialValues from "../../schemas/OnlineStore/initialValues";
import form from "../../schemas/OnlineStore/form";
import ProductInfo from "./ProductInfo";

const EditStoreProduct = ({ productDetails, locationId, setIconPressed }) => {
  /* ** Refs ** */
  const formRef = useRef();
  /* ** Constants ** */
  const { addProductValidation } = validations;

  const { formId, addProductsFields: formField } = form;

  const dispatch = useDispatch();

  /* ** State ** */
  const [showDetailsList, setShowDetailsList] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState({ code: "USD", name: "USD" });
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedSubTag, setSelectedSubTag] = useState(null);
  const [initialState, setInitialState] = useState(initialValues);

  useEffect(() => {
    const product = productDetails?.current;

    if (product) {
      const manipulated = {};
      manipulated.productName = product?.title || "";
      manipulated.productCode = product?.partnerId || null;
      manipulated.price = product?.price ? product?.price?.recommendedRetailPrice : null;
      manipulated.netPrice = product?.price ? product?.price?.partnerPrice : null;
      manipulated.defaultCurrencyCode = product?.currency
        ? { code: product?.currency, name: product?.currency }
        : { code: "USD", name: "USD" };
      manipulated.tag =
        product?.tags?.length > 0
          ? { label: product?.tags[0]?.title, value: product?.tags[0]?._id }
          : {};
      manipulated.subTag =
        product?.subTags?.length > 0
          ? { label: product?.subTags[0]?.title, value: product?.subTags[0]?._id }
          : {};

      manipulated._id = product?._id;

      setSelectedTag(manipulated.tag);
      setSelectedSubTag(manipulated.subTag);
      setSelectedCurrency(manipulated.defaultCurrencyCode);

      setInitialState(manipulated);
    }
  }, [productDetails?.current]);

  const resetEditForm = (resetForm) => {
    resetForm();
    setIconPressed("");
    dispatch(clearSubTags());
  };

  const handleCurrencyChange = (value) => {
    if (!value || !value.name) {
      setSelectedCurrency({ code: "USD", name: "USD" });
    } else {
      setSelectedCurrency(value);
    }
  };

  const handleTagChange = (value) => {
    if (value && value?.value) {
      setSelectedTag(value);
      setSelectedSubTag({});
    } else {
      setSelectedTag({});
      setSelectedSubTag({});
    }
  };

  const handleSubTagChange = (value) => {
    if (value && value?.value) {
      setSelectedSubTag(value);
    } else {
      setSelectedSubTag({});
    }
  };

  const handleSubmit = async (values) => {
    const { productName, productCode, price, tag, subTag, defaultCurrencyCode, netPrice } = values;
    const productPayload = {
      tags: tag.value,
      source: "lokalee",
      itemType: "store",
      title: productName,
      location: locationId,
      partnerId: productCode,
      currency: defaultCurrencyCode?.code,
      price: {
        recommendedRetailPrice: price,
        partnerPrice: netPrice,
      },
    };

    if (subTag) {
      productPayload.subTags = [subTag.value];
    }

    dispatch(updateProduct(productPayload, values._id));
    setShowDetailsList(true);
  };

  return (
    <MDBox shadow="md" bgColor="white" borderRadius="md" variant="contained" p={2}>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={addProductValidation}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        // validateOnBlur={false}
        // validateOnChange={false}
        innerRef={(f) => {
          formRef.current = f;
        }}
      >
        {({ values, errors, touched, setFieldValue, resetForm, submitForm }) => (
          <Form id={formId} autoComplete="off">
            {console.log("errors", errors)}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Button
                color="secondary"
                startIcon={<ArrowBack />}
                onClick={() => resetEditForm(resetForm)}
              >
                Back
              </Button>

              <ViewEditActions
                stateName="locations"
                detailPage={showDetailsList}
                handleEdit={() => setShowDetailsList(false)}
                handleSave={(event) => {
                  event.preventDefault();
                  submitForm();
                }}
                handleClose={() => {
                  resetForm();
                  setShowDetailsList(true);
                }}
              />
            </Box>

            {showDetailsList ? (
              <Box paddingLeft={3}>
                <DetailList
                  data={{
                    ProductName: values?.productName,
                    JobCode: values?.productCode,
                    RetailPrice: values?.price,
                    NetPrice: values?.netPrice,
                    Currency: values?.defaultCurrencyCode?.code,
                    Tag: values?.tag?.label,
                    SubTag: values?.subTag?.label,
                  }}
                />
              </Box>
            ) : (
              <ProductInfo
                formData={{ values, touched, formField, errors, setFieldValue }}
                handleCurrencyChange={handleCurrencyChange}
                selectedCurrency={selectedCurrency}
                handleTagChange={handleTagChange}
                selectedTag={selectedTag}
                handleSubTagChange={handleSubTagChange}
                selectedSubTag={selectedSubTag}
              />
            )}
          </Form>
        )}
      </Formik>
    </MDBox>
  );
};

export default EditStoreProduct;

EditStoreProduct.propTypes = {
  locationId: PropTypes.string.isRequired,
  setIconPressed: PropTypes.func.isRequired,
  productDetails: PropTypes.shape({
    current: PropTypes.instanceOf(Object),
  }).isRequired,
};
