const form = {
  formId: "new-tag-form",
  formField: {
    // tag page
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter",
    },

    parents: {
      name: "parents",
      label: "Parent",
      type: "array",
    },

    type: {
      name: "type",
      label: "Type",
      type: "object",
    },

    top: {
      name: "top",
      label: "Top",
      type: "boolean",
    },

    interests: {
      name: "interests",
      label: "Interests",
      type: "array",
    },

    persona: {
      name: "persona",
      label: "Companion",
      type: "array",
    },

    icon: {
      name: "icon",
      label: "Icon",
      errorMsg: "Icon is required!",
    },

    messageBoxCategoryId: {
      name: "messageBoxCategoryId",
      label: "Tag Id",
      type: "text",
    },
  },
};

export default form;
