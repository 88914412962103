/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */

// Material Dashboard 2 PRO React examples

// libraries
import { createRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// // custom components
import MDBox from "components/MDBox";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import MaterialTablePicker from "components/MaterialTablePicker";

// redux actions
import { deleteRecommendation, updateRecommendation } from "store/actions/recommendationActions";

// material ui components
import Grid from "@mui/material/Grid2";
import columnsMap from "components/MaterialTablePicker/TableColumns";

const RecommendationsDuplicatesList = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.users.loading);
  const user = useSelector((state) => state.users.user);

  const deleteRecommendationFn = (id) => {
    dispatch(deleteRecommendation(id));
  };

  const updateRecommendationFn = (data) => {
    const updatedData = { _id: data._id, title: data.title, city: data.city };
    dispatch(updateRecommendation(updatedData));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item size={{ xs: 12, lg: 8 }}>
        <MDBox sx={{ m: 5 }}>
          <MaterialTablePicker
            screen="DuplicateRecommendationsList"
            title=""
            searchField="duplicateRecommendations"
            currentUrl="duplicateRecommendations"
            tableRef={tableRef}
            columns={[
              columnsMap.titleCol,
              columnsMap.primaryDestination,
              columnsMap.tagsWithFilter,
              columnsMap.sourceInvoices,
            ]}
            addUrl="recommendations/new-recommendation"
            onRowClickURL={`/${user.role}/recommendations`}
            asyncURL={`${process.env.REACT_APP_API}/recommendations/duplicate?`}
            asyncTable
            isLoading={loading}
            filteringOptions={false}
            isExportable={false}
            editableUpdate={updateRecommendationFn}
            editableDelete={deleteRecommendationFn}
            editableRole={user.role === "admin"}
            isGrouping
            isFreeActions
            updateRoleAction
            deleteRoleAction
            actionsRole={user.role === "admin" || user.role === "partner"}
            actionsName="RecommendationsDuplicates"
          />
        </MDBox>
      </Grid>
    </DashboardLayout>
  );
};

export default RecommendationsDuplicatesList;
