/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import DetailList from "components/DetailList";
import { capitalizeFirstLetter } from "components/helper";
import CurrencyPicker from "components/AutoComplete/CurrencyPicker";
import TagPicker from "components/AsyncSelect/TagPicker";
import SubTagPicker from "components/AsyncSelect/SubTagPicker";
import Danger from "components/Danger";

const ProductInfo = ({
  formData,
  editPage = false,
  detailPage = true,
  handleCurrencyChange = () => {},
  selectedCurrency = { code: "USD", name: "USD" },
  handleTagChange = () => {},
  selectedTag = {},
  handleSubTagChange = () => {},
  selectedSubTag = {},
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;

  const { productName, productCode, price, netPrice, defaultCurrencyCode, tag, subTag } = formField;
  const {
    productName: productNameV,
    productCode: productCodeV,
    price: priceV,
    netPrice: netPriceV,
  } = values;
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Partner info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            name: values?.name,
            website: values?.website,
            licence: values?.licence,
            commissionPercentage: values?.commissionNumber,
            top: values?.top,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={productName.type}
                label={productName.label}
                name={productName.name}
                value={capitalizeFirstLetter(productNameV)}
                placeholder={productName.placeholder}
                error={errors.productName && touched.productName}
                success={productNameV?.length > 0 && !errors.productName}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={productCode.type}
                label={productCode.label}
                name={productCode.name}
                value={productCodeV}
                placeholder={productCode.placeholder}
                error={errors.productCode && touched.productCode}
                success={productCodeV?.length > 0 && !errors.productCode}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={price.type}
                label={price.label}
                name={price.name}
                value={priceV}
                placeholder={price.placeholder}
                error={errors.price && touched.price}
                success={priceV?.length > 0 && !errors.price}
              />
            </Grid>{" "}
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={netPrice.type}
                label={netPrice.label}
                name={netPrice.name}
                value={netPriceV}
                placeholder={netPrice.placeholder}
                error={errors.netPrice && touched.netPrice}
                success={netPriceV?.length > 0 && !errors.netPrice}
              />
            </Grid>{" "}
            <Grid item size={{ xs: 6 }}>
              <TagPicker
                tag={tag}
                handleChange={handleTagChange}
                setFieldValue={setFieldValue}
                selectedTag={selectedTag || {}}
                isJobType
              />
              {errors?.tag && !values?.tag?.label && touched?.tag && (
                <Danger validation={errors?.tag} />
              )}
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <SubTagPicker
                key={selectedTag?.id}
                subTag={subTag}
                handleChange={handleSubTagChange}
                setFieldValue={setFieldValue}
                selectedSubTag={selectedSubTag || {}}
                selectedTag={selectedTag || {}}
                isClearable
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <MDTypography variant="h6" color="text" fontWeight="regular">
                {defaultCurrencyCode.label}
              </MDTypography>
              <MDBox
                style={{
                  marginTop: "8px",
                  backgroundColor: "white",
                }}
              >
                <CurrencyPicker
                  currency={selectedCurrency}
                  handleSelectCurrency={handleCurrencyChange}
                  setFieldValue={setFieldValue}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for ProductInfo
ProductInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handleCurrencyChange: PropTypes.func,
  selectedCurrency: PropTypes.instanceOf(Object),
  handleTagChange: PropTypes.func,
  selectedTag: PropTypes.instanceOf(Object),
  handleSubTagChange: PropTypes.func,
  selectedSubTag: PropTypes.instanceOf(Object),
};

export default ProductInfo;
