import React from "react";
import PropTypes from "prop-types";
import { Body, Container, Html, Section, Text, Img } from "@react-email/components";

import HeadSection from "../HeadSection";

const EmailToGuest = (data = {}) => {
  const { user = {}, data: emailData = {} } = data;
  const { name = "John Doe", roomNumber = "101" } = user;
  const {
    currency = "AED",
    cardNumber = "1234",
    paymentMethod = "stripe",
    invoiceNumber = "INV123456",
    locationName = "Hotel California",
    locationLogo = "https://email-assets.lokalee.app/email/emailImages/logo.png",
    totalAmount = { priceAfterLokaleeDiscount: { recommendedRetailPrice: 150 } },
    items = [
      {
        totalPax: 1,
        ref: { title: "Room Service" },
        itemTotalPrice: { price: { recommendedRetailPrice: 50 } },
      },
      {
        totalPax: 2,
        ref: { title: "Spa Treatment" },
        itemTotalPrice: { price: { recommendedRetailPrice: 100 } },
      },
    ],
  } = emailData;

  return (
    <Html>
      <HeadSection />

      <Body
        style={{
          height: "100vh",
          lineHeight: "1.625",
          backgroundColor: "#f7fafc",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <Container
          style={{
            maxWidth: "600px",
            marginLeft: "auto",
            paddingTop: "16px",
            marginRight: "auto",
            backgroundColor: "#ffffff",
            border: "1px solid #eaeaea",
          }}
        >
          <table style={{ height: "98px", backgroundColor: "#F2F2F2", width: "100%" }}>
            <tr>
              <td align="center">
                <Img
                  alt="Logo"
                  width="auto"
                  height="70px"
                  src={locationLogo}
                  style={{ objectFit: "contain" }}
                />
              </td>
            </tr>
          </table>

          <Section
            style={{
              padding: "30px 32px",
            }}
          >
            <Text
              style={{
                margin: "0px",
                fontSize: "25px",
                color: "#222D29",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Dear {name},
            </Text>

            <Text
              style={{
                margin: "0px",
                fontSize: "16px",
                color: "#222D29",
              }}
            >
              Thank you for choosing our services. We are pleased to process your request and
              deliver as soon as possible.
            </Text>
          </Section>

          <Section
            style={{
              margin: "0px auto",
              maxWidth: "536px",
              padding: "16px 24px",
              borderRadius: "11.54px",
              backgroundColor: "#F2F2F2",
            }}
          >
            {paymentMethod === "stripe" && (
              <Text
                style={{
                  margin: "0px",
                  fontSize: "18px",
                  color: "#222D29",
                  fontWeight: "bold",
                  marginBottom: "4px",
                }}
              >
                Order Number: {invoiceNumber}
              </Text>
            )}

            <Text
              style={{
                margin: "0px",
                fontSize: "14px",
                color: "#222D29",
                marginBottom: "35px",
              }}
            >
              Room No: {roomNumber}
            </Text>

            <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "8px" }}>
              <thead>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th
                    colSpan="2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#222D29",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    ORDER DETAILS
                  </th>

                  <th
                    style={{
                      width: "100px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#222D29",
                      textAlign: "center",
                      padding: "8px",
                    }}
                  >
                    QUANTITY
                  </th>
                  <th
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#222D29",
                      textAlign: "right",
                      padding: "8px",
                    }}
                  >
                    PRICE
                  </th>
                </tr>
              </thead>

              <tbody>
                {items.map((item) => {
                  const { totalPax, ref, itemTotalPrice } = item;

                  return (
                    <tr key={ref.title} style={{ borderBottom: "1px solid #ddd" }}>
                      <td
                        colSpan="2"
                        style={{
                          fontSize: "14px",
                          color: "#222D29",
                          padding: "8px",
                          textAlign: "left",
                          colSpan: 2,
                        }}
                      >
                        {ref.title}
                      </td>
                      <td
                        style={{
                          width: "100px",
                          fontSize: "14px",
                          color: "#222D29",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {totalPax}
                      </td>
                      <td
                        style={{
                          width: "100px",
                          fontSize: "14px",
                          color: "#222D29",
                          textAlign: "right",
                          padding: "8px",
                        }}
                      >
                        {currency} {itemTotalPrice.price.recommendedRetailPrice}
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td
                    colSpan="3"
                    style={{
                      fontSize: "14px",
                      color: "#222D29",
                      fontWeight: "700",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    TOTAL
                  </td>
                  <td
                    style={{
                      fontSize: "14px",
                      color: "#222D29",
                      fontWeight: "bold",
                      textAlign: "right",
                      padding: "8px",
                    }}
                  >
                    {currency} {totalAmount.priceAfterLokaleeDiscount.recommendedRetailPrice}
                  </td>
                </tr>
              </tbody>
            </table>

            <Text
              style={{
                fontSize: "14px",
                color: "#222D29",
                marginTop: "30px",
                fontWeight: "700",
                lineHeight: "25.75px",
                borderBottom: "1px solid #ddd",
              }}
            >
              PAYMENT METHOD
            </Text>

            <Text
              style={{
                fontSize: "14px",
                color: "#222D29",
                marginBottom: "16px",
              }}
            >
              {paymentMethod === "stripe" ? `VISA ****${cardNumber}` : paymentMethod}
            </Text>
          </Section>

          <Section
            style={{
              marginTop: "8px",
              textAlign: "center",
              marginBottom: "24px",
              backgroundColor: "#ffffff",
            }}
          >
            <Text
              style={{
                margin: "0px",
                fontSize: "14px",
                color: "#222D29",
              }}
            >
              Our staff are available for assistance at any time.
            </Text>
          </Section>

          <Section
            style={{
              margin: "24px",
              textAlign: "left",
              padding: "16px 0px",
              borderTop: "1px solid #ddd",
            }}
          >
            <Text
              style={{
                margin: "0px",
                color: "#222D29",
                fontSize: "20px",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              Enjoy your stay,
            </Text>

            <Text
              style={{
                margin: "0px",
                fontSize: "14px",
                color: "#222D29",
              }}
            >
              {locationName} Management
            </Text>
          </Section>
        </Container>
      </Body>
    </Html>
  );
};

EmailToGuest.propTypes = {
  data: PropTypes.shape({}),
};

export default EmailToGuest;
